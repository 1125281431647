import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Table, Col, Row, Container, Form, FormGroup, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { Constants } from "../../Constants";
import { logoutUser } from "src/slices/thunks";
import { useDispatch } from "react-redux";
import exportFromJSON from 'export-from-json';
import loading from '../../assets/images/loading.gif';


export default class mostPlayedMiniGameUsers extends Component<any, any>  {
    constructor(props: any) {
        super(props);
        this.state = {
            result: [],
            category: [],
            miniGameType: "MiniGameK1",
            isLoading: true
        };

        this.getMiniGame = this.getMiniGame.bind(this);
    }

    ExportToExcel = () => {
        const data = this.state.result.data.map((el: any, index: number) => {
            return { "Kullanıcı Adı": el.username, "Oyunun Toplam Oynanma Sayısı": el.playingCount, "Başarı Yüzdesi": "% " + el.successPercentage };
        });
        const fileName = 'Mini Oyunları En Çok Oynayan Kullanıcılar Listesi';
        const exportType = 'xls';
        exportFromJSON({ data, fileName, exportType })
    }

    componentDidMount() {
        this.getMiniGame().then(result => {
            this.setState({ result: result });
        });
    }

    componentWillUnmount() {
        this.getMiniGame().then(result => {
            this.setState({ result: result })
        });
    }
    async getMiniGame() {
        try {
            var body = {
                "miniGameType": this.state.miniGameType,
            }
            var response = await fetch(Constants.url + "analytics/getUsersMostPlayedMiniGames", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(body)
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();
            this.setState({ result: result });

            this.setState({ isLoading: false });

            return result;

        } catch (error) {
            console.log("error: ", error);
        }
    }
    async changeMiniGame(miniGameType: string) {
        try {
            var body = {
                "miniGameType": miniGameType
            }
            var response = await fetch(Constants.url + "analytics/getUsersMostPlayedMiniGames", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(body)
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();
            this.setState({ result: result });

            this.setState({ isLoading: false });

            return result;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    render() {

        return (
            <React.Fragment>
                <div style={{ minHeight: "100vh" }}>
                    <div className="page-content">
                        <MetaTags>
                            <title>  Mini Oyunları En Çok Oynayan Kullanıcılar Listesi </title>
                        </MetaTags>
                    </div>
                    <div>
                        <Container style={{ textAlign: "right", direction: "ltr", alignItems: 'center', flex: 1 }}>
                            <div>
                                <Row style={{ textAlign: "left", direction: "ltr", marginLeft: "30px" }}>
                                    <Col>
                                        <h5><b><u> Mini oyunları En Çok Oynayan Kullanıcılar Listesi </u></b></h5>
                                    </Col>
                                </Row>
                                <br />
                                <Row style={{ textAlign: "left", direction: "ltr", marginLeft: "30px" }}>
                                    <Col>
                                        <Link to="/analyticEvents" className="btn btn-primary">Geri</Link>
                                    </Col>
                                    <Col md={2} >
                                        <button type="button" className="btn btn-danger" onClick={this.ExportToExcel}>Mini oyunları En Çok Oynayan Kullanıcılar Listesi</button>
                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <br />
                            <br />
                            <div>
                                <Col md={4} style={{ textAlign: "left", direction: "ltr", margin: "-45px 0px 0px 45px" }}>
                                    <Form>
                                        <FormGroup>
                                            <Label for="category">
                                                Mini Oyun Seçiniz
                                            </Label>
                                            <Input
                                                id="category"
                                                name="category"
                                                type="select"
                                                onChange={async event => { this.setState({ isLoading: true }); this.setState({ miniGameType: event.target.value }); await this.changeMiniGame(event.target.value); }}
                                            >
                                                <option value="MiniGameK1">Teknik Bilgi</option>
                                                <option value="MiniGamePS1">Problem Çözme</option>
                                                <option value="MiniGameF1">Sürekli Öğrenme</option>
                                                <option value="MiniGameCF1">Müşteri Odaklılık</option>
                                                <option value="MiniGameI1">İnovasyon</option>
                                            </Input>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </div>
                            <br />
                            <div>
                                <Container style={{ direction: "ltr", flex: 1, alignContent: "center" }}>
                                    {
                                        this.state.isLoading &&
                                        <div>
                                            <Col style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={loading} alt="loading" style={{ width: "150px" }} />
                                                <h4>Bilgiler Yükleniyor...</h4>
                                            </Col>
                                        </div>
                                    }

                                </Container>
                                <Container style={{ textAlign: "right", direction: "ltr", width: "800px", alignItems: 'center', flex: 1 }}>
                                    {!this.state.isLoading &&
                                        <Table bordered style={{ direction: "ltr", width: "800px" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "center", width: "300px" }}>
                                                        Kullanıcı Adı
                                                    </th>

                                                    <th style={{ textAlign: "center", width: "300px" }}>
                                                        Oyunun Toplam Oynanma Sayısı
                                                    </th>
                                                    <th style={{ textAlign: "center", width: "300px" }}>
                                                        Başarı Yüzdesi
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.result != null && this.state.result != undefined && this.state.result.data != null ? (this.state.result.data.map(
                                                    (miniGame: any, index: number) => (
                                                        <tr key={index}>
                                                            <td style={{ textAlign: "center" }}>
                                                                {miniGame.username}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {miniGame.playingCount}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {"%" + miniGame.successPercentage}
                                                            </td>
                                                        </tr>
                                                    )
                                                )) : (<tr>
                                                    <td style={{ textAlign: "center" }}>

                                                    </td>
                                                    <td style={{ textAlign: "center" }}>

                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    }
                                </Container>
                            </div>
                        </Container>
                    </div>
                </div>
            </React.Fragment>

        );
    }
}

