import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Table, ListGroup, ListGroupItem, Col, Row, Container, Form, FormGroup, Label, Input, FormText, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Config, { Constants } from "../../Constants";
import moment from 'moment';
import Store from "src/store";
import { logoutUser } from "src/slices/thunks";
import { useDispatch } from "react-redux";
import exportFromJSON from 'export-from-json';
import loading from '../../assets/images/loading.gif';


export default class mostGainedMiniGamePointUsersList extends Component<any, any>  {
    constructor(props: any) {
        super(props);
        this.state = {
            result: [],
            isLoading: true
        };

        this.getLoginDaysList = this.getLoginDaysList.bind(this);
    }

    ExportToExcel = () => {
        const data = this.state.result.data.map((el: any, index: number) => {
            return { "Gün": moment.utc(el.loginDate).local().format('yyyy-MM-DD'), "Toplam Giriş Sayısı": el.count };
        });

        const fileName = 'Oyuna giriş yapılan günler ve bu günlerdeki toplam giriş sayıları listesi';
        const exportType = 'xls';
        exportFromJSON({ data, fileName, exportType })
    }

    componentDidMount() {
        this.getLoginDaysList().then(result => {
            this.setState({ result: result });
        });
    }

    async getLoginDaysList() {
        try {
            var response = await fetch(Constants.url + "analytics/getLoginDays", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();
            this.setState({ result: result });
            this.setState({ isLoading: false });

            return result;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ minHeight: "100vh" }}>
                    <div className="page-content">
                        <MetaTags>
                            <title>  Oyuna Giriş Yapılan Günler ve Bu Günlerdeki Toplam Giriş Sayıları Listesi </title>
                        </MetaTags>
                    </div>
                    <div>
                        <Container style={{ textAlign: "right", direction: "ltr", alignItems: 'center', flex: 1 }}>
                            <div>
                                <Row style={{ textAlign: "left", direction: "ltr", marginLeft: "30px" }}>
                                    <Col>
                                        <h5><b><u> Oyuna Giriş Yapılan Günler ve Bu Günlerdeki Toplam Giriş Sayıları Listesi </u></b></h5>
                                    </Col>
                                </Row>
                                <br />
                                <Row style={{ textAlign: "left", direction: "ltr", marginLeft: "30px" }}>
                                    <Col>
                                        <Link to="/analyticEvents" className="btn btn-primary">Geri</Link>
                                    </Col>
                                    <Col md={5} >
                                        <button type="button" className="btn btn-danger" onClick={this.ExportToExcel}>Oyuna Giriş Yapılan Günler ve Bu Günlerdeki Toplam Giriş Sayıları Listesi İndir</button>
                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <div>
                                <Container style={{ direction: "ltr", flex: 1, alignContent: "center" }}>
                                    {
                                        this.state.isLoading &&
                                        <div>
                                            <Col style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={loading} alt="loading" style={{ width: "150px" }} />
                                                <h4>Bilgiler Yükleniyor...</h4>
                                            </Col>
                                        </div>
                                    }

                                </Container>
                                <Container style={{ textAlign: "right", direction: "ltr", width: "800px", alignItems: 'center', flex: 1 }}>
                                    {!this.state.isLoading &&
                                        <Table bordered style={{ direction: "ltr", width: "800px" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "center" }}>
                                                        Gün
                                                    </th>

                                                    <th style={{ textAlign: "center" }}>
                                                        Toplam Giriş Sayısı
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.result != null && this.state.result != undefined && this.state.result.data != null ? (this.state.result.data.map(
                                                    (LoginDays: any, index: number) => (
                                                        <tr key={index}>
                                                            <td style={{ textAlign: "center" }}>
                                                                {moment.utc(LoginDays.loginDate).local().format('yyyy-MM-DD')}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {LoginDays.count}
                                                            </td>
                                                        </tr>
                                                    )
                                                )) : (<tr>
                                                    <td style={{ textAlign: "center" }}>

                                                    </td>
                                                    <td style={{ textAlign: "center" }}>

                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    }
                                </Container>
                            </div>


                        </Container>
                    </div>
                </div>
            </React.Fragment>

        );
    }
}

