import React, { useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import UploadExcelFile from "src/pages/Events/uploadExcelFile";
import { Constants } from "src/Constants";
import { Button, Col, Container, Row, Table, Tooltip } from "reactstrap";
import { logoutUser } from "src/slices/thunks";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import moment from "moment";
import Store from "src/store";
import MoonLoader from "react-spinners/MoonLoader";

const Events = () => {
  const navigation = useHistory();

  const [eventList, setEventList] = useState<[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chosenValue, setChosenValue] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [isActiveEvent, setIsActiveEvent] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);

  localStorage.setItem("choosenValue", JSON.stringify(chosenValue));

  useEffect(() => {
    setIsLoading(true);
    loadEvents();
  }, []);

  function checkEventState(events: any) {
    for (const event of events) {
      if (event.eventState.state === 0 || event.eventState.state === 1) {
        setIsActiveEvent(true);
        setTooltipOpen(true);
        break;
      } else {
        setIsActiveEvent(false);
        setTooltipOpen(false);
      }
    }
  }

  useEffect(() => {
    checkEventState(eventList);
  }, [eventList, eventList.length]);

  const loadEvents = async () => {
    var response = await fetch(Constants.url + "admin/event?page=1&size=10", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (response.status == 401 || response.status == 403) {
      const dispatch = useDispatch();
      dispatch(logoutUser(history));
      return;
    }

    const res = await response.json();

    if (res.success) {
      setEventList(res.data);

      if (res.data) {
        const foundEvent = res.data.find(
          (event: any) =>
            event.eventState.state === 0 || event.eventState.state === 1
        );
        if (foundEvent) {
          setIsEdit(true);
          setChosenValue(foundEvent.eventState);
        } else {
        }
      }
    } else {
      alert("Server Error: " + res.error);
    }

    setIsLoading(false);
  };

  const editEvent = () => {
    /* this.setState({ choosenValue: chosenValue });
    Store.setData("choosenQuestion", this.state.choosenQuestion); */
    navigation.push("/editEvent");
  };

  const deleteEvent = async () => {
    var response = await fetch(Constants.url + "admin/event", {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (response.status == 401 || response.status == 403) {
      const dispatch = useDispatch();
      dispatch(logoutUser(history));
      return;
    } else if (response.status == 200) {
      alert("Etkinlik sonlandırılmıştır.");
      window.location.reload();
    }
  };

  const tooltipTargetId = "myTooltipTarget";

  return (
    <React.Fragment>
      <div className="page-content">
        {isLoading && (
          <Row
            style={{
              direction: "ltr",
              height: 500,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}
            >
              <MoonLoader color="#1a1a1a" size={80} speedMultiplier={0.5} />
            </div>
          </Row>
        )}
        {!isLoading && (
          <>
            <MetaTags>
              <title>Etkinlikler </title>
            </MetaTags>
            <Col>
              <h4 style={{ textAlign: "left", margin: "15px 0px 0px 35px" }}>
                Etkinlikler
              </h4>
            </Col>

            <div>
              <h5 style={{ textAlign: "left", margin: "15px 0px 0px 35px" }}>
                Etkinlik Ekleme
              </h5>
              <br />
            </div>
            <div></div>
            <div>
              <Container style={{ textAlign: "right", direction: "ltr" }}>
                <Row
                  style={{
                    justifyContent: "space-between",
                    marginBottom: 20,
                    paddingLeft: 20,
                    paddingRight: 20,
                    direction: "ltr",
                  }}
                >
                  <Col>
                    {isActiveEvent ? (
                      <>
                        <Button
                          style={{
                            backgroundColor: "darkblue",
                            marginRight: 4,
                            marginBottom: 2,
                            width: "150px",
                          }}
                          onClick={() => {
                            setIsEdit(true);
                            editEvent();
                          }}
                        >
                          Düzenle
                        </Button>
                        <Button
                          style={{
                            backgroundColor: "red",
                            width: "150px",
                          }}
                          onClick={() => deleteEvent()}
                        >
                          Etkinliği Bitir
                        </Button>{" "}
                      </>
                    ) : null}
                    <div id={tooltipTargetId}>
                      {tooltipOpen === true ? (
                        <span>Şu an aktif bir etkinlik bulunmaktadır.</span>
                      ) : null}
                      <Button
                        onClick={() => {
                          navigation.push("/addEvent");
                        }}
                        style={{
                          backgroundColor: tooltipOpen ? "gray" : "darkgreen",
                          marginLeft: 4,
                        }}
                        disabled={isActiveEvent}
                      >
                        Yeni Etkinlik Ekle
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Table bordered style={{ direction: "ltr", width: "100%" }}>
                      <thead>
                        <tr>
                          <th style={{ textAlign: "center" }}>Etkinlik</th>
                          <th style={{ textAlign: "center" }}>Başlama</th>
                          <th style={{ textAlign: "center" }}>Bitiş</th>
                          <th style={{ textAlign: "center" }}>Ödül</th>
                          <th style={{ textAlign: "center" }}>Durum</th>
                        </tr>
                      </thead>
                      <tbody>
                        {eventList.length > 0 &&
                          eventList.map((event: any, index: number) => (
                            <tr key={index}>
                              <td style={{ textAlign: "center" }}>
                                {event.eventState.game.name}
                              </td>

                              <td style={{ textAlign: "center" }}>
                                {event.eventState.startsIn != null
                                  ? moment
                                      .utc(event.eventState.startsIn * 1000)

                                      .format("DD/MM/YYYY HH:mm:ss")
                                  : "-"}
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {event.eventState.endsIn != null
                                  ? moment
                                      .utc(event.eventState.endsIn * 1000)

                                      .format("DD/MM/YYYY HH:mm:ss")
                                  : "-"}
                              </td>

                              <td style={{ textAlign: "center" }}>
                                {event.eventState.rewards.length} adet
                              </td>
                              <td style={{ textAlign: "center" }}>
                                {event.eventState.state === 0
                                  ? "Başlamadı"
                                  : event.eventState.state === 1
                                  ? "Devam Ediyor"
                                  : event.eventState.state === 2
                                  ? "Bitti"
                                  : ""}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </Container>
            </div>
            <br />
            <br />
          </>
        )}
      </div>
    </React.Fragment>
  );
};

export default Events;
