import React, { Component, useState } from "react";
import MetaTags from "react-meta-tags";
import { Table, ListGroup, ListGroupItem, Col, Row, Container, Form, FormGroup, Label, Input, FormText, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Config, { Constants } from "../../Constants";
import { logoutUser } from "src/slices/thunks";
import { useDispatch } from "react-redux";
import exportFromJSON from 'export-from-json';
import loading from '../../assets/images/loading.gif';


export default class usersPlatforms extends Component<any, any>  {
    constructor(props: any) {
        super(props);
        this.state = {
            result: [],
            isLoading: true
        };

        this.getUsersPlatforms = this.getUsersPlatforms.bind(this);
    }


    ExportToExcel = () => {
        const data = [{
            "Toplam Kullanıcı Sayısı": this.state.result.data.allUserCount,
            "Androidden Giriş Yapan Kullanıcı Yüzdesi": "% " + this.state.result.data.androidPercentage,
            "Iosdan Giriş Yapan Kullanıcı Yüzdesi": "% " + this.state.result.data.iosPercentage,
            "Webden Giriş Yapan Kullanıcı Yüzdesi": "% " + this.state.result.data.webPercentage
        }];

        const fileName = 'Kullanıcıların Giriş Yaptığı Platformların Oranları';
        const exportType = 'xls';
        exportFromJSON({ data, fileName, exportType })
    }

    componentDidMount() {
        this.getUsersPlatforms();
    }

    componentWillUnmount() {
        this.getUsersPlatforms();
    }


    async getUsersPlatforms() {
        try {
            var response = await fetch(Constants.url + "analytics/getUsersPlatform", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();
            this.setState({ result: result });
            this.setState({ isLoading: false });

            return result;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    render() {

        return (
            <React.Fragment>
                <div style={{ minHeight: "100vh" }}>
                    <div className="page-content">
                        <MetaTags>
                            <title>  Kullanıcıların Giriş Yaptığı Platformların Oranları </title>
                        </MetaTags>
                    </div>
                    <div>
                        <Container style={{ textAlign: "right", direction: "ltr", alignItems: 'center', flex: 1 }}>
                            <div>
                                <Row style={{ textAlign: "left", direction: "ltr", marginLeft: "30px" }}>
                                    <Col>
                                        <h5><b><u> Kullanıcıların Giriş Yaptığı Platformların Oranları </u></b></h5>
                                    </Col>
                                </Row>
                                <br />
                                <Row style={{ textAlign: "left", direction: "ltr", marginLeft: "30px" }}>
                                    <Col>
                                        <Link to="/analyticEvents" className="btn btn-primary">Geri</Link>
                                    </Col>
                                    <Col md={2} >
                                        <button type="button" className="btn btn-danger" onClick={this.ExportToExcel}>Kullanıcıların Giriş Yaptığı Platformların Oranları</button>
                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <div>

                            </div>
                            <Container style={{ direction: "ltr", flex: 1, alignContent: "center" }}>
                                {
                                    this.state.isLoading &&
                                    <div>
                                        <Col style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                            <img src={loading} alt="loading" style={{ width: "150px" }} />
                                            <h4>Bilgiler Yükleniyor...</h4>
                                        </Col>
                                    </div>
                                }

                            </Container>
                            <Col md={10}>
                                <Row>
                                    {!this.state.isLoading &&
                                        <Table bordered style={{ direction: "ltr", width: "800px" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "center", width: "300px" }}>
                                                        Toplam Kullanıcı Sayısı
                                                    </th>

                                                    <th style={{ textAlign: "center", width: "300px" }}>
                                                        Androidden Giriş Yapan Kullanıcı Yüzdesi
                                                    </th>
                                                    <th style={{ textAlign: "center", width: "300px" }}>
                                                        Iosdan Giriş Yapan Kullanıcı Yüzdesi
                                                    </th>
                                                    <th style={{ textAlign: "center", width: "300px" }}>
                                                        Webden Giriş Yapan Kullanıcı Yüzdesi
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.result != null && this.state.result != undefined && this.state.result.data != null ?
                                                    (<tr>
                                                        <td style={{ textAlign: "center" }}>
                                                            {this.state.result.data.allUserCount}
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            {"% " + this.state.result.data.androidPercentage}
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            {"% " + this.state.result.data.iosPercentage}
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            {"% " + this.state.result.data.webPercentage}
                                                        </td>
                                                    </tr>
                                                    )
                                                    : (<tr>
                                                        <td style={{ textAlign: "center" }}>

                                                        </td>
                                                        <td style={{ textAlign: "center" }}>

                                                        </td>
                                                        <td style={{ textAlign: "center" }}>

                                                        </td>
                                                        <td style={{ textAlign: "center" }}>

                                                        </td>
                                                    </tr>)}
                                            </tbody>
                                        </Table>
                                    }
                                </Row>
                            </Col>

                        </Container>
                    </div>
                </div>
            </React.Fragment>

        );
    }
}

