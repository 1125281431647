import React, { Component } from 'react';
import { Table, ListGroup, ListGroupItem, Col } from "reactstrap";


export default class GeneralInfo extends Component<any, any> { 
    constructor(props: any ){
        super(props);
        this.state = { 
            allUserCount : "",
            activeUserCount : "",
            todayUserCount : ""
         };        
    }    

    
    render() {
        const allUserCount = this.props.allUserCount;
        
        const activeUserCount = this.props.activeUserCount;

        const todayUserCount = this.props.todayUserCount;

        return (
            <div>                
               <Table borderless style={{ direction: "ltr"}}>
                    <tbody style={{ textAlign: "right"}}>                        
                         <tr>
                            <th>
                                Toplam Kullanıcı Sayısı :
                            </th>
                            <th>
                                { allUserCount }
                            </th>
                        </tr>                        
                         <tr >
                             <th>
                             Aktif Kullanıcı Sayısı :
                            </th>
                            <th>
                                { activeUserCount }
                            </th>
                        </tr>                         
                         <tr >
                             <th>
                             Bugün Oynayan Kullanıcı Sayısı :
                            </th>
                            <th>
                                { todayUserCount }
                            </th>
                        </tr> 
                    </tbody>
                </Table>
            </div>
        )
    }
}
