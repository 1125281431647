import moment from 'moment';
import React, { Component } from 'react';
import { useDispatch } from 'react-redux';
import { Table, ListGroup, ListGroupItem, Col, Button, Row } from "reactstrap";
import { Constants } from 'src/Constants';
import { logoutUser } from 'src/slices/thunks';
import i18n from '../../i18n';


export default class DuplicateUser extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            duplicateUserInfo: [],
            choosenUser : null
        }
    }

    async areYouSure() {
        var answer = window.confirm("Silmek istediğinizden emin misiniz?");
        if (answer) {
            try {
                var body = {
                    "id": this.state.choosenUser
                }
                var response = await fetch(Constants.url + "analytics/deleteDupliceUserById",
                    {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem("token")
                        },
                        body: JSON.stringify(body)
                    });
                if (response.status == 401 || response.status == 403) {
                    const dispatch = useDispatch();
                    dispatch(logoutUser(history));
                    return
                }
                if (response.status == 200) {
                    alert("Silme işlemi Başarılı");
                }
                var result = await response.json();
                return result;

            } catch (error) {
                console.log("error: ", error);
            }
        }
        else {
            this.props.history;
        }
    }

    render() {
        const duplicateUserInfo = this.props.duplicateUserDataFromParent.data;

        return (
            <div>
                <Col>
                    <Row>
                        <Table bordered style={{ direction: "ltr" }}>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: "center" }}>
                                        Kullanıcı Adı
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                        Oluşturlma Tarihi
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                        Seviyesi
                                    </th>
                                    <th style={{ textAlign: "center" }}>
                                        Seçim
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {duplicateUserInfo != null ? (duplicateUserInfo.map(
                                    (user: any, index: number) => (
                                        <tr key={index}>
                                            <td style={{ textAlign: "center" }}>
                                                {user.username}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {moment(user.createdDate).format('DD.MM.YYYY HH:mm:ss')}
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                {user.level}
                                            </td>
                                            
                                    <td style={{ textAlign: "center"}}>
                                        <input type="radio" name="choosenValue" value ={index} onClick={ async () =>{ await this.setState({choosenUser : user.id })}} />                                    
                                    </td>
                                        </tr>
                                    )
                                )) : (<tr>
                                    <td style={{ textAlign: "center" }}>

                                    </td>
                                    <td style={{ textAlign: "center" }}>

                                    </td>
                                    <td style={{ textAlign: "center" }}>

                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </Row>
                    <Row>
                        <Col xs={10}></Col>
                        <Col style={{ }}>
                            <Button style={{ backgroundColor: "darkblue", marginLeft : "25px"}} onClick={() => { this.areYouSure() }} >Sil</Button>
                        </Col>

                    </Row>
                </Col>


            </div>
        )
    }
}
