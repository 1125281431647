import { useDispatch } from 'react-redux';
import Config, { Constants } from "../../Constants";
import { logoutUser } from '../../../src/slices/thunks';
import moment from 'moment';
import * as XLSX from 'xlsx'
import axios from 'axios';

export default async function ExportAllData(startDate: Date, endDate: Date) {

    async function getGeneralStatistics() {
        try {
            async function getUserCount(value: string) {
                try {
                    var response = await fetch(Constants.url + "admin/" + value,
                        {
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + localStorage.getItem("token")
                            }
                        });

                    if (response.status == 401 || response.status == 403) {
                        const dispatch = useDispatch();
                        dispatch(logoutUser(history));
                        return
                    }

                    var result = await response.json();

                    return result;
                } catch (error) {
                    console.log("error: ", error);
                }
            }

            const allUserCount = await getUserCount("allUserCount");
            const activeUserCount = await getUserCount("activeUserCount");
            const todaysUserCount = await getUserCount("todaysUserCount");

            async function getValue() {
                try {
                    var body = {
                        "start": moment.utc(startDate).format('yyyy-MM-DD HH:mm:ss'),
                        "end": moment.utc(endDate).format('yyyy-MM-DD HH:mm:ss')
                    };

                    var response = await fetch(Constants.url + "analytics/getallevents",
                        {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + localStorage.getItem("token")
                            },
                            body: JSON.stringify(body)
                        });

                    if (response.status == 401 || response.status == 403) {
                        const dispatch = useDispatch();
                        dispatch(logoutUser(history));
                        return
                    }

                    var result = await response.json();
                    return result;

                } catch (error) {
                    console.log("error: ", error);
                }
            }

            const otherStat = await getValue();


            async function getKnowladgeAnsweredQuestionsValue() {
                try {
                    var response = await fetch(Constants.url + "analytics/knowladgeAnsweredQuestions", {
                        method: 'Get',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem("token")
                        }
                    });
                    if (response.status == 401 || response.status == 403) {
                        const dispatch = useDispatch();
                        dispatch(logoutUser(history));
                        return
                    }
                    var result = await response.json();

                    return result;

                } catch (error) {
                    console.log("error: ", error);
                }
            }

            const totalQuest = await getKnowladgeAnsweredQuestionsValue();

            const data = [{
                "Tarih": moment.utc().local().format('yyyy-MM-DD'),
                "Toplam Kullanıcı Sayısı": allUserCount,
                "Aktif Kullanıcı Sayısı": activeUserCount,
                "Bugün Oynayan Kullanıcı Sayısı": todaysUserCount,
                "Oyuna Günlük Yapılan Toplam Giriş Sayısı": otherStat.allLoginCount,
                "Oyuna Günlük Yapılan Farklı Kullanıcı Giriş Sayısı": otherStat.uniqueLoginCount,
                "Oyuna Aynı Anda En Yüksek Giriş Yapılan Tarih": moment.utc(otherStat.concurrentLoginCount.concurrentDate).local().format('DD-MMM-YYYY HH:mm:ss'),
                "Şimdiye Kadar Oyuna Aynı Anda Giriş Yapmış En yüksek Kullanıcı Sayısı": otherStat.concurrentLoginCount.count,
                "Toplam Oyun İçi Alınan Aksiyon Sayıları": otherStat.allActiveActionCount,
                "Oyundaki Teknik Bilgi Testlerindeki Cevaplanan Soru Sayısı": totalQuest
            }];

            return data;
        } catch (error) {

        }

    }



    async function getPlatformDatas() {
        try {
            var response = await fetch(Constants.url + "analytics/getUsersPlatform", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();

            const data = [{
                "Toplam Kullanıcı Sayısı": result.data.allUserCount,
                "Androidden Giriş Yapan Kullanıcı Yüzdesi": "% " + result.data.androidPercentage,
                "Iosdan Giriş Yapan Kullanıcı Yüzdesi": "% " + result.data.iosPercentage,
                "Webden Giriş Yapan Kullanıcı Yüzdesi": "% " + result.data.webPercentage
            }];

            return data;

        } catch (error) {
            console.log("error: ", error);
        }

    }

    async function getTodaysNewUser() {
        try {
            var body = {
                "dateTime": moment.utc().format('yyyy-MM-DD HH:mm:ss')
            };
            var response = await fetch(Constants.url + "analytics/getTodayNewUsersList", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(body)
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }

            var result = await response.json();

            if (result.length == 0 || result == null) {
                const data = [{ "Tarih": moment.utc().local().format('yyyy-MM-DD'), "Kullanıcı Adı": "-" }];
                return data;
            }
            else {
                const data = result.data.map((el: any) => {
                    return { "Tarih": moment.utc().local().format('yyyy-MM-DD'), "Kullanıcı Adı": el.username };
                });
                return data;
            }


        } catch (error) {
            console.log("error: ", error);
        }
    }

    async function getDailyLoginsData() {
        try {
            var response = await fetch(Constants.url + "analytics/getLoginDays", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();

            const data = result.data.map((el: any) => {
                return { "Gün": moment.utc(el.loginDate).local().format('yyyy-MM-DD'), "Toplam Giriş Sayısı": el.length != 0 ? el.count : "0" };
            });

            return data;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    async function getTotalLoginData() {
        try {
            var body = {
                "start": moment.utc().day(1).format('yyyy-MM-DD HH:mm:ss'),
                "end": moment.utc().format('yyyy-MM-DD HH:mm:ss'),
            };

            var response = await fetch(Constants.url + "analytics/getAllHourlyLogin", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(body)
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();

            const data = result.allLoginList.map((el: any, index: number) => {
                return {
                    "Giriş Yapılan Saatler": moment.utc(el.date).local().format('yyyy-MM-DD HH:mm:ss'),
                    "Yapılan Toplam Giriş Sayısı": el.length != 0 ? el.count : "0",
                    "Toplam Kalınan Süre": el.length != 0 ? el.sessionTotalInterval : "0"
                };
            });

            return data;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    async function getUniqueLoginData() {
        try {
            var body = {
                "start": moment.utc().day(1).format('yyyy-MM-DD HH:mm:ss'),
                "end": moment.utc().format('yyyy-MM-DD HH:mm:ss'),
            };

            var response = await fetch(Constants.url + "analytics/getUniqueHourlyLogin", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(body)
            });

            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }

            var result = await response.json();

            const data = result.allLoginList.map((el: any, index: number) => {
                return {
                    "Giriş Yapılan Saatler": moment.utc(el.date).local().format('yyyy-MM-DD HH:mm:ss'),
                    "Yapılan Toplam Giriş Sayısı": el.length != 0 ? el.count : "0",
                    "Kalınan Toplam Süre": el.length != 0 ? el.sessionTotalInterval : "0"
                };
            });
            return data;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    async function getXpData() {
        try {
            var response = await fetch(Constants.url + "analytics/getAllOrderedXp", {
                method: 'Get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();

            const data = result.analyticsOrderedUserDataDTO.map((el: any, index: number) => {
                return { "Sıralama": el.orderNumber, "Kullanıcı Adı": el.userName, "Deneyim Puanı": el.xp };
            });

            return data;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    async function getCampusValueData() {
        try {
            var response = await fetch(Constants.url + "analytics/getAllOrderedCampusValue", {
                method: 'Get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();

            const data = result.analyticsOrderedUserDataDTO.map((el: any, index: number) => {
                return { "Sıralama": el.orderNumber, "Kullanıcı Adı": el.userName, "Yerleşke Değeri": el.campusValue };
            });

            return data;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    async function getPrestijValueData() {
        try {
            var response = await fetch(Constants.url + "analytics/getOrderedPrestige", {
                method: 'Get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();

            const data = result.analyticsOrderedUserDataDTO.map((el: any, index: number) => {
                return { "Sıralama": el.orderNumber, "Kullanıcı Adı": el.userName, "Prestij Puanı": el.prestigePoint };
            });

            return data;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    async function getLevelData() {
        try {
            var response = await fetch(Constants.url + "analytics/getAllOrderedLevel", {
                method: 'Get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();

            const data = result.analyticsOrderedUserDataDTO.map((el: any, index: number) => {
                return { "Sıralama": el.orderNumber, "Kullanıcı Adı": el.userName, "Seviyesi": el.level };
            });

            return data;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    async function getIncompleteTutorialData() {
        try {
            var response = await fetch(Constants.url + "analytics/getIncompleteTutorialCount", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();

            var data = [{
                "Toplam Kullanıcı Sayısı": result.data.totalUsersCount, "Oyuna Başlangıç Tutorialını Tamamlamayan Kullanıcı Sayısı": result.data.startGameCount,
                "Profil Oluşturma Tutorialını Tamamlamayan Kullanıcı Sayısı": result.data.profileCount, "Teknik Bilgi Tutorialını Tamamlamayan Kullanıcı Sayısı": result.data.miniGameK1Count,
                "Problem Çözme Tutorialını Tamamlamayan Kullanıcı Sayısı": result.data.miniGamePS1Count, "Sürekli Öğrenme Tutorialını Tamamlamayan Kullanıcı Sayısı": result.data.miniGameF1Count
            }];
            return data;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    async function getPlayKnowlegdeGameData() {
        try {
            var body = {
                "miniGameType": "MiniGameK1"
            }
            var response = await fetch(Constants.url + "analytics/getUsersMostPlayedMiniGames", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(body)
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();

            const data = result.data.map((el: any, index: number) => {
                return { "Kullanıcı Adı": el.username, "Oyunun Toplam Oynanma Sayısı": el.playingCount, "Başarı Yüzdesi": "% " + el.successPercentage };
            });

            return data;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    async function getPlayProblemSolvingData() {
        try {
            var body = {
                "miniGameType": "MiniGamePS1"
            }
            var response = await fetch(Constants.url + "analytics/getUsersMostPlayedMiniGames", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(body)
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();

            const data = result.data.map((el: any, index: number) => {
                return { "Kullanıcı Adı": el.username, "Oyunun Toplam Oynanma Sayısı": el.playingCount, "Başarı Yüzdesi": "% " + el.successPercentage };
            });

            return data;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    async function getPlayFlexibilityData() {
        try {
            var body = {
                "miniGameType": "MiniGameF1"
            }
            var response = await fetch(Constants.url + "analytics/getUsersMostPlayedMiniGames", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(body)
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();

            const data = result.data.map((el: any, index: number) => {
                return { "Kullanıcı Adı": el.username, "Oyunun Toplam Oynanma Sayısı": el.playingCount, "Başarı Yüzdesi": "% " + el.successPercentage };
            });

            return data;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    async function getSolvingQuestionUserData() {
        try {
            var response = await fetch(Constants.url + "analytics/getAllAnswers", {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();

            const data = result.data.map((el: any, index: number) => {
                return {
                    "Soru Seviyesi": el.question.level, "Sorunun Kategorisi": el.question.category, "Yanıtlanan Sorular": el.question.question,
                    "Toplam Yanıtlama Sayısı": el.count, "Doğru Yanıtlanma Sayısı": el.correctAnswerCount, "Yanlış Yanıtlanma Sayısı": el.incorrectAnswerCount
                };
            });
            return data;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    async function getCorrectAnswerAccurecy() {
        try {
            var response = await fetch(Constants.url + "analytics/GetMostAccuracyRateAnsweredQuestion", {
                method: 'Get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            });

            var result = await response.json();

            const data = result.map((el: any, index: number) => {
                return { "Kullanıcı Adı": el.username, "Doğruluk Oranı": '% ' + el.accuracyRate };
            });

            return data;

        }
        catch (error) {

            console.log("error", error)
        }
    }

    async function getUserCount(value: string) {
        var count2 = 0;
        await fetch(Constants.url + "admin/" + value,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            })
            .then(response => response.json())
            .then(count => {
                console.log("count: ", count);
                count2 = count;
            })
            .catch((error) => console.log('fetchToken error: ', error));

        return count2;
    }

    let getHiredEmployeePercentegesData = async (count: number) => {
        let pageCount = Math.ceil(count / 100);

        var data: any[] = [];

        for (let i = 1; i <= pageCount; i++) {
            var res = await getData(i);
            data.push(...res);
        }

        return data;
    }

    async function getData(page: number) {
        try {
            var result = await axios(Constants.url + "analytics/getAllUserEmployee?page=" + page, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
            });
            if (result.status == 401 || result.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }

            const data = result.data.map((el: any, index: number) => {
                return {
                    "Kullanıcı Adı": el.userName, "Toplam İşe Aldığı Çalışan Sayısı": el.allEmployeesCount,
                    "Kadın Çalışan Yüzdesi": (el.allEmployeesCount == 0 ? "% 0" : "% " + el.womanEmployeePercentage.toFixed(2)),
                    "Erkek Çalışan Yüzdesi": (el.allEmployeesCount == 0 ? "% 0" : "% " + el.manEmployeePercentage.toFixed(2))
                };
            });

            return data;

        } catch (error) {
            console.log("error: ", error);
        }
    }


    async function getMostAnsweredQuestions() {
        try {
            var response = await fetch(Constants.url + "analytics/mostAnsweredQuestions", {
                method: 'Get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            });

            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }

            var answerResults = await response.json();

            var data = answerResults.map((el: any, index: number) => {
                return {
                    "Kullanıcı Adı": el.username,
                    "Cevaplanan Soru Sayısı": el.answerCount,
                    "Doğru Cevaplanan Soru Sayısı": el.correctAnswerCount,
                    "Yanlış Cevaplanan Soru Sayısı": (el.answerCount - el.correctAnswerCount),
                    "Başarı Yüzdesi": "%" + ((el.correctAnswerCount * 100) / el.answerCount).toFixed(2)
                };
            });
            return data;


        }
        catch (error) {

            console.log("error", error)
        }
    }

    const generalStatistics: any = await getGeneralStatistics();
    const platformDatas: any = await getPlatformDatas();
    const todaysNewUser: any = await getTodaysNewUser();
    const dailyLoginsData: any = await getDailyLoginsData();
    const totalLoginData: any = await getTotalLoginData();
    const uniqueLoginData: any = await getUniqueLoginData();
    const xpData: any = await getXpData(); //Deneyim
    const campusValueData: any = await getCampusValueData();
    const prestijValueData: any = await getPrestijValueData();
    const levelData: any = await getLevelData();
    const incompleteTutorialData: any = await getIncompleteTutorialData();
    const playKnowlegdeGameData: any = await getPlayKnowlegdeGameData();
    const playProblemSolvingData: any = await getPlayProblemSolvingData();
    const playFlexibilityData: any = await getPlayFlexibilityData();
    const getMostAnsweredQuestionsData = await getMostAnsweredQuestions();
    const solvingQuestionUserData: any = await getSolvingQuestionUserData();
    const correctAnswerAccurecy: any = await getCorrectAnswerAccurecy();
    const count = await getUserCount("allUserCount");
    const hiredEmployeePercentegesData = await getHiredEmployeePercentegesData(count);


    async function ExportToExcel() {

        var generalStatisticsWS = await XLSX.utils.json_to_sheet(generalStatistics);
        var platformDatasWS = await XLSX.utils.json_to_sheet(platformDatas);
        var todaysNewUserWS = await XLSX.utils.json_to_sheet(todaysNewUser);
        var dailyLoginsDataWS = await XLSX.utils.json_to_sheet(dailyLoginsData);
        var totalLoginDataWS = await XLSX.utils.json_to_sheet(totalLoginData);
        var uniqueLoginDataWS = await XLSX.utils.json_to_sheet(uniqueLoginData);
        var xpDataWS = await XLSX.utils.json_to_sheet(xpData);
        var campusValueDataWS = await XLSX.utils.json_to_sheet(campusValueData);
        var prestijValueDataWS = await XLSX.utils.json_to_sheet(prestijValueData);
        var levelDataWS = await XLSX.utils.json_to_sheet(levelData);
        var incompleteTutorialDataWS = await XLSX.utils.json_to_sheet(incompleteTutorialData);
        var playKnowlegdeGameDataWS = await XLSX.utils.json_to_sheet(playKnowlegdeGameData);
        var playProblemSolvingDataWS = await XLSX.utils.json_to_sheet(playProblemSolvingData);
        var playFlexibilityDataWS = await XLSX.utils.json_to_sheet(playFlexibilityData);
        var getMostAnsweredQuestionsDataWS = await XLSX.utils.json_to_sheet(getMostAnsweredQuestionsData);
        var solvingQuestionUserDataWS = await XLSX.utils.json_to_sheet(solvingQuestionUserData);
        var correctAnswerAccurecyWS = await XLSX.utils.json_to_sheet(correctAnswerAccurecy);
        var hiredEmployeePercentegesDataWS = await XLSX.utils.json_to_sheet(hiredEmployeePercentegesData);

        var wb = XLSX.utils.book_new()

        XLSX.utils.book_append_sheet(wb, generalStatisticsWS, 'Genelİstatistikler')
        XLSX.utils.book_append_sheet(wb, platformDatasWS, 'GirişYapılanPlatformOranları');
        XLSX.utils.book_append_sheet(wb, todaysNewUserWS, 'SistemeGirenYeniKullanıcılar');
        XLSX.utils.book_append_sheet(wb, dailyLoginsDataWS, 'GirilenGünlerVeToplamSayıları');
        XLSX.utils.book_append_sheet(wb, totalLoginDataWS, 'ToplamSaatlikGirişBilgileri');
        XLSX.utils.book_append_sheet(wb, uniqueLoginDataWS, 'UniqueSaatlikGirişBilgileri');
        XLSX.utils.book_append_sheet(wb, xpDataWS, 'KullanıcıDeneyimPuanıListesi');
        XLSX.utils.book_append_sheet(wb, campusValueDataWS, 'KullanıcıYerleşkeDeğeriListesi');
        XLSX.utils.book_append_sheet(wb, prestijValueDataWS, 'KullanıcıPrestijPuanıListesi');
        XLSX.utils.book_append_sheet(wb, levelDataWS, 'KullanıcıSeviyeListesi');
        XLSX.utils.book_append_sheet(wb, incompleteTutorialDataWS, 'TutoriallarınTamamlanmamaListes');
        XLSX.utils.book_append_sheet(wb, playKnowlegdeGameDataWS, 'TeknikBilgiOynayanKişiListesi');
        XLSX.utils.book_append_sheet(wb, playProblemSolvingDataWS, 'ProblemÇözmeOynayanKişiListesi');
        XLSX.utils.book_append_sheet(wb, playFlexibilityDataWS, 'SürekliÖğrenmeOynayanKişiListes');
        XLSX.utils.book_append_sheet(wb, getMostAnsweredQuestionsDataWS, 'EnÇokSoruYantlayanKullanıcı')
        XLSX.utils.book_append_sheet(wb, solvingQuestionUserDataWS, 'CevaplananSoruVeCevapOranları');
        XLSX.utils.book_append_sheet(wb, correctAnswerAccurecyWS, 'KişilerinSoruDoğrulukOranı');
        XLSX.utils.book_append_sheet(wb, hiredEmployeePercentegesDataWS, 'İşeAlımlarVeCinsiyetDağılımları');


        XLSX.writeFile(wb, 'İşLegendsTümKullanıcıİstatistikler.xlsx')
        console.log("excel", wb)
    }

    ExportToExcel();
}