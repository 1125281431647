import React, { Component, useState } from 'react';
import MetaTags from "react-meta-tags";
import ReactDrawer from "react-drawer";
import SimpleBar from "simplebar-react";
import "react-drawer/lib/react-drawer.css";
import Config, { Constants } from "../../Constants";
import { withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { logoutUser } from "src/slices/thunks";
import Store from "src/store";

import { Table, Form, FormGroup, Label, Input, FormText, Button, ListGroup, ListGroupItem } from 'reactstrap';

export default class SearchList extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            category: [],
            choosenValue: null,
            choosenQuestion: "",
            filteredQuestions: this.props.filteredQuestionfromParent
        };
    }

    componentDidMount() {
        this.setState({ filteredQuestions: this.props.filteredQuestionfromParent });
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevProps.filteredQuestionfromParent != this.props.filteredQuestionfromParent) {
            this.setState({ filteredQuestions: this.props.filteredQuestionfromParent });
        }
    }

    render() {

        return (
            <div className="page-content" style={{ textAlign: "left", direction: "ltr", alignItems: 'center', flex: 1 }}>
                <Form>
                    <div style={{ marginLeft: "35px" }}>
                        <h3> Arama Sonuçları</h3>
                    </div>
                    <br />
                    <Table bordered width="250px">
                        <thead>
                            <tr>
                                <th style={{ textAlign: "center" }}>
                                    Kategori
                                </th>
                                <th style={{ textAlign: "center" }}>
                                    Soru Seviyesi
                                </th>
                                <th style={{ textAlign: "center" }}>
                                    Soru
                                </th>
                                <th style={{ textAlign: "center" }}>
                                    Cevap 1
                                </th>
                                <th style={{ textAlign: "center" }}>
                                    Cevap 2
                                </th>
                                <th style={{ textAlign: "center" }}>
                                    Cevap 3
                                </th>
                                <th style={{ textAlign: "center" }}>
                                    Cevap 4
                                </th>
                                <th style={{ textAlign: "center" }}>
                                    Doğru Cevap
                                </th>
                                <th style={{ textAlign: "center" }}>
                                    Seçim
                                </th>
                            </tr>
                        </thead>
                        <tbody >
                            {this.state.filteredQuestions.data != null && this.state.filteredQuestions.data != undefined && this.state.filteredQuestions.data.length > 0 ? (this.state.filteredQuestions.data.map(
                                (que: any, index: number) => (
                                    <tr key={index}>
                                        <td style={{ textAlign: "center" }}>
                                            {que.category}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            {que.level}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            {que.question}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            {que.questionAnswers[0]}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            {que.questionAnswers[1]}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            {que.questionAnswers[2] != null ? que.questionAnswers[2] : ""}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            {que.questionAnswers[3] != null ? que.questionAnswers[3] : ""}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            {que.correctAnswer}
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <input type="radio"
                                                name="choosenValue"
                                                value={index}
                                                onClick={
                                                    async () => {
                                                        await this.setState({ choosenValue: index }),
                                                        await this.setState({ choosenQuestion: this.props.parentCallback(this.state.filteredQuestions.data[this.state.choosenValue]).id })
                                                    }}
                                            />
                                        </td>
                                    </tr>
                                )
                            )) : (<tr>
                                <td style={{ textAlign: "center" }}>

                                </td>
                                <td style={{ textAlign: "center" }}>

                                </td>
                                <td style={{ textAlign: "center" }}>

                                </td>
                                <td style={{ textAlign: "center" }}>

                                </td>
                                <td style={{ textAlign: "center" }}>

                                </td>
                                <td style={{ textAlign: "center" }}>

                                </td>
                                <td style={{ textAlign: "center" }}>

                                </td>
                                <td style={{ textAlign: "center" }}>

                                </td>
                            </tr>)}

                        </tbody>
                    </Table>
                </Form>
            </div>
        );
    }
}