import React, { Component } from 'react';
import { Table, ListGroup, ListGroupItem, Col } from "reactstrap";
import { PieChart } from 'react-minimal-pie-chart';



export default class IsActiveChart extends Component<any, any> {  
    constructor(props: any ){
        super(props);
        this.state = { 
            activeUserCount : "",
            passiveUserCount : ""
         };        
    }
                      
    render() {
        const activeUserCount = this.props.activeUserCount;
        const allUserCount = this.props.allUserCount;
        const defaultLabelStyle = {
            fontSize: '8px',
            fontFamily: 'sans-serif',
          };
        return (
            <div>                
               <Table borderless style={{ direction: "ltr"}}>
                    <tbody style={{ textAlign: "right"}}>                        
                         <tr >
                             <th>
                                Aktif/Pasif Kullanıcı Grafiği :
                            </th>
                            <th>      
                                <PieChart
                                    data={[
                                    { title: 'Aktif kullanıcılar', value: activeUserCount, color: '#C22416'},
                                    { title: 'Pasif kullanıcılar', value: (allUserCount- activeUserCount), color: '#C9DDEE' },
                                    ]}
                                    label={({ dataEntry }) => dataEntry.value}
                                    labelStyle={{
                                      ...defaultLabelStyle,
                                    }}
                                    style={{ width: "40%"}}
                                />                      
                            </th>
                        </tr> 
                    </tbody>
                </Table>
            </div>
        )
    }
}
