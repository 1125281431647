import React, { Component } from "react";
import { useDispatch } from "react-redux";
import {
  Table,
  Form,
  FormGroup,
  Label,
  Input,
  FormText,
  Button,
} from "reactstrap";
import Config, { Constants } from "../../Constants";
import { logoutUser } from "../../slices/thunks";

export default class UploadExcelFile extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      file: null,
    };
    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(event: React.ChangeEvent<HTMLInputElement>) {
    let files = event.target.files;
    if (files !== null && files.length > 0) {
      let file = files[0];
      this.setState({ file: file });
    } else {
      this.setState({ file: null });
    }
  }

  async onSubmit() {
    const formData = new FormData();
    formData.append("excelFile", this.state.file, this.state.file.name);
    if (this.state.file !== null && this.state.file !== "") {
      try {
        var response = await fetch(
          Constants.url + "admin/addQuestionFromExcel",
          {
            method: "POST",
            headers: {
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
            body: formData,
          }
        );

        if (response.status == 401 || response.status == 403) {
          const dispatch = useDispatch();
          dispatch(logoutUser(history));
          return;
        }
        var result = await response.json();
        this.setState({ userInfo: result });

        if (response.status == 200) {
          alert("Excel Dosyası Başarılı Bir Şekilde Kaydedildi");
        }
        return result;
      } catch (error) {
        console.log("error: ", error);
      }
    }
  }

  render() {
    return (
      <div
        style={{
          textAlign: "left",
          direction: "ltr",
          margin: "-30px 0px 0px 50px",
        }}
      >
        <Form>
          <FormGroup>
            <Label for="addFile">Soru yüklemek için bir dosya seçiniz :</Label>
            <Input
              id="addFile"
              name="addFile"
              type="file"
              accept=".xls,.xlsx"
              style={{ margin: "-30px 0px 0px 50px" }}
              onChange={this.onChange}
            />
            <Button
              style={{ backgroundColor: "darkblue" }}
              onClick={() => {
                if (this.props.isCallBack === true) {
                  this.props.handleOnSubmit(this.state.file);
                } else {
                  this.onSubmit;
                }
              }}
            >
              Ekle
            </Button>
          </FormGroup>
        </Form>
      </div>
    );
  }
}
