import React, {Component} from "react";
import { Col, Row, Button  } from 'reactstrap';
import { Redirect } from "react-router-dom";
import Config, {Constants} from "../../Constants";
import { logoutUser } from "src/slices/thunks";
import { useDispatch } from "react-redux";


export default class UserCheck  extends Component<any,any> {
    constructor(props:any) {
        super(props);
        this.state = { 
            userInfo : null,
            username : "",
            resultState : null
        };        
    }

    

    componentDidMount() {
        if ( this.state.username !== null && this.state.username !== "") {
            this.findToUser();
            this.sendData();
            this.userCheck();
        }

    }

    componentWillUnmount() {               
        if ( this.state.username !== null && this.state.username !== "") {
            this.sendData();
        }
    }

    async sendData () {        
        this.props.parentCallback(this.state.userInfo);        
    }

    async userCheck () {
        if(this.state.userInfo == null ) {
              alert("Kullanıcı bulunamadı");          
        }
    }

    async findToUser () {
        
        if ( this.state.username !== null && this.state.username !== "") {
            try {
                var response = await fetch(Constants.url + "admin/getUserInfo?username=" + this.state.username.trim(), 
                { 
                    headers:{ 'Content-Type': 'application/json',
                              'Authorization' : 'Bearer ' + localStorage.getItem("token") 
                    }
                });

                if ( response.status == 401 || response.status == 403){
                    const dispatch = useDispatch();
                    dispatch(logoutUser(history));
                    return
                }
                this.setState({resultState : response})
                var result = await response.json();
                this.setState({userInfo : result});  

                return result;

            } catch (error) {                
                console.log("error: " , error);
            }
        }
    }

    render() { 
        return (
            <React.Fragment>
                <form style={{ textAlign: "left" , direction: "ltr", margin:"15px 0px 0px 25px"}} action="javascript:void(0);">
                    <Row> 
                        <Col xs="4" >                    
                            <label>
                                Kullanıcı Adı:       
                                <input id="username" type="text" name="userName" onChange={ event =>  this.setState({username : event.target.value})} />
                            </label>
                        </Col>
                        <Col xs="3">
                             <Button style= {{ backgroundColor:"darkblue"}}  onClick={async () => { await this.findToUser(); this.sendData(); this.userCheck();}} > Göster </Button> 
                        </Col>            
                    </Row>  
                </form>
                <br />
            </React.Fragment>
         );
    }
}