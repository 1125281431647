import React, { Component } from 'react';
import { Table, ListGroup, ListGroupItem, Col } from "reactstrap";


export default class EmployeesTable extends Component<any, any> {  
    constructor(props: any ){
        super(props);
        this.state = {
            employeesInfo : []
        }
    } 

    render() {
        const employeesInfo = this.props.userEmployeesInfoDataFromParent.data;

        return (
            <div>    
                <h5 style={{ textAlign: "left"}}>Sahip Olduğu Çalışanlar :</h5> 
               <Table bordered style={{ direction: "ltr"}}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center"}}>
                                Çalışan Adı
                            </th>                            
                            <th style={{ textAlign: "center"}}>
                                Çalışanın Deneyim Yılı
                            </th>
                            <th style={{ textAlign: "center"}}>
                                Teknik Bilgi Yetkinlik Puanı
                            </th>
                            <th style={{ textAlign: "center"}}>
                                Sürekli Öğrenme Yetkinlik Puanı
                            </th>                            
                            <th style={{ textAlign: "center"}}>
                                Problem Çözme Yetkinlik Puanı
                            </th>
                            <th style={{ textAlign: "center"}}>
                                İnsan İlişkileri Yönetimi Yetkinlik Puanı
                            </th>
                            <th style={{ textAlign: "center"}}>
                                İnovasyon Yetkinlik Puanı
                            </th>                            
                            <th style={{ textAlign: "center"}}>
                                Müşteri Odaklılık Yetkinlik Puanı
                            </th>
                            <th style={{ textAlign: "center"}}>
                                Değerlere Uygunluk Yetkinlik Puanı
                            </th>
                        </tr>
                    </thead>
                    <tbody> 
                        { employeesInfo != null ? (employeesInfo.adminPanelUserEmployeeDTOs.map(
                            (employees : any, index : number) => (
                                <tr key={index}>
                                    <td style={{ textAlign: "center"}}>
                                        {employees.name}
                                    </td>
                                    <td style={{ textAlign: "center"}}>
                                        {employees.experience}
                                    </td>
                                    <td style={{ textAlign: "center"}}>
                                        {employees.knowledge}
                                    </td>
                                    <td style={{ textAlign: "center"}}>
                                        {employees.flexibility}  
                                    </td>
                                    <td style={{ textAlign: "center"}}>
                                        {employees.problemSolving} 
                                    </td>
                                    <td style={{ textAlign: "center"}}>
                                        {employees.communication}
                                    </td>
                                    <td style={{ textAlign: "center"}}>
                                        {employees.innovation}
                                    </td>
                                    <td style={{ textAlign: "center"}}>
                                        {employees.customerFocus}
                                    </td>
                                    <td style={{ textAlign: "center"}}>
                                    {employees.conformityToValues}
                                    </td>
                                </tr>
                            )
                        ) ): (<tr>
                            <td style={{ textAlign: "center"}}>
                            
                            </td>
                            <td style={{ textAlign: "center"}}>
                            
                            </td>
                            <td style={{ textAlign: "center"}}>
                                
                            </td>
                            <td style={{ textAlign: "center"}}>
                                
                            </td>
                            <td style={{ textAlign: "center"}}>
                                
                            </td>
                            <td style={{ textAlign: "center"}}>
                               
                            </td>
                            <td style={{ textAlign: "center"}}>
                               
                            </td>
                            <td style={{ textAlign: "center"}}>
                               
                            </td>
                            <td style={{ textAlign: "center"}}>
                               
                            </td>
                        </tr>)} 
                    </tbody>
                </Table>
            </div>
        )
    }
}
