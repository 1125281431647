import React, { Component } from 'react';
import { Table, ListGroup, ListGroupItem, Col } from "reactstrap";
import i18n from '../../i18n';


export default class AllUser extends Component<any, any> {  
    constructor(props: any ){
        super(props);
        this.state = {
           allUserInfo : []
        }
    } 

    render() { 
        const allUserInfo = this.props.allUserDataFromParent.data;    

        return (
            <div>  
               <Table bordered style={{ direction: "ltr"}}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center"}}>
                                Kullanıcı Adı
                            </th>
                        </tr>
                    </thead>
                    <tbody> 
                        { allUserInfo != null ? (allUserInfo.map(
                            (user : any, index : number) => (
                                <tr key={index}>
                                    <td style={{ textAlign: "center"}}>
                                        { user.username }
                                    </td>
                                </tr>
                            )
                        ) ): (<tr>
                            <td style={{ textAlign: "center"}}>
                            
                            </td>
                        </tr>)} 
                    </tbody>
                </Table>
            </div>
        )
    }
}
