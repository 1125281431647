import React, { useEffect } from "react";
import MetaTags from "react-meta-tags";
import UploadExcelFile from "src/pages/AddQuestions/uploadExcelFile";

import { Col, Container, Row, Table } from "reactstrap";
import AddQuestionOne from "./addQuestionOne";

interface Props {
  setQuestion?: any;
  handleQuestions: (data: any) => void;
  handleOnSubmit: (file: File) => void;
}

const AddQuestionForEvent = ({
  setQuestion,
  handleQuestions,
  handleOnSubmit,
}: Props) => {
  useEffect(() => {}, [handleOnSubmit]);

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Soru </title>
        </MetaTags>
        <Col>
          <h4 style={{ textAlign: "left", margin: "15px 0px 0px 35px" }}>
            Soru Ekleme
          </h4>
        </Col>
      </div>
      <div>
        <br />
        {/*  <AddQuestionOne
          setQuestion={setQuestion}
          isCallback={true}
          handleOnSubmit={(data: any) => {
            handleQuestions(data);
          }}
        /> */}
        <UploadExcelFile
          isCallBack={true}
          handleOnSubmit={(file: File) => handleOnSubmit(file)}
        />
      </div>
      <div>
        <Container style={{ textAlign: "right", direction: "ltr" }}>
          <Row></Row>
        </Container>
      </div>
      <br />
      <br />
    </React.Fragment>
  );
};

export default AddQuestionForEvent;
