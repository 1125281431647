export default class Store {
    static data : {[key: string] : any}  = {}  
    
    static setData(key : string, value : any) {
        Store.data[key] = value;     
    }
    static getData(key : string) : any {
        if ( Store.data.hasOwnProperty(key)){
            return Store.data[key];
        }
        return null;
    }
}

