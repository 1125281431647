interface MenuItemsProps {
  id: number;
  novidade?: any;
  label: string;
  icon?: string;
  link?: string;
  badge?: string;
  badgecolor?: string;
  subItems?: any;
  isHeader?: boolean;
}

const menuItems: Array<MenuItemsProps> = [
  {
    id: 1,
    label: "İşLegends",
    isHeader: true,
  },
  {
    id: 2,
    label: "Kullanıcı Kontrol",
    icon: "user-check",
    link: "/userControl",
    badgecolor: "badge-soft-secondary",
  },
  {
    id: 3,
    label: "Kullanıcı Sorgulama",
    icon: "user",
    link: "/userInfo",
    badgecolor: "badge-soft-secondary",
  },
  {
    id: 4,
    label: "Kullanıcı İstatistikleri",
    icon: "pie-chart",
    link: "/analyticEvents",
    badgecolor: "badge-soft-secondary",
  },
  {
    id: 9,
    label: "Kaynak Toplama",
    icon: "pie-chart",
    link: "/collectable/resources",
    badgecolor: "badge-soft-secondary",
  },
  {
    id: 5,
    label: "Sorular",
    icon: "book",
    badgecolor: "badge-soft-secondary",
    subItems: [
      {
        id: 6,
        label: "Soru Ekleme",
        icon: "file-plus",
        link: "/addQuestion",
        parentId: 5,
      },
      {
        id: 7,
        label: "Soru İşlemleri",
        icon: "edit",
        link: "/questionOperation",
        parentId: 5,
      },
    ],
  },
  {
    id: 8,
    label: "Etkinlikler",
    icon: "calendar",
    link: "/events",
    badgecolor: "badge-soft-secondary",
    subItems: [
      {
        id: 10,
        label: "Etkinlik Listesi",
        link: "/eventList",
        parentId: 8,
      },
    ],
  },
];

export { menuItems };
