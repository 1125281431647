import React, {Component} from "react";
import MetaTags from "react-meta-tags";
import { Table, Col, Row, Container } from "reactstrap";
import { Link } from "react-router-dom";
import  {Constants} from "../../Constants";
import Store from "src/store";
import exportFromJSON from 'export-from-json';
import "react-datepicker/dist/react-datepicker.css";
import { logoutUser } from "src/slices/thunks";
import { useDispatch } from "react-redux";
import loading from '../../assets/images/loading.gif';


export default class UserPrestigePoint extends Component<any,any>  {
    constructor(props:any) {
        super(props);
        this.state = { 
            personalData : [],
            userPrestigeInfo : Store.getData("userPrestigeInfo"),
            result : [],
            isLoading: true
        };
        this.getUser = this.getUser.bind(this);
        const value = Store.getData("userPrestigeInfo");
    }

    ExportToExcel = () => {  
        const data = this.state.result.analyticsOrderedUserDataDTO.map((el : any, index : number) => {
             return { "Sıralama" : el.orderNumber , "Kullanıcı Adı" : el.userName, "Prestij Puanı" : el.prestigePoint  };
        }); 
        
        const fileName = 'Kullanıcı Prestij Puanı Listesi' ;
        const exportType = 'xls' ;
        exportFromJSON({ data, fileName, exportType })  
    } 

    async getUser ( ) {
        try {
            var response = await fetch( Constants.url + "analytics/getOrderedPrestige", {
                method: 'Get',
                headers: {
                        'Content-Type': 'application/json',
                        'Authorization' : 'Bearer ' + localStorage.getItem("token") 
                        }
            });
            if ( response.status == 401 || response.status == 403){
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();

            this.setState({ isLoading: false });
              
            return result;

        } catch (error) {
            console.log("error: " , error);
        }  
    }
   

    componentDidMount() {
        this.getUser().then(result => {
            this.setState({result: result});
        });
    }


    render() {
        
        
        return (
            <React.Fragment>
                <div style={{ minHeight : "100vh"}}>
                    <div className="page-content">
                        <MetaTags>
                            <title> Kullanıcı Prestij Puanı Listesi </title>
                        </MetaTags>
                    </div>
                    <br />
                    <div>
                        <Row  style={{ textAlign: "left", direction: "ltr", alignItems: "center"}}>
                            <Col xs={1}></Col>
                            <Col md={8}>
                                <h5><b><u> Kullanıcı Prestij Puanı Listesi</u></b></h5>
                            </Col>                             
                        </Row>
                        <br />
                        <Row style={{ textAlign: "left", direction: "ltr"}}>
                            <Col xs={1}></Col>
                            <Col>
                                <Link to="/analyticEvents" className="btn btn-primary">Geri</Link>
                            </Col>                            
                            <Col md={3} >
                                <button type="button" className="btn btn-danger" onClick={this.ExportToExcel}>Kullanıcı Prestij Puanı Listesi İndir</button>  
                            </Col>
                        </Row>
                        <br />
                        <br />
                    </div>
                    <div>
                    <Container style={{ direction: "ltr", flex: 1, alignContent: "center" }}>
                            {
                                this.state.isLoading &&
                                    <div>
                                        <Col style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                            <img src={loading} alt="loading" style={{ width: "150px" }} />
                                            <h4>Bilgiler Yükleniyor...</h4>
                                        </Col>
                                    </div>
                            }
                            
                        </Container>
                        <Container style={{ textAlign: "right", direction: "ltr", width : "800px", alignItems:'center', flex: 1}}>
                        {!this.state.isLoading &&
                            <Table bordered style={{ direction: "ltr"}}>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center"}}>
                                            Sıralama
                                        </th>

                                        <th style={{ textAlign: "center"}}>
                                            Kullanıcı Adı
                                        </th>

                                        <th style={{ textAlign: "center"}}>
                                            Prestij Puanı
                                        </th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    {this.state.result.analyticsOrderedUserDataDTO != undefined && this.state.result.analyticsOrderedUserDataDTO != null ? (this.state.result.analyticsOrderedUserDataDTO.map(
                                        (users : any, index : number) => (
                                            <tr key={index}>
                                                <td style={{ textAlign: "center"}}>
                                                    {users.orderNumber}                               
                                                </td>
                                                <td style={{ textAlign: "center"}}>
                                                    {users.userName}
                                                </td>
                                                <td style={{ textAlign: "center"}}>
                                                    {users.prestigePoint}
                                                </td>
                                            </tr>
                                        )
                                    ) ): (<tr>
                                        <td style={{ textAlign: "center"}}>

                                        </td>
                                        <td style={{ textAlign: "center"}}>

                                        </td>
                                        <td style={{ textAlign: "center"}}>

                                        </td>
                                    </tr>)} 
                                </tbody>
                            </Table>
                            }
                            <br />
                        </Container>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

