import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Table, Col, Row, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { Constants } from "../../Constants";
import { logoutUser } from "src/slices/thunks";
import { useDispatch } from "react-redux";
import exportFromJSON from 'export-from-json';

import loading from '../../assets/images/loading.gif';


export default class userIncompleteTutorialList extends Component<any, any>  {
    constructor(props: any) {
        super(props);
        this.state = {
            result: [],
            isLoading: true
        };

        this.getTutorial = this.getTutorial.bind(this);
    }

    ExportToExcel = () => {
        var data = [{
            "Toplam Kullanıcı Sayısı": this.state.result.data.totalUsersCount,
            "Oyuna Başlangıç Tutorialını Tamamlamayan Kullanıcı Sayısı": this.state.result.data.startGameCount,
            "Profil Oluşturma Tutorialını Tamamlamayan Kullanıcı Sayısı": this.state.result.data.profileCount,
            "Problem Çözme Tutorialını Tamamlamayan Kullanıcı Sayısı": this.state.result.data.miniGamePS1Count
        }];
        const fileName = 'Tutorialları Tamamlamayan Kullanıcıların Listesi';
        const exportType = 'xls';
        exportFromJSON({ data, fileName, exportType })
    }

    componentDidMount() {
        this.getTutorial();
    }

    componentWillUnmount() {
        this.getTutorial();
    }


    async getTutorial() {
        try {
            var response = await fetch(Constants.url + "analytics/getIncompleteTutorialCount", {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();

            this.setState({ result: result });
            this.setState({ isLoading: false });

            return result;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    render() {

        return (
            <React.Fragment>
                <div style={{ minHeight: "100vh" }}>
                    <div className="page-content">
                        <MetaTags>
                            <title>  Tutorialları Tamamlamayan Kullanıcıların Sayılarının Listesi </title>
                        </MetaTags>
                    </div>
                    <div>
                        <Container style={{ textAlign: "right", direction: "ltr", alignItems: 'center', flex: 1 }}>
                            <div>
                                <Row style={{ textAlign: "left", direction: "ltr", marginLeft: "30px" }}>
                                    <Col>
                                        <h5><b><u> Tutorialları Tamamlamayan Kullanıcıların Sayılarının Listesi </u></b></h5>
                                    </Col>
                                </Row>
                                <br />
                                <Row style={{ textAlign: "left", direction: "ltr", marginLeft: "30px" }}>
                                    <Col>
                                        <Link to="/analyticEvents" className="btn btn-primary">Geri</Link>
                                    </Col>
                                    <Col md={2} >
                                        <button type="button" className="btn btn-danger" onClick={this.ExportToExcel}>Tutorialları Tamamlamayan Kullanıcıların Sayılarının Listesi</button>
                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            <div>
                                <Container style={{ direction: "ltr", flex: 1, alignContent: "center" }}>
                                    {
                                        this.state.isLoading &&
                                        <div>
                                            <Col style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={loading} alt="loading" style={{ width: "150px" }} />
                                                <h4>Bilgiler Yükleniyor...</h4>
                                            </Col>
                                        </div>
                                    }

                                </Container>
                                <Container style={{ textAlign: "right", direction: "ltr", width: "800px", alignItems: 'center', flex: 1 }}>
                                    {!this.state.isLoading &&
                                        <Table bordered style={{ direction: "ltr", width: "800px" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "center", width: "300px" }}>
                                                        Toplam Kullanıcı Sayısı
                                                    </th>
                                                    <th style={{ textAlign: "center", width: "300px" }}>
                                                        Oyuna Başlangıç Tutorialını Tamamlamayan Kullanıcı Sayısı
                                                    </th>
                                                    <th style={{ textAlign: "center", width: "300px" }}>
                                                        Profil Oluşturma Tutorialını Tamamlamayan Kullanıcı Sayısı
                                                    </th>
                                                    {/* <th style={{ textAlign: "center", width: "300px" }}>
                                                Teknik Bilgi Tutorialını Tamamlamayan Kullanıcı Sayısı
                                            </th> */}
                                                    <th style={{ textAlign: "center", width: "300px" }}>
                                                        Problem Çözme Tutorialını Tamamlamayan Kullanıcı Sayısı
                                                    </th>
                                                    {/* <th style={{ textAlign: "center", width: "300px" }}>
                                                Sürekli Öğrenme Tutorialını Tamamlamayan Kullanıcı Sayısı
                                            </th> */}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.result != null && this.state.result != undefined && this.state.result.data != null ? (
                                                    <tr>
                                                        <td style={{ textAlign: "center" }}>
                                                            {this.state.result.data.totalUsersCount}
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            {this.state.result.data.startGameCount}
                                                        </td>
                                                        <td style={{ textAlign: "center" }}>
                                                            {this.state.result.data.profileCount}
                                                        </td>
                                                        {/* <td style={{ textAlign: "center" }}>
                                                    {this.state.result.data.miniGameK1Count}
                                                </td> */}
                                                        <td style={{ textAlign: "center" }}>
                                                            {this.state.result.data.miniGamePS1Count}
                                                        </td>
                                                        {/* <td style={{ textAlign: "center" }}>
                                                    {this.state.result.data.miniGameF1Count}
                                                </td> */}
                                                    </tr>
                                                )
                                                    : (<tr>
                                                        <td style={{ textAlign: "center" }}>

                                                        </td>
                                                        <td style={{ textAlign: "center" }}>

                                                        </td>
                                                    </tr>)}
                                            </tbody>
                                        </Table>
                                    }
                                </Container>
                            </div>
                            <br />
                            <br />
                            <br />
                            <br />
                            {!this.state.isLoading &&
                            <Row>
                                <Col md={3}>
                                    <Table bordered style={{ direction: "ltr", width: "300px" }}>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center", width: "100px" }}>
                                                    Oyuna Başlangıç Tutorialını Tamamlamayan Kullanıcı Listesi
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.result.data != null ? (this.state.result.data.incompleStartGameUsers.map(
                                                (user: any, index: number) => (
                                                    <tr key={index}>
                                                        <td style={{ textAlign: "center" }}>  {user.username}</td>
                                                    </tr>
                                                )
                                            )) : (<tr>
                                                <td style={{ textAlign: "center" }}>

                                                </td>
                                            </tr>)
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                                <Col>
                                    <Table bordered style={{ direction: "ltr", width: "300px" }}>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center", width: "100px" }}>
                                                    Profil Oluşturma Tutorialını Tamamlamayan Kullanıcı Listesi
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.result.data != null ? (this.state.result.data.incompleProfileUsers.map(
                                                (user: any, index: number) => (
                                                    <tr key={index}>
                                                        <td style={{ textAlign: "center" }}>  {user.username}</td>
                                                    </tr>
                                                )
                                            )) : (<tr>
                                                <td style={{ textAlign: "center" }}>

                                                </td>
                                            </tr>)
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                                {/* <Col>
                                    <Table bordered style={{ direction: "ltr", width: "100px" }}>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center", width: "100px" }}>
                                                    Teknik Bilgi Tutorialını Tamamlamayan Kullanıcı Listesi
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.result.data != null ? (this.state.result.data.incompleK1Users.map(
                                                (user: any, index: number) => (
                                                    <tr key={index}>
                                                        <td style={{ textAlign: "center" }}>  {user.username}</td>
                                                    </tr>
                                                )
                                            )) : (<tr>
                                                <td style={{ textAlign: "center" }}>

                                                </td>
                                            </tr>)
                                            }
                                        </tbody>
                                    </Table>
                                </Col> */}
                                <Col>
                                    <Table bordered style={{ direction: "ltr", width: "300px" }}>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center", width: "100px" }}>
                                                    Problem Çözme Tutorialını Tamamlamayan Kullanıcı Listesi
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.result.data != null ? (this.state.result.data.incomplePS1Users.map(
                                                (user: any, index: number) => (
                                                    <tr key={index}>
                                                        <td style={{ textAlign: "center" }}>  {user.username}</td>
                                                    </tr>
                                                )
                                            )) : (<tr>
                                                <td style={{ textAlign: "center" }}>

                                                </td>
                                            </tr>)
                                            }
                                        </tbody>
                                    </Table>
                                </Col>
                                {/* <Col>
                                    <Table bordered style={{ direction: "ltr", width: "100px" }}>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center", width: "100px" }}>
                                                Sürekli Öğrenme Tutorialını Tamamlamayan Kullanıcı Listesi
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.result.data != null ? (this.state.result.data.incompleF1Users.map(
                                                (user: any, index: number) => (
                                                    <tr key={index}>
                                                        <td style={{ textAlign: "center" }}>  {user.username}</td>
                                                    </tr>
                                                )
                                            )) : (<tr>
                                                <td style={{ textAlign: "center" }}>

                                                </td>
                                            </tr>)
                                            }
                                        </tbody>
                                    </Table>
                                </Col> */}
                            </Row>
                            }

                        </Container>
                    </div>
                </div>
            </React.Fragment >

        );
    }
}

