import React, { Component } from 'react';
import { Table, ListGroup, ListGroupItem, Col } from "reactstrap";


export default class FriendsTable extends Component<any, any> {  
    constructor(props: any ){
        super(props);
        this.state = {
            friends : []
        }
    } 

    render() {
        const friendsInfo = this.props.userFriendsInfoDataFromParent.data;

        return (
            <div>    
                <h5 style={{ textAlign: "left"}}>Arkadaş Listesi :</h5> 
               <Table bordered style={{ direction: "ltr"}}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center"}}>
                                Kullanıcı Adı
                            </th>                            
                        </tr>
                    </thead>
                    <tbody> 
                        { (friendsInfo != null && friendsInfo.friends != null) ? (friendsInfo.friends.map(
                            (friendUserName : any, index : number) => (
                                <tr key={index}>
                                    <td style={{ textAlign: "center"}}>
                                        {friendUserName}
                                    </td>
                                </tr>
                            )
                        ) ): (<tr>
                        </tr>)} 
                    </tbody>
                </Table>
            </div>
        )
    }
}
