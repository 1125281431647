import { combineReducers } from 'redux'

import contactsReducer from './Contacts/reducer'

import layoutReducer from "./Layout/reducer"

// Calendar
import calendarReducer from "./Calendar/reducer";

//register
import registerReducer from "./Auth/Register/reducer";

//login
import loginReducer from "./Auth/Login/reducer";

//project
import projectsReducer from "./Projects/reducer";

// // User Profile 
import profileReducer from "./Auth/Profile/reducer";

// // Forget Password
import forgetPasswordReducer from "./Auth/Forgetpwd/reducer";


const rootReducer = combineReducers({
    Layout: layoutReducer,
    calendar: calendarReducer,
    register: registerReducer,
    login: loginReducer,
    forgetPassword: forgetPasswordReducer,
    projects: projectsReducer,
    profile: profileReducer
})

export default rootReducer
