export default class Config {}

export const Constants = {
  url: "/api/",
  //url : "http://localhost:5722/"
  //"http://0.0.0.0:5000/"
  //url: "https://server-test-be-bankworld.azurewebsites.net/",
  //"https://api.islegends.com/api"
  //url:"https://api.islegends.com/api/"
};
