import React, { Component } from 'react';
import { Table, ListGroup, ListGroupItem, Col } from "reactstrap";
import Config, { Constants } from "../../Constants";
import axios from "axios";
import moment from 'moment';
import { logoutUser } from 'src/slices/thunks';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

interface Properties {
    allUserCount: number,
    uniqueLoginCount: number,
    count: number,
    allActiveActionCount: number,
    startDate: Date,
    endDate: Date,
    newUserCount: number,
}

export default class GeneralInfo extends Component<any, any>  {
    constructor(props: Properties) {
        super(props);
        this.state = {
            newUserCount: 0,
        }
        this.getValue = this.getValue.bind(this);
        this.getNewUserCount = this.getNewUserCount.bind(this);
    }

    componentDidMount() {
        this.getValue();
        this.getNewUserCount();
    }

    componentDidUpdate(prevProps: any) {
        if (prevProps.startDate != this.props.startDate || prevProps.endDate != this.props.endDate) {
            this.getValue().then(result => {
                this.setState({ result: result });
            });
        }
    }


    async getValue() {
        try {
            var body = {
                "start": moment.utc(this.props.startDate).format('yyyy-MM-DD HH:mm:ss'),
                "end": moment.utc(this.props.endDate).format('yyyy-MM-DD HH:mm:ss')
            };

            var response = await fetch(Constants.url + "analytics/getallevents",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    },
                    body: JSON.stringify(body)
                });

            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }

            var result = await response.json();
            this.setState({ new: result });

            return result;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    async getNewUserCount() {
        try {
            var body = {
                "dateTime": moment.utc().format('yyyy-MM-DD HH:mm:ss')
            };

            var response = await fetch(Constants.url + "analytics/getTodayNewUsersCount",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    },
                    body: JSON.stringify(body)
                });

            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }

            var result = await response.json();
            this.setState({ newUserCount: result });

            return result;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    render() {
        return (
            <div>
                <Table style={{ direction: "ltr" }} borderless>
                    <thead>
                        <tr>
                            <th>
                                <b><u>Genel İstatistik :</u></b>
                            </th>
                        </tr>
                    </thead>
                    <tbody style={{ textAlign: "left" }}>
                        <tr style={{ margin: "25px 15px 0px 25px" }} >
                            <th>
                                Oyuna günlük yapılan toplam giriş sayısı : {this.state.result != null ? this.state.result.allLoginCount : ""}
                            </th>
                        </tr>
                        <tr >
                            <th>
                                Oyuna günlük yapılan unique giriş sayısı : {this.state.result != null ? this.state.result.uniqueLoginCount : ""}
                            </th>
                        </tr>
                        <tr >
                            <th>
                                Oyuna aynı anda en yüksek giriş yapılan tarih  : {this.state.result == null ? <text> </text> : (moment.utc(this.state.result.concurrentLoginCount.concurrentDate)).local().format('DD-MMM-YYYY HH:mm:ss')}
                            </th>
                        </tr>
                        <tr >
                            <th>
                                Oyuna aynı anda giriş yapmış en yüksek kullanıcı sayısı : {this.state.result != null ? this.state.result.concurrentLoginCount.count : ""}
                            </th>
                        </tr>
                        <tr >
                            <th>
                                Toplam oyun içi alınan aksiyon sayıları : {this.state.result != null ? this.state.result.allActiveActionCount : ""}
                            </th>
                        </tr>
                        <tr >
                            <th>
                                Bugün sisteme yeni katılan kullanıcıların sayısı : {this.state.newUserCount != null ? this.state.newUserCount : ""}
                            </th>
                        </tr>
                        <tr >
                            <th>
                                <Link to={this.state.newUserCount != 0 ? "/todayNewUsersList" : "/analyticEvents"}>
                                    Bugün sisteme yeni katılan kullanıcıların listesi
                                </Link>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <Link to="/usersPlatforms">
                                    Kullanıcıların Giriş Yaptığı Platformların Oranları
                                </Link>
                            </th>
                        </tr>
                        <tr >
                            <th>
                                <Link to="/usersLoginDaysList">
                                    Oyuna giriş yapılan günler ve bu günlerdeki toplam giriş sayıları listesi
                                </Link>
                            </th>
                        </tr>
                        <tr >
                            <th>
                                <Link to="/allhourlyLogin">
                                    Tüm sistem bazında bugün toplam oyuna saatlik giriş sayıları listesi
                                </Link>
                            </th>
                        </tr>
                        <tr >
                            <th>
                                <Link to="/uniquehourlyLogin">
                                    Tüm sistem bazında bugün unique oyuna saatlik giriş sayıları listesi
                                </Link>
                            </th>
                        </tr>
                        <tr >
                            <th>
                                <Link to="/userXpList">
                                    Oyundaki deneyim puanına göre sıralanmış kullanıcı listesi
                                </Link>
                            </th>
                        </tr>
                        <tr >
                            <th>
                                <Link to="/userCampusValueList">
                                    Oyundaki yerleşke değerine göre sıralanmış kullanıcı listesi
                                </Link>
                            </th>
                        </tr>
                        <tr >
                            <th>
                                <Link to="/userPrestigePointList">
                                    Oyundaki prestij puanına göre sıralanmış kullanıcı listesi
                                </Link>
                            </th>
                        </tr>
                        <tr >
                            <th>
                                <Link to="/usersLevelList">
                                    Oyundaki en yüksek seviyeye göre sıralanmış kullanıcı listesi
                                </Link>
                            </th>
                        </tr>
                        <tr >
                            <th>
                                <Link to="/incompleteTutorial">
                                    Tutorialları Tamamlamayan Kullanıcıların Sayılarının Listesi
                                </Link>
                            </th>
                        </tr>
                    </tbody>
                </Table>
            </div>
        )
    }
}
