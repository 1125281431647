import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Table, Form, FormGroup, Label, Input, ListGroup, ListGroupItem, Col, Row, Container } from "reactstrap";
import { Link } from "react-router-dom";
import Config, { Constants } from "../../Constants";
import moment from 'moment';
import Store from "src/store";
import { logoutUser } from "src/slices/thunks";
import { useDispatch } from "react-redux";
import exportFromJSON from 'export-from-json';


export default class MostCorrectAnsweredQuestion extends Component<any, any>  {
    constructor(props: any) {
        super(props);
        this.state = {
            personalData: [],
            userHourlyInfo: Store.getData("userInfoData"),
            result: [],
            category: [],
            choosenCategory: "All"
        };
        this.getUser = this.getUser.bind(this);
        this.getCategory = this.getCategory.bind(this);
        this.changeCategory = this.changeCategory.bind(this);
        const value = Store.getData("userInfoData");
    }

    ExportToExcel = () => {
        const data = this.state.result.data.map((el: any, index: number) => {
            return { "Soru Seviyesi": el.question.level, "Sorunun Kategorisi": el.question.category, "Yanlış Yanıtlanan Sorular": el.question.question, "Doğru Yanıtlama Sayısı": el.count };
        });
        const fileName = 'Kullanıcının Yanlış Yanıtladığı Sorular';
        const exportType = 'xls';
        exportFromJSON({ data, fileName, exportType })
    }

    async getUser() {
        try {
            var body = {
                "userId": this.state.userHourlyInfo.data.id
            };

            var response = await fetch(Constants.url + "analytics/getMostIncorrectlyAnswerCountByUserId", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(body)
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();
            this.setState({ result: result });

            return result;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    async getCategory() {
        try {
            var response = await fetch(Constants.url + "admin/getQuestionCategory",
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    }
                });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();
            this.setState({ category: result });

            return result;


        } catch (error) {
            console.log("error: ", error);
        }
    }

    async changeCategory(category: string) {

        if (category == "All") {
            return this.getUser();
        }
        else {
            try {
                var body = {
                    "userId": this.state.userHourlyInfo.data.id,
                    "category": category
                };

                var response = await fetch(Constants.url + "analytics/getMostIncorrectlyAnswerCountByUserIdCategory", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    },
                    body: JSON.stringify(body)
                });
                if (response.status == 401 || response.status == 403) {
                    const dispatch = useDispatch();
                    dispatch(logoutUser(history));
                    return
                }
                var result = await response.json();
                this.setState({ result: result });

                return result;

            } catch (error) {
                console.log("error: ", error);
            }
        }
    }


    componentDidMount() {
        this.setState({ choosenDate: Date.now() })
        this.getUser().then(result => {
            this.setState({ result: result });
        }); this.getCategory().then(result => {
            this.setState({ category: result });
        });
    }

    componentWillUnmount() {
        this.changeCategory(this.state.choosenCategory).then(result => {
            this.setState({ result: result })
        });
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ minHeight: "100vh" }}>
                    <div className="page-content">
                        <MetaTags>
                            <title> Kullanıcının Yanlış Yanıtladığı Sorular </title>
                        </MetaTags>
                    </div>
                    <br />
                    <div>
                        <Container style={{ textAlign: "right", direction: "ltr", alignItems: 'center', flex: 1 }}>
                            <div>
                                <Row style={{ textAlign: "left", direction: "ltr", margin: "-45px 0px 0px 30px" }}>
                                    <Col>
                                        <h5><b><u> Kullanıcının Yanlış Yanıtladığı Sorular </u></b></h5>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col style={{ textAlign: "left", direction: "ltr", marginLeft: "40px" }}>
                                        <Link to="/userInfo" className="btn btn-primary" onClick={this.props.history.pop}>Geri</Link>
                                    </Col>
                                    <Col md={3} >
                                        <button type="button" className="btn btn-danger" onClick={this.ExportToExcel}>Yanlış Yanıtlanan Soruları indir</button>
                                    </Col>
                                </Row>
                                <br />
                                <br />
                                <br />
                            </div>
                            <Col md={4} style={{ textAlign: "left", direction: "ltr", margin: "-45px 0px 0px 45px" }}>
                                <Form>
                                    <FormGroup>
                                        <Label for="category">
                                            Kategori Seçiniz
                                        </Label>
                                        <Input
                                            id="category"
                                            name="category"
                                            type="select"
                                            onChange={async event => { this.setState({ choosenCategory: event.target.value }); await this.changeCategory(event.target.value); }}
                                        >
                                            <option value="All">Tüm Kategoriler</option>
                                            {this.state.category != null ? (this.state.category.map(
                                                (category: any, index: number) => (
                                                    <option key={index} value={category.type}>
                                                        {category.type}
                                                    </option>
                                                ))) : (<option>  </option>)
                                            }
                                        </Input>
                                    </FormGroup>
                                </Form>
                            </Col>
                            <br />
                            <Col md={11}>
                                <Table bordered style={{ direction: "ltr" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: "center" }}>
                                                Soru Seviyesi
                                            </th>

                                            <th style={{ textAlign: "center" }}>
                                                Sorunun Kategorisi
                                            </th>
                                            <th style={{ textAlign: "center" }}>
                                                Yanlış Yanıtlanan Sorular
                                            </th>

                                            <th style={{ textAlign: "center" }}>
                                                Yanlış Yanıtlama Sayısı
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.result != null && this.state.result != undefined && this.state.result.data != null ? (this.state.result.data.map(
                                            (questions: any, index: number) => (
                                                <tr key={index}>
                                                    <td style={{ textAlign: "center" }}>
                                                        {questions.question.level}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {questions.question.category}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {questions.question.question}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {questions.count}
                                                    </td>
                                                </tr>
                                            )
                                        )) : (<tr>
                                            <td style={{ textAlign: "center" }}>

                                            </td>
                                            <td style={{ textAlign: "center" }}>

                                            </td>
                                        </tr>)}
                                    </tbody>
                                </Table>
                            </Col>
                        </Container>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

