import React, { Component, useState } from 'react';
import { Table, ListGroup, ListGroupItem, Col } from "reactstrap";
import moment from 'moment';


export default class PersonalInfo extends Component<any, any> {      
    constructor(props: any ){
        super(props);
        this.state = { 
            personalInfo : []
        };        
    } 
   
    render() {    
        const personalInfo = this.props.userInfoDataFromParent.data;    

        return (
            
            <div>                
               <Table borderless style={{ direction: "ltr"}}>
                    <tbody style={{ textAlign: "right"}}>                        
                        <tr >
                             <th>
                                Kullanıcı Adı :
                            </th>
                            <th >
                               {personalInfo == null ? <text> </text> : personalInfo.username}
                            </th>
                        </tr>                        
                         <tr >
                             <th>
                             Kullanıcı Seviyesi :
                            </th>
                            <th>
                               {personalInfo == null ? <text> </text> : personalInfo.level}
                            </th>
                        </tr>                         
                         <tr >
                             <th>
                             Kullanıcı Deneyim Puanı :
                            </th>
                            <th>
                               {personalInfo == null ? <text> </text> : personalInfo.xp}
                            </th>
                        </tr>                        
                         <tr >
                             <th>
                             Sahip Olduğu Elmas Miktarı :
                            </th>
                            <th >
                               {personalInfo == null ? <text> </text> : personalInfo.diamond}
                            </th>
                        </tr>                         
                         <tr >
                             <th>
                             Sahip Olduğu Para Miktarı :
                            </th>
                            <th>
                               {personalInfo == null ? <text> </text> : personalInfo.money}
                            </th>
                        </tr>                        
                         <tr >
                             <th>
                             Oyuna Başlama Tarihi :
                            </th>
                            <th>
                               {personalInfo == null ? <text> </text> : moment.utc(personalInfo.createdDate).local().format('DD-MMM-YYYY HH:mm:ss')}
                            </th>
                        </tr>                       
                         <tr >
                             <th>
                             Aktiflik Durumu :
                            </th>
                            <th>
                               {personalInfo == null ? <text> </text> :  (personalInfo.isActive == true || personalInfo.isActive == '1' ? <text>Aktif Kullanıcı</text> : <text>Pasif Kullanıcı</text>)}
                            </th>
                        </tr>   
                        <tr style={{ margin:"25px 15px 15px 25px"}} >
                             <th>
                                Oyunda geçirdiği süre :                                 
                            </th>
                            <th>
                            {personalInfo == null ? <text> </text> :  personalInfo.spentTimeInGame}
                            </th>
                        </tr>                          
                         <tr >
                            <th>
                                Oyun içinde aldığı aksiyon sayıları :
                            </th>
                            <th>
                            {personalInfo == null ? <text> </text> :  personalInfo.actionCount}
                            </th>
                        </tr>                           
                         <tr >
                            <th>
                                Oyuna giriş yaptığı platform :
                            </th>
                            <th>
                            {personalInfo == null ? <text> </text> :  personalInfo.platform}
                            </th>
                        </tr>                        
                       
                    </tbody>
                </Table>
            </div>
        )
    }
}
