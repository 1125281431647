import React, { Component } from "react";
import { Col, Row, Button, Container } from 'reactstrap';

import MetaTags from "react-meta-tags";
import { Constants } from "../../Constants";
import { logoutUser } from "src/slices/thunks";
import { useDispatch } from "react-redux";
import AllUser from "./AllUser";
import DuplicateUser from "./DuplicateUser";
import loading from '../../assets/images/loading.gif';
import exclamation from '../../assets/images/exclamation.gif';


export default class UserControl extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            allUser: null,
            dupliceUser: null,
            isLoading: true,
            error : null
        };
    }


    async userCheck() {
        if (this.state.dupliceUser == null) {
            alert("Tekrar Eden Kullanıcı İsmi Bulunamadı");
        }
    }

    async getAllUser() {
        try {
            var response = await fetch(Constants.url + "analytics/getAllUserList",
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    }
                });

            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();
            this.setState({ allUser: result });
            this.setState({ isLoading: false });
            return result;
        }
        catch (error) {
            this.setState({ isLoading: false });
            console.log("error", error)
            this.setState({ error: error });
        }
    }

    async getDupliceUser() {
        try {
            var response = await fetch(Constants.url + "analytics/getDuplicateUserList",
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    }
                });

            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();
            this.setState({ dupliceUser: result });
            this.setState({ isLoading: false });

            return result;

        }
        catch (error) {
            this.setState({ isLoading: false });
            console.log("error", error)
            this.setState({ error: error });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div className="page-content" >
                    <MetaTags>
                        <title>Kullanıcı Sorgulama</title>
                    </MetaTags>
                    <br />
                    <Row style={{ direction: "ltr", alignItems: 'center', flex: 1 }} >
                        <Col md={1}></Col>
                        <Col>
                            <h4 style={{ textAlign: "left" }}>Kullanıcı Sorgulama</h4>
                        </Col>
                    </Row>
                    <hr />
                    <form style={{ textAlign: "left", direction: "ltr", margin: "30px 0px 0px 30px" }} action="javascript:void(0);">
                        <Row>
                            <Col>
                                <Row>
                                    <Button style={{ backgroundColor: "darkblue", alignItems: "left" }} onClick={async () => { await this.getAllUser() }} > Tüm Kullanıcıları Göster </Button>
                                </Row>
                                <br />
                                <Row style={{ alignItems: "left" }}>
                                    {this.state.allUser != null ?
                                        <Container style={{ direction: "ltr", alignItems: 'left', flex: 1 }}>
                                            <Row style={{ textAlign: "center" }}>
                                                <h5 style={{ alignItems: "center" }}><b><u> Tüm Kullanıcılar </u></b></h5>
                                            </Row>
                                            <Row style={{ alignItems: "center", direction: "ltr" }}>
                                                <Container style={{ direction: "ltr", flex: 1, alignContent: "center", marginTop: '10px' }}>
                                                    {
                                                        this.state.isLoading &&
                                                        <div>
                                                            <Col style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                                                <img src={loading} alt="loading" style={{ width: "150px" }} />
                                                                <h4>Bilgiler Yükleniyor...</h4>
                                                            </Col>
                                                        </div>
                                                    }

                                                </Container>
                                                <Container>
                                                    {!this.state.isLoading && 
                                                        <AllUser allUserDataFromParent={this.state.allUser} />
                                                    }
                                                </Container>
                                            </Row>
                                        </Container>
                                        : 
                                        <Container style={{ direction: "ltr", flex: 1, alignContent: "center" }}>
                                            {!this.state.isLoading && this.state.error != null &&
                                                <div>
                                                    <Col style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                                        <img src={exclamation} alt="exclamation" style={{ width: "75px" }} />
                                                        <h4>Verileri Çekme Esnasında Hata Oluştu</h4>
                                                    </Col>
                                                </div>
                                            }
        
                                        </Container>
                                    }
                                </Row>
                            </Col>
                            <Col>
                                <Row>
                                    <Button style={{ backgroundColor: "darkblue", alignItems: "center" }} onClick={async () => { await this.getDupliceUser(); this.userCheck(); }} > Tekrarlayan Kullanıcıları Göster </Button>

                                </Row>
                                <br />
                                <Row>
                                    {this.state.dupliceUser != null ?
                                        <Container style={{ direction: "ltr", alignItems: 'center', flex: 1 }}>
                                            <Row style={{ textAlign: "center" }}>
                                                <h5 style={{ alignItems: "center" }}><b><u> Tekrarlayan Kullanıcılar </u></b></h5>
                                            </Row>
                                            <Container style={{ direction: "ltr", flex: 1, alignContent: "center", marginTop: '10px' }}>
                                                    {
                                                        this.state.isLoading &&
                                                        <div>
                                                            <Col style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                                                <img src={loading} alt="loading" style={{ width: "150px" }} />
                                                                <h4>Bilgiler Yükleniyor...</h4>
                                                            </Col>
                                                        </div>
                                                    }

                                                </Container>
                                            <Row style={{ alignItems: "center", direction: "ltr" }}>
                                            {!this.state.isLoading && 
                                                <Col>
                                                    <DuplicateUser duplicateUserDataFromParent={this.state.dupliceUser} />
                                                </Col>
    }
                                            </Row>
                                        </Container>
                                        : 
                                        <Container style={{ direction: "ltr", flex: 1, alignContent: "center" }}>
                                            {!this.state.isLoading && this.state.error != null &&
                                                <div>
                                                    <Col style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                                        <img src={exclamation} alt="exclamation" style={{ width: "75px" }} />
                                                        <h4>Verileri Çekme Esnasında Hata Oluştu</h4>
                                                    </Col>
                                                </div>
                                            }
        
                                        </Container>
                                    }
                                </Row>
                            </Col>
                            <Col xs={1}></Col>
                        </Row>

                    </form>
                </div>
                <br />
            </React.Fragment>
        );
    }
}