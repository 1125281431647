import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Table, Col, Row, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { Constants } from "../../Constants";
import moment from 'moment';
import Store from "src/store";
import exportFromJSON from 'export-from-json';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { logoutUser } from "src/slices/thunks";
import { useDispatch } from "react-redux";
import loading from '../../assets/images/loading.gif';

export default class UserHourlyLogin extends Component<any, any>  {
    constructor(props: any) {
        super(props);
        this.state = {
            choosenDate: new Date(),
            personalData: [],
            userHourlyInfo: Store.getData("userInfoData"),
            result: [],
            isLoading: true
        };
        this.getUser = this.getUser.bind(this);
        const value = Store.getData("userInfoData");
    }

    ExportToExcel = () => {
        const data = this.state.result.map((el: any, index: number) => {
            return { "Giriş Yapılan Saatler": moment.utc(el.date).local().format('yyyy-MM-DD HH:mm:ss'), "Yapılan Toplam Giriş Sayısı": el.count, "Toplam Kalınan Süre": el.sessionTotalInterval };
        });

        const fileName = 'Kullanıcı Saatlik Giriş Bilgileri';
        const exportType = 'xls';
        exportFromJSON({ data, fileName, exportType })
    }

    async getUser() {
        try {

            let date = this.state.choosenDate;
            let start = new Date(date)
            start.setHours(0, 0, 0);
            let end = new Date(date);
            end.setHours(23, 59, 59);

            var body = {
                "start": moment.utc(start).format('yyyy-MM-DD HH:mm:ss'),
                "end": moment.utc(end).format('yyyy-MM-DD HH:mm:ss'),
            };

            var response = await fetch(Constants.url + "analytics/getAllHourlyLogin", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(body)
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();
            var orderedResult = [...result.allLoginList].sort(
                (objA: any, objB: any) => new Date(objA.date).getTime() - new Date(objB.date).getTime());


            this.setState({ result: orderedResult });
            this.setState({ isLoading: false });

            return orderedResult;

        } catch (error) {
            console.log("error: ", error);
        }
    }


    componentDidMount() {
        this.setState({ choosenDate: Date.now() })
        this.getUser().then(result => {
            this.setState({ result: result });
        });
    }

    componentDidUpdate(prevProps: any, prevState: any) {
        if (prevState.choosenDate != this.state.choosenDate) {
            this.getUser().then(result => {
                this.setState({ result: result });
            });
        }
    }
    render() {


        return (
            <React.Fragment>
                <div style={{ minHeight: "100vh" }}>
                    <div className="page-content">
                        <MetaTags>
                            <title> Kullanıcı Saatlik Giriş Bilgileri </title>
                        </MetaTags>
                    </div>
                    <br />
                    <div>
                        <Row style={{ textAlign: "left", direction: "ltr", alignItems: "center" }}>
                            <Col xs={1}></Col>
                            <Col md={8}>
                                <h5><b><u> Kullanıcı Saatlik Giriş Bilgileri </u></b></h5>
                            </Col>
                            <Col md="">
                                <DatePicker
                                    selected={this.state.choosenDate}
                                    onChange={(date: any) => this.setState({ choosenDate: date })}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </Col>
                        </Row>
                        <br />
                        <Row style={{ textAlign: "left", direction: "ltr" }}>
                            <Col xs={1}></Col>
                            <Col>
                                <Link to="/analyticEvents" className="btn btn-primary">Geri</Link>
                            </Col>
                            <Col md={3} >
                                <button type="button" className="btn btn-danger" onClick={this.ExportToExcel}>Saatlik Giriş Bilgilerini İndir</button>
                            </Col>
                        </Row>
                        <br />
                        <br />
                    </div>
                    <div>
                        <Container style={{ direction: "ltr", flex: 1, alignContent: "center" }}>
                            {
                                this.state.isLoading &&
                                    <div>
                                        <Col style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                            <img src={loading} alt="loading" style={{ width: "150px" }} />
                                            <h4>Bilgiler Yükleniyor...</h4>
                                        </Col>
                                    </div>
                            }
                            
                        </Container>
                        <Container style={{ textAlign: "right", direction: "ltr", width: "800px", alignItems: 'center', flex: 1 }}>
                            {!this.state.isLoading &&
                                <Table bordered style={{ direction: "ltr" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: "center" }}>
                                                Giriş Yapılan Saatler
                                            </th>

                                            <th style={{ textAlign: "center" }}>
                                                Yapılan Toplam Giriş Sayısı
                                            </th>

                                            <th style={{ textAlign: "center" }}>
                                                Kalınan Toplam Süre
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.result != null ? (this.state.result.map(
                                            (choosenDate: any, index: number) => (
                                                <tr key={index}>
                                                    <td style={{ textAlign: "center" }}>
                                                        {(moment.utc(choosenDate.date)).local().format('HH:mm:ss')}
                                                        -
                                                        {(moment.utc(choosenDate.date)).local().format('HH:59:59')}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {choosenDate.count}
                                                    </td>
                                                    <td style={{ textAlign: "center" }}>
                                                        {choosenDate.sessionTotalInterval}
                                                    </td>
                                                </tr>
                                            )
                                        )) : (<tr>
                                            <td style={{ textAlign: "center" }}>

                                            </td>
                                            <td style={{ textAlign: "center" }}>

                                            </td>
                                            <td style={{ textAlign: "center" }}>

                                            </td>
                                        </tr>)}
                                    </tbody>
                                </Table>
                            }
                            <br />
                        </Container>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

