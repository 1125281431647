import React, { useEffect, useState } from "react";
import { Button, Col, Row, Table } from "reactstrap";
import { Constants } from "src/Constants";
import { useDispatch } from "react-redux";
import { logoutUser } from "src/slices/thunks";
import moment from "moment";
import { Link, useHistory } from "react-router-dom";
import Store from "src/store";
import AdminCollectableResourceDTO from "../Data/AdminCollectableResourceDTO";
import MoonLoader from "react-spinners/MoonLoader";

const ColelctableResources = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [collectableResources, setCollectableResources] = useState<AdminCollectableResourceDTO[]>([]);

    const navigation = useHistory()

    useEffect(() => {
        setIsLoading(true);
        loadResources();
    }, [])

    const loadResources = async () => {
        var response = await fetch(Constants.url + "admin/collectable/resource/all", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token") 
            }
        });
        if ( response.status == 401 || response.status == 403){
            const dispatch = useDispatch();
            dispatch(logoutUser(history));
            return;
        }
        
        const res = await response.json();

        if (res.success) {
            setCollectableResources(res.data.collectableResources);
        }
        else {
            alert("Server Error: " + res.error);
        }

        setIsLoading(false);
    }

    const editResource = (resource: AdminCollectableResourceDTO) => {
        Store.setData("collectableResourceId", resource.id);  
        navigation.push("/collectable/resources/edit");
    }

    const newResource = () => {
        Store.setData("collectableResourceId", null);  
        navigation.push("/collectable/resources/add");
    }


    return (
        <React.Fragment>

            <div className="page-content">
                { isLoading &&
                    <Row style={{direction: "ltr", height: 500, justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{display:'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            <MoonLoader color="#1a1a1a" size={80} speedMultiplier={0.5} />
                        </div>
                    </Row>
                }
                { !isLoading && 
                    <>
                        <Row style={{justifyContent: 'space-between', marginBottom: 20, paddingLeft: 20, paddingRight: 20, direction: "ltr"}}>
                            <Col>
                                <Link to="/" className="btn btn-primary">Geri</Link>
                            </Col>
                            <Col sm={1} style={{width: 200}}>
                                <Button onClick={newResource}>Yeni Kaynak Ekle</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <Table bordered style={{ direction: "ltr", width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: "center", width: '20%'}}>
                                                ID
                                            </th>
                                            <th style={{ textAlign: "center", width: '50%'}}>
                                                Görev
                                            </th>
                                            <th style={{ textAlign: "center"}}>
                                                Başlangıç
                                            </th>
                                            <th style={{ textAlign: "center"}}>
                                                Bitiş
                                            </th>
                                            <th style={{ textAlign: "center"}}>
                                                Aktif
                                            </th>
                                            <th style={{ textAlign: "center"}}>
                                                Düzenle
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { collectableResources.length > 0 && 
                                            collectableResources.map(
                                                (resource: AdminCollectableResourceDTO, index: number) => (
                                                    <tr key={index}>
                                                        <td style={{ textAlign: "center" }}>{resource.id}</td>
                                                        <td style={{ textAlign: "center" }}>{resource.description}</td>
                                                        <td style={{ textAlign: "center" }}>{resource.startDate != null ? (moment.utc(resource.startDate)).local().format('DD/MM/YYYY HH:mm:ss') : "-"}</td>
                                                        <td style={{ textAlign: "center" }}>{resource.endDate != null ? (moment.utc(resource.endDate)).local().format('DD/MM/YYYY HH:mm:ss') : "-"}</td>
                                                        <td style={{ textAlign: "center" }}>{resource.isActive ? "+" : "-"}</td>
                                                        <td style={{ textAlign: "center" }}>
                                                            <Button onClick={() => editResource(resource)}>Düzenle</Button>
                                                        </td>
                                                    </tr>
                                                )
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </>
                }
            </div>
        </React.Fragment>

    );

}

export default ColelctableResources;