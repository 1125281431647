import AdminCollectableResourceUserDTO from "./AdminCollectableResourceUserDTO";

export enum Currency {
    //None = "None",
    Money = "Money",
    //RND = "RND",
    Diamond = "Diamond",
    Energy = "Energy",
    //EducationMultiplier = "EducationMultiplier",
    //Cannot = "Cannot",
    Ticket = "Ticket",
};

interface AdminCollectableResourceDTO {
    id?: string;
    createdDate?: string;
    modifiedDate?: string;
    isActive?: boolean;

    description?: string;
    amountType?: Currency;
    amount?: number;
    allUsers?: boolean;
    startDate?: string;
    endDate?: string;
    canCollectMultiple?: boolean;
    collectPeriod?: number;

    users: AdminCollectableResourceUserDTO[];
};

export default AdminCollectableResourceDTO;