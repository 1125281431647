import i18n from "i18next";
import detector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
//import translationTR from 'locales/translation';

const resource = {
  tr: {
    translation: {
      "MainBuilding" : "Ana Merkez",
      "ProjectCenter" : "Proje Merkezi",
      "EmployeeCenter" : "Geleceğin Çalışanları Merkezi",
      "AgricultureCenter" : "Tarım Bankacılığı Merkezi",
      "SustainabilityCenter" : "Sürdürülebilirlik Merkezi",
      "ControlCenter" : "Denetim ve Uyum Merkezi",
      "DataCenter" : "Veri Analitiği Merkezi",
      "AutomationCenter" : "Otomasyon Merkezi",
      "FutureCenter" : "Geleceğin Bankacılığı Merkezi",
      "Decoration_1_1" : "Dekorasyon_1_1",
      "Decoration_1_2" : "Dekorasyon_1_2",
      "Decoration_1_3" : "Dekorasyon_1_3",
      "Decoration_1_4" : "Dekorasyon_1_4",
      "Decoration_1_5" : "Dekorasyon_1_5",
      "Decoration_1_6" : "Dekorasyon_1_6",
      "Decoration_1_7" : "Dekorasyon_1_7",
      "Decoration_1_8" : "Dekorasyon_1_8",
      "Decoration_2_1" : "Dekorasyon_2_1",
      "Decoration_3_1" : "Dekorasyon_3_1"
    }
  }
};

i18n
  .use(detector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({ 
    resources: resource, 
    keySeparator: false, // we do not use keys in form messages.welcome
    fallbackLng: "tr", 
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;