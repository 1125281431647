import React, { Component, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { Table, Col, Row, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { Constants } from "../../Constants";
import { logoutUser } from "src/slices/thunks";
import { useDispatch } from "react-redux";
import exportFromJSON from 'export-from-json';
import axios from "axios";
import exclamation from '../../assets/images/exclamation.gif';
import loading from '../../assets/images/loading.gif';

interface ICount {
    count: number;
}
export default class usersEmployeeData extends Component<any, any>  {
    constructor(props: any) {
        super(props);
        this.state = {
            result: [],
            isLoading: true,
            error: null
        };

        this.getUserCount = this.getUserCount.bind(this);
    }


    ExportToExcel = () => {
        const data = this.state.result.data.map((el: any, index: number) => {
            return {
                "Kullanıcı Adı": el.userName, "Toplam İşe Aldığı Çalışan Sayısı": el.allEmployeesCount,
                "Kadın Çalışan Yüzdesi": (el.allEmployeesCount == 0 ? "% 0" : "% " + el.womanEmployeePercentage.toFixed(2)),
                "Erkek Çalışan Yüzdesi": (el.allEmployeesCount == 0 ? "% 0" : "% " + el.manEmployeePercentage.toFixed(2))
            };
        });
        const fileName = 'Kullanıcı Bazında İşe Alımlar ve Cinsiyet Dağılımları';
        const exportType = 'xls';
        exportFromJSON({ data, fileName, exportType })
    }



    componentDidMount() {
        this.getUserCount("allUserCount");
    }

    componentWillUnmount() {
    }


    async getUserCount(value: string) {
        await fetch(Constants.url + "admin/" + value,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            })
            .then(response => response.json())
            .then(count => {
                this.getData(count);
            })
            .catch((error) => console.log('fetchToken error: ', error));

    }

    getData = async (count: number) => {
        let pageCount = Math.ceil(count / 100);

        for (let i = 1; i <= pageCount; i++) {
            await this.getUserEmploye(i);
        }
    }


    async getUserEmploye(page: number) {
        try {
            var response = await axios(Constants.url + "analytics/getAllUserEmployee?page=" + page, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                timeout: 600000,
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }

            this.setState((prevState: any, props: any) => {
                return { result: prevState.result.concat(response.data) }
            });
            this.setState({ isLoading: false });

            return response;

        } catch (error) {
            this.setState({ isLoading: false });
            console.log("error", error)
            this.setState({ error: error });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ minHeight: "100vh" }}>
                    <div className="page-content">
                        <MetaTags>
                            <title>  Kullanıcı Bazında İşe Alımlar ve Cinsiyet Dağılımları </title>
                        </MetaTags>
                    </div>
                    <div>
                        <Container style={{ textAlign: "right", direction: "ltr", alignItems: 'center', flex: 1 }}>
                            <div>
                                <Row style={{ textAlign: "left", direction: "ltr", marginLeft: "30px" }}>
                                    <Col>
                                        <h5><b><u> Kullanıcı Bazında İşe Alımlar ve Cinsiyet Dağılımları </u></b></h5>
                                    </Col>
                                </Row>
                                <br />
                                <Row style={{ textAlign: "left", direction: "ltr", marginLeft: "30px" }}>
                                    <Col>
                                        <Link to="/analyticEvents" className="btn btn-primary">Geri</Link>
                                    </Col>
                                    <Col md={2} >
                                        <button type="button" className="btn btn-danger" onClick={this.ExportToExcel}>Kullanıcı Bazında İşe Alımlar ve Cinsiyet Dağılımları</button>
                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <div>
                                <Container style={{ direction: "ltr", flex: 1, alignContent: "center", marginTop: '10px' }}>
                                    {
                                        this.state.isLoading &&
                                        <div>
                                            <Col style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={loading} alt="loading" style={{ width: "150px" }} />
                                                <h4>Bilgiler Yükleniyor...</h4>
                                            </Col>
                                        </div>
                                    }

                                </Container>
                                <Container style={{ direction: "ltr", flex: 1, alignContent: "center" }}>
                                    {!this.state.isLoading && this.state.error != null &&
                                        <div>
                                            <Col style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={exclamation} alt="exclamation" style={{ width: "75px" }} />
                                                <h4>Verileri Çekme Esnasında Hata Oluştu</h4>
                                            </Col>
                                        </div>
                                    }

                                </Container>
                                <Container style={{ textAlign: "right", direction: "ltr", width: "800px", alignItems: 'center', flex: 1 }}>
                                    {!this.state.isLoading && this.state.error == null &&
                                        <Table bordered style={{ direction: "ltr", width: "800px" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "center", width: "300px" }}>
                                                        Kullanıcı Adı
                                                    </th>

                                                    <th style={{ textAlign: "center", width: "300px" }}>
                                                        Toplam İşe Aldığı Çalışan Sayısı
                                                    </th>
                                                    <th style={{ textAlign: "center", width: "300px" }}>
                                                        Kadın Çalışan Yüzdesi
                                                    </th>
                                                    <th style={{ textAlign: "center", width: "300px" }}>
                                                        Erkek Çalışan Yüzdesi
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.result != null && this.state.result != undefined ? (this.state.result.map(
                                                    (employee: any, index: number) => (
                                                        <tr key={index}>
                                                            <td style={{ textAlign: "center" }}>
                                                                {employee.userName}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {employee.allEmployeesCount}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {employee.allEmployeesCount == 0 ? "% 0" : "% " + employee.womanEmployeePercentage.toFixed(2)}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {employee.allEmployeesCount == 0 ? "% 0" : "% " + employee.manEmployeePercentage.toFixed(2)}
                                                            </td>
                                                        </tr>
                                                    )
                                                )) : (<tr>
                                                    <td style={{ textAlign: "center" }}>

                                                    </td>
                                                    <td style={{ textAlign: "center" }}>

                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    }
                                </Container>
                            </div>
                        </Container>
                    </div>
                </div>
            </React.Fragment>

        );
    }
}

