import React, { Component, useEffect, useState } from "react";
import MetaTags from "react-meta-tags";
import Config, { Constants } from "../../Constants";

import {
  Form,
  FormGroup,
  Label,
  Col,
  Input,
  Button,
  Badge,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { logoutUser } from "src/slices/thunks";
import { useDispatch } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import DatePicker from "react-datepicker";

import AddQuestionForEvent from "./addQuestionForEvent";
import { useHistory } from "react-router";

interface BadgeItem {
  id: number;
  type: string;
  amount: string;
}

interface GameInfo {
  name?: string;
  imageUrl?: string;
  type?: number;
  description?: string;
  questions?: [];
}

interface Question {
  text: string;
  categoryType: string;
  correctAnswer: number;
  time: number;
  answers: Answer[];
}

interface Answer {
  option: number;
  answer: string;
}

const AddEvent = () => {
  const navigation = useHistory();
  const [result, setResult] = useState<any[]>([]);
  const [gameInfo, setGameInfo] = useState<GameInfo>({});
  const [startsIn, setStartsIn] = useState(new Date());
  const [endsIn, setEndsIn] = useState(new Date());
  const [cost, setCost] = useState(0);
  const [playCount, setPlayCount] = useState(1);
  const [selected1, setSelected1] = useState("");
  const [selected2, setSelected2] = useState("");
  const [selected3, setSelected3] = useState("");
  const [selectedAmount1, setSelectedAmount1] = useState("");
  const [selectedAmount2, setSelectedAmount2] = useState("");
  const [selectedAmount3, setSelectedAmount3] = useState("");
  const [firstRewardList, setFirstRewardList] = useState<
    { id: number; type: string; amount: string }[]
  >([]);

  const [secondRewardList, setSecondRewardList] = useState<
    { id: number; type: string; amount: string }[]
  >([]);
  const [thirdRewardList, setThirdRewardList] = useState<
    { id: number; type: string; amount: string }[]
  >([]);
  const [allRewardsList, setAllRewardsList] = useState<
    { type: string; amount: string }[][]
  >([]);
  const [badges1, setBadges1] = useState<BadgeItem[]>([]);
  const [badges2, setBadges2] = useState<BadgeItem[]>([]);
  const [badges3, setBadges3] = useState<BadgeItem[]>([]);
  const [question, setQuestion] = useState<Question>();
  const [questionsList, setQuestionsList] = useState<Question[]>([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [questionFile, setQuestionFile] = useState<any>();
  const [gameTypeTechnic, setGameTypeTechnic] = useState(false);
  const [badgeCode, setBadgeCode] = useState<string | undefined>(undefined);

  const rewardTypes = [
    { value: "Money", id: 0 },
    { value: "Diamond", id: 2 },
    { value: "Energy", id: 3 },
  ];

  const gameType = [
    { value: "Teknik Bilgi", id: 1 },
    { value: "Çarkıfelek", id: 2 },
  ];

  const [openRewardType1, setOpenRewardType1] = useState(false);
  const toggleReward1 = () => setOpenRewardType1(prevState => !prevState);
  const [openRewardType2, setOpenRewardType2] = useState(false);
  const toggleReward2 = () => setOpenRewardType2(prevState => !prevState);
  const [openRewardType3, setOpenRewardType3] = useState(false);
  const toggleReward3 = () => setOpenRewardType3(prevState => !prevState);
  const [openGameType, setOpenGameType] = useState(false);
  const toggleGameType = () => setOpenGameType(prevState => !prevState);

  const [badgeOpen, setBadgeOpen] = useState(false);
  const toggleBadge = () => setBadgeOpen(prevState => !prevState);

  const handleSelectValue = (value: string, index: number) => {
    if (index === 1) {
      setSelected1(value);
      setOpenRewardType1(false);
    } else if (index === 2) {
      setSelected2(value);
      setOpenRewardType2(false);
    } else {
      setSelected3(value);
      setOpenRewardType3(false);
    }
  };

  const handleSelectGameType = (value: string) => {
    if (value === "Teknik Bilgi") {
      setGameInfo(prevGameInfo => ({
        ...prevGameInfo,
        type: 1,
      }));
      setOpenGameType(false);
    } else if (value === "Çarkıfelek") {
      setGameInfo(prevGameInfo => ({
        ...prevGameInfo,
        type: 2,
      }));
      setOpenGameType(false);
    }
  };

  const deleteBadge = (index: number, id: number) => {
    if (index === 1) {
      setBadges1(prevBadges => prevBadges.filter(badge => badge.id !== id));
    } else if (id === 2) {
      setBadges2(prevBadges => prevBadges.filter(badge => badge.id !== id));
    } else if (id === 3) {
      setBadges3(prevBadges => prevBadges.filter(badge => badge.id !== id));
    }
  };

  const handleClearInputs = () => {
    window.location.reload();
    navigation.push("/eventList");
  };

  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    const handleError = (error: ErrorEvent) => {
      setError(new Error(error.message));
    };

    window.addEventListener("error", handleError);

    return () => {
      window.removeEventListener("error", handleError);
    };
  }, []);

  useEffect(() => {
    if (error) {
      console.error("Bir hata oluştu:", error);

      window.location.reload();
    }
  }, [error]);

  useEffect(() => {
    if (
      gameInfo.name &&
      gameInfo.name !== "" &&
      gameInfo.imageUrl &&
      gameInfo.imageUrl !== "" &&
      gameInfo.type &&
      gameInfo.description &&
      gameInfo.description !== "" &&
      startsIn &&
      endsIn &&
      cost &&
      playCount &&
      questionFile
    ) {
      setIsDisabled(false);
    }
  }, [
    gameInfo.name,
    gameInfo.imageUrl,
    gameInfo.type,
    gameInfo.description,
    startsIn,
    endsIn,
    cost,
    playCount,

    questionFile,
  ]);

  const handleAddInput = (listIndex: number) => {
    if (selected1 && selectedAmount1) {
      if (listIndex === 1) {
        const newReward = {
          id: Math.random(),
          type: selected1,
          amount: selectedAmount1,
        };
        setFirstRewardList(prevList => [...prevList, newReward]);
        setSelectedAmount1("");
        setSelected1("");
        const newBadge: BadgeItem = {
          id: Math.random(),
          amount: selectedAmount1,
          type: selected1,
        };
        setBadges1(prevBadges => [...prevBadges, newBadge]);
      }
    } else if (selected2 && selectedAmount2) {
      if (listIndex === 2) {
        const newReward = {
          id: Math.random(),
          type: selected2,
          amount: selectedAmount2,
        };
        setSecondRewardList(prevList => [...prevList, newReward]);
        setSelectedAmount2("");
        setSelected2("");
        const newBadge: BadgeItem = {
          id: Math.random(),
          amount: selectedAmount2,
          type: selected2,
        };
        setBadges2(prevBadges => [...prevBadges, newBadge]);
      }
    } else if (selected3 && selectedAmount3) {
      if (listIndex === 3) {
        const newReward = {
          id: Math.random(),
          type: selected3,
          amount: selectedAmount3,
        };
        setThirdRewardList(prevList => [...prevList, newReward]);
        setSelectedAmount3("");
        setSelected3("");
        const newBadge: BadgeItem = {
          id: Math.random(),
          amount: selectedAmount3,
          type: selected3,
        };
        setBadges3(prevBadges => [...prevBadges, newBadge]);
      }
    } else {
      return;
    }
  };

  const handleInputChange = (value: string, index: number) => {
    const inputValue = value.replace(/[^\d]/g, "");
    if (index === 1) {
      setSelectedAmount1(inputValue);
    } else if (index === 2) {
      setSelectedAmount2(inputValue);
    } else {
      setSelectedAmount3(inputValue);
    }
    return inputValue;
  };

  const handleNumberInput = (value: string) => {
    const inputValue = value.replace(/[^\d]/g, "");
    return inputValue;
  };
  const handleChangeNumber = (event: any, id: number) => {
    if (id === 0) {
      setCost(event);
    } else if (id === 1) {
      setPlayCount(event);
    }
  };

  function handleChangeAmount(event: any, index: number) {
    if (index === 1) {
      setSelectedAmount1(event);
    } else if (index === 2) {
      setSelectedAmount2(event);
    } else {
      setSelectedAmount3(event);
    }
  }

  useEffect(() => {
    if (question && question.text) {
      const newQuestion: Question = {
        text: question.text,
        categoryType: question.categoryType || "",
        correctAnswer: question.correctAnswer || 0,
        time: Number(question.time) || 0,
        answers: question.answers || [],
      };

      setQuestionsList(prev => [...prev, newQuestion]);

      setQuestion(undefined);
    }
  }, [question]);

  const groupBy = function (xs: any, key: any) {
    return xs.reduce(function (rv: any, x: any) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };

  useEffect(() => {
    if (gameInfo.type == 1) {
      setPlayCount(3);
      setGameTypeTechnic(true);
    } else if (gameInfo.type == 2) {
      setGameTypeTechnic(false);
    }
  }, [gameInfo.type]);

  const addingEvent = async () => {
    let a = groupBy(questionsList, "categoryType");

    try {
      const gameData = {
        name: gameInfo.name,
        imageUrl: gameInfo.imageUrl,
        type: gameInfo.type,
        description: gameInfo.description,
      };

      var startDate = new Date(startsIn);
      startDate.setHours(startDate.getHours() + 3);
      var endDate = new Date(endsIn);
      endDate.setHours(endDate.getHours() + 3);

      var body = {
        timeToStart: startDate.getTime() / 1000,
        timeToEnd: endDate.getTime() / 1000,
        cost: cost,
        playCount: playCount,
        game: gameData,
        rewards: allRewardsList,
        badgeCode: badgeCode,
      };

      var response = await fetch(Constants.url + "admin/event", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(body),
      });

      if (response.status == 401 || response.status == 403) {
        alert(
          "Kayıt Oluşturulamadı. Eksik alanları kontrol edip tekrar deneyiniz."
        );
        const dispatch = useDispatch();
        dispatch(logoutUser(history));
        return;
      }

      const res = await response.json();

      if (res.success == true) {
        alert("Etkinlik Kaydı Başarılı");

        try {
          if (questionFile) {
            const formData = new FormData();
            formData.append("excelFile", questionFile, questionFile.name);

            var response = await fetch(Constants.url + "admin/event/question", {
              method: "POST",
              headers: {
                Authorization: "Bearer " + localStorage.getItem("token"),
              },
              body: formData,
            });
          }
          const resFile = await response.json();
          if (response.status == 401 || response.status == 403) {
            alert(
              "Kayıt Oluşturulamadı. Eksik alanları kontrol edip tekrar deneyiniz."
            );
            const dispatch = useDispatch();
            dispatch(logoutUser(history));
            return;
          }
          if (resFile.success == true) {
            alert("Soru Ekleme Kaydı Başarılı");
            handleClearInputs();
          }
        } catch (error) {
          console.log("error: ", error);
        }
      }
      if (res.success === false) {
        alert("Kayıt Başarısız Olmuştur.");
      }

      var result = await response.json();
      setResult(result);

      return result;
    } catch (error) {
      console.log("error: ", error);
    }
  };

  useEffect(() => {
    if (allRewardsList.length !== 0 && questionsList) {
      addingEvent();
    }
  }, [allRewardsList]);

  const addEvent = async () => {
    const mergedList = [firstRewardList, secondRewardList, thirdRewardList];

    setAllRewardsList(mergedList);
  };

  /*  const componentDidMount = () => {
    getCategory().then(result => {
      setResult(result);
    });
  };

  const componentWillUnmount = () => {
    addEvent().then(result => {
      setSend(result);
    });
  }; */

  function addDays(date: Date, days: number) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  const handleOnSubmit = (file: any) => {
    setQuestionFile(file);
    alert("Excel Dosyası Başarılı Bir Şekilde Eklendi");
  };

  return (
    <>
      <div
        className="page-content"
        style={{
          textAlign: "left",
          direction: "ltr",
          margin: "50px 0px 0px 50px",
        }}
      >
        <Col style={{ marginBottom: "10px" }}>
          <Link to="/eventList" className="btn btn-primary">
            Geri
          </Link>
        </Col>

        <h5 style={{ fontWeight: "bold", fontSize: "20px" }}>
          Etkinlik Ekleme:
        </h5>
        <Form>
          <FormGroup>
            <Label>Başlama Zamanı</Label>
            <div>
              <DatePicker
                showIcon
                selected={
                  startsIn ? new Date(startsIn) : addDays(new Date(), 1)
                }
                onChange={date => {
                  const selectedDate = date ?? new Date();
                  setStartsIn(selectedDate);
                }}
                minDate={addDays(new Date(), 1)}
                dateFormat="dd/MM/yyyy HH:mm aa"
                required={true}
                showTimeSelect
                timeIntervals={30}
                timeCaption="Time"
                onKeyDown={e => {
                  e.preventDefault();
                }}
              />
            </div>

            <Label>Bitiş Zamanı</Label>

            <DatePicker
              showIcon
              selected={endsIn ? new Date(endsIn) : addDays(new Date(), 1)}
              onChange={date => {
                const selectedDate = date ?? new Date();
                setEndsIn(selectedDate);
              }}
              minDate={addDays(new Date(), 1)}
              dateFormat="dd/MM/yyyy HH:mm aa"
              required={true}
              showTimeSelect
              timeIntervals={30}
              timeCaption="Time"
              onKeyDown={e => {
                e.preventDefault();
              }}
            />
            <Label>Bilet Adedi</Label>
            <Input
              type="text"
              onChange={event => {
                const value = event.target.value;
                const filteredValue = handleNumberInput(value);
                const valueAsNumber = parseInt(filteredValue, 10);
                handleChangeNumber(valueAsNumber, 0);
              }}
              value={cost ? cost : ""}
              id="cost"
              required={true}
            ></Input>
            <Label>Günlük Oynama Hakkı</Label>
            <Input
              type="number"
              onChange={event => {
                const value = event.target.value;

                if ((Number(value) > 0 && Number(value) < 11) || value == "") {
                  const filteredValue = handleNumberInput(value);
                  const valueAsNumber = parseInt(filteredValue, 10);

                  handleChangeNumber(valueAsNumber, 1);
                }
              }}
              id="playCount"
              value={playCount}
              required={true}
              disabled={gameTypeTechnic}
              maxLength={2}
            ></Input>
            <FormGroup
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <Label style={{ fontWeight: "bold", fontSize: "20px" }}>
                Oyun
              </Label>
              <Label>Oyun İsmi</Label>
              <Input
                type="text"
                onChange={event => {
                  setGameInfo(prevGameInfo => ({
                    ...prevGameInfo,
                    name: event.target.value,
                  }));
                }}
                id="name"
                required={true}
              ></Input>
              <Label>Oyun Görseli</Label>
              <text>Oyun görselini lütfen .png formatında yükleyiniz</text>
              <Input
                type="text"
                onChange={event => {
                  setGameInfo(prevGameInfo => ({
                    ...prevGameInfo,
                    imageUrl: event.target.value,
                  }));
                }}
                id="imageUrl"
                required={true}
              ></Input>
              <Label>Oyun Tipi</Label>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "2px" }}
              >
                <Dropdown isOpen={openGameType} toggle={toggleGameType}>
                  <DropdownToggle caret size="lg">
                    {gameInfo.type
                      ? gameInfo.type === 1
                        ? "Teknik Bilgi"
                        : "Çarkıfelek"
                      : "Oyun Tipi"}
                  </DropdownToggle>
                  <DropdownMenu>
                    {gameType.map((game: any) => (
                      <DropdownItem
                        onClick={() => {
                          handleSelectGameType(game.value);
                          setOpenGameType(false);
                        }}
                        key={game.id}
                      >
                        {game?.value}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
              </div>
              <Label>Oyun Açıklaması</Label>
              <Input
                type="text"
                onChange={event => {
                  setGameInfo(prevGameInfo => ({
                    ...prevGameInfo,
                    description: event.target.value,
                  }));
                }}
                id="description"
              ></Input>
            </FormGroup>
            <FormGroup
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <Label style={{ fontWeight: "bold", fontSize: "20px" }}>
                Birincinin Ödülleri
              </Label>
              <Label>Ödüller</Label>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "2px" }}
              >
                {badges1.length > 0
                  ? badges1.map(badge => (
                      <Badge
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "140px",
                          height: "40px",
                          color: "white",
                          backgroundColor: "darkblue",
                        }}
                        key={badge.id}
                        color="primary"
                        className="mr-2 mb-2"
                      >
                        {"Ödül: "}
                        {badge.type} {badge.amount}
                        <button
                          style={{
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "end",
                            paddingRight: "0px",
                          }}
                          onClick={() => {
                            deleteBadge(1, badge.id);
                          }}
                        >
                          X
                        </button>
                      </Badge>
                    ))
                  : null}
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "2px" }}
              >
                <Dropdown isOpen={openRewardType1} toggle={toggleReward1}>
                  <DropdownToggle caret size="lg">
                    {selected1 ? selected1 : "Ödül Tipi"}
                  </DropdownToggle>
                  <DropdownMenu>
                    {rewardTypes.map((reward: any, i: number) => (
                      <DropdownItem
                        onClick={() => {
                          handleSelectValue(reward.value, 1);
                        }}
                        key={reward.id}
                      >
                        {reward.value}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
                <Input
                  type="text"
                  placeholder="Ödül Miktarı"
                  value={selectedAmount1}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    const filteredValue = handleInputChange(value, 1);
                    handleChangeAmount(filteredValue, 1);
                  }}
                ></Input>
                <button
                  type="button"
                  onClick={() => {
                    handleAddInput(1);
                  }}
                >
                  Ekle
                </button>
              </div>
            </FormGroup>
            <FormGroup
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <Label style={{ fontWeight: "bold", fontSize: "20px" }}>
                İkincinin Ödülleri
              </Label>
              <Label>Ödüller</Label>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "2px" }}
              >
                {badges2.length > 0
                  ? badges2.map(badge => (
                      <Badge
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "140px",
                          height: "40px",
                          color: "white",
                          backgroundColor: "darkblue",
                        }}
                        key={badge.id}
                        color="primary"
                        className="mr-2 mb-2"
                      >
                        {"Ödül: "}
                        {badge.type} {badge.amount}
                        <button
                          style={{
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "end",
                            paddingRight: "0px",
                          }}
                          onClick={() => {
                            deleteBadge(2, badge.id);
                          }}
                        >
                          X
                        </button>
                      </Badge>
                    ))
                  : null}
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "2px" }}
              >
                <Dropdown isOpen={openRewardType2} toggle={toggleReward2}>
                  <DropdownToggle caret size="lg">
                    {selected2 ? selected2 : "Ödül Tipi"}
                  </DropdownToggle>
                  <DropdownMenu>
                    {rewardTypes.map((reward: any, i: number) => (
                      <DropdownItem
                        onClick={() => handleSelectValue(reward.value, 2)}
                        key={reward.id}
                      >
                        {reward.value}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>
                <Input
                  type="text"
                  placeholder="Ödül Miktarı"
                  value={selectedAmount2}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    const filteredValue = handleInputChange(value, 2);
                    handleChangeAmount(filteredValue, 2);
                  }}
                ></Input>
                <button
                  type="button"
                  onClick={() => {
                    handleAddInput(2);
                  }}
                >
                  Ekle
                </button>
              </div>
            </FormGroup>
            <FormGroup
              style={{
                display: "flex",
                flexDirection: "column",
                marginTop: "10px",
              }}
            >
              <Label style={{ fontWeight: "bold", fontSize: "20px" }}>
                Üçüncünün Ödülleri
              </Label>
              <Label>Ödüller</Label>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "2px" }}
              >
                {badges3.length > 0
                  ? badges3.map(badge => (
                      <Badge
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "140px",
                          height: "40px",
                          color: "white",
                          backgroundColor: "darkblue",
                        }}
                        key={badge.id}
                        color="primary"
                        className="mr-2 mb-2"
                      >
                        {"Ödül: "}
                        {badge.type} {badge.amount}
                        <button
                          style={{
                            width: "20px",
                            height: "20px",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "end",
                            paddingRight: "0px",
                          }}
                          onClick={() => {
                            deleteBadge(3, badge.id);
                          }}
                        >
                          X
                        </button>
                      </Badge>
                    ))
                  : null}
              </div>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "2px" }}
              >
                <Dropdown isOpen={openRewardType3} toggle={toggleReward3}>
                  <DropdownToggle caret size="lg">
                    {selected3 ? selected3 : "Ödül Tipi"}
                  </DropdownToggle>

                  <DropdownMenu>
                    {rewardTypes.map((reward: any, i: number) => (
                      <DropdownItem
                        key={reward.id}
                        onClick={() => handleSelectValue(reward.value, 3)}
                      >
                        {reward.value}
                      </DropdownItem>
                    ))}
                  </DropdownMenu>
                </Dropdown>

                <Input
                  type="text"
                  placeholder="Ödül Miktarı"
                  value={selectedAmount3}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const value = e.target.value;
                    const filteredValue = handleInputChange(value, 3);
                    handleChangeAmount(filteredValue, 3);
                  }}
                ></Input>
                <button
                  type="button"
                  onClick={() => {
                    handleAddInput(3);
                  }}
                >
                  Ekle
                </button>
              </div>
            </FormGroup>

            <FormGroup>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "2px",
                  marginTop: "10px",
                }}
              >
                <Label style={{ fontWeight: "bold", fontSize: "20px" }}>
                  Rozet Seçin
                </Label>
                <Dropdown isOpen={badgeOpen} toggle={toggleBadge}>
                  <DropdownToggle caret size="lg">
                    {badgeCode ? badgeCode : "Rozet Seçin"}
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem
                      onClick={() => {
                        setBadgeCode(undefined);
                      }}
                      key="cancel"
                    >
                      İptal Et
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setBadgeCode("event_odeme");
                      }}
                      key="event_odeme"
                    >
                      Ödeme Sistemleri
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setBadgeCode("event_yasal");
                      }}
                      key="event_yasal"
                    >
                      Yasal Uyum
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setBadgeCode("event_ekonomi");
                      }}
                      key="event_ekonomi"
                    >
                      Ekonomi 101
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setBadgeCode("event_anadolu");
                      }}
                      key="event_anadolu"
                    >
                      Ödeme Sistemleri
                    </DropdownItem>
                    <DropdownItem
                      onClick={() => {
                        setBadgeCode("event_urun");
                      }}
                      key="event_urun"
                    >
                      Sürdürülebilirlik
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </FormGroup>
          </FormGroup>

          <br />
          <br />
        </Form>

        <div>
          <AddQuestionForEvent
            handleOnSubmit={handleOnSubmit}
            handleQuestions={(data: any) => {
              setQuestion({
                text: data.question || "",
                categoryType: data.categoryType || "",
                correctAnswer: data.correctAnswer || 0,
                time: data.time || 0,
                answers: [
                  { option: 0, answer: data.answer1 || "" },
                  { option: 1, answer: data.answer2 || "" },
                  { option: 2, answer: data.answer3 || "" },
                  { option: 3, answer: data.answer4 || "" },
                ],
              });
            }}
          />
        </div>
        <div>
          {questionsList?.length > 0
            ? questionsList?.map((question, index) => (
                <Badge
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "140px",
                    height: "40px",
                    color: "white",
                    backgroundColor: "green",
                  }}
                  color="primary"
                  className="mr-2 mb-2"
                  key={index}
                >
                  {"Soru: "} {question.categoryType}
                </Badge>
              ))
            : null}
        </div>
        <Button
          style={{ backgroundColor: "darkblue" }}
          onClick={async () => {
            await addEvent();
          }}
          disabled={isDisabled}
        >
          Etkinlik Ekle
        </Button>
      </div>
    </>
  );
};

export default AddEvent;
