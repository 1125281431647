import React, { Component } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Table, ListGroup, ListGroupItem, Col } from "reactstrap";
import { logoutUser } from 'src/slices/thunks';
import { threadId } from 'worker_threads';
import Config, { Constants } from "../../Constants";


export default class MiniGameInfo extends Component<any, any>  {
    constructor(props: any) {
        super(props);
        this.state = {
            value: [],
            mostAnsweredQuestions: []
        };

    }

    async getKnowladgeAnsweredQuestionsValue() {
        try {
            var response = await fetch(Constants.url + "analytics/knowladgeAnsweredQuestions", {
                method: 'Get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();
            this.setState({ value: result })
            return result;

        } catch (error) {
            console.log("error: ", error);
        }
    }


    componentDidMount() {
        this.getKnowladgeAnsweredQuestionsValue();
    }
    render() {
        return (
            <div>
                <Table style={{ direction: "ltr" }} borderless>
                    <thead>
                        <tr>
                            <th>
                                <b><u>Mini Oyunlar :</u></b>
                            </th>
                        </tr>
                    </thead>
                    <tbody style={{ textAlign: "left" }}>
                        <tr >
                            <th>
                                Oyundaki teknik bilgi testlerindeki cevaplanan soru sayısı : {this.state.value}
                            </th>
                        </tr>
                        <tr style={{ margin: "25px 15px 15px 25px" }} >
                            <th>
                                <Link to="/mostPlayedMiniGameUsers">
                                    Oyundaki her bir mini oyunu en çok oynayan kullanıcılar listesi
                                </Link>
                            </th>
                        </tr>
                        <tr style={{ margin: "25px 15px 15px 25px" }} >
                            <th>
                                <Link to="/mostPlayedMultiplayerGameUsers">
                                    Oyundaki her bir multiplayer oyunu en çok oynayan kullanıcılar listesi
                                </Link>
                            </th>
                        </tr>
                        <tr >
                            <th>
                                <Link to="/userMostAnsweredQuestionUsers">
                                    En çok soru cevaplayan kullanıcılar listesi
                                </Link>
                            </th>
                        </tr>
                        <tr >
                            <th>
                                <Link to="/userAnswersAccuracyRate">
                                    En yüksek doğruluk oranı ile soru cevaplayan kullanıcılar listesi
                                </Link>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <Link to="/userAllAnswersByCategory">
                                    Tüm ve kategori bazında cevaplanan <b>tüm sorular</b>  listesi
                                </Link>
                            </th>
                        </tr>
                        <tr>
                            <th>
                                <Link to="/usersEmployeeList">
                                    Kullanıcı Bazında İşe Alımlar ve Cinsiyet Dağılımları
                                </Link>
                            </th>
                        </tr>
                    </tbody>
                </Table>
            </div>
        )
    }
}
