import React, { Component } from 'react';
import { Table, ListGroup, ListGroupItem, Col } from "reactstrap";
import i18n from '../../i18n';


export default class BuildingsTable extends Component<any, any> {  
    constructor(props: any ){
        super(props);
        this.state = {
           buildingsInfo : []
        }
    } 

    render() { 
        const buildingsInfo = this.props.userBuildingsInfoDataFromParent.data;    

        return (
            <div>    
                <h5 style={{ textAlign: "left", margin:"0px 0px 10px 35px"}}>Sahip Olduğu Binalar :</h5> 

               <Table bordered style={{ direction: "ltr"}}>
                    <thead>
                        <tr>
                            <th style={{ textAlign: "center"}}>
                                Bina Adı
                            </th>
                            
                            <th style={{ textAlign: "center"}}>
                                Bina Seviyesi
                            </th>
                        </tr>
                    </thead>
                    <tbody> 
                        { buildingsInfo != null ? (buildingsInfo.adminPanelUserBuildingDTOs.map(
                            (buildings : any, index : number) => (
                                <tr key={index}>
                                    <td style={{ textAlign: "center"}}>
                                        { i18n.t(buildings.type) }
                                    </td>
                                    <td style={{ textAlign: "center"}}>
                                        {buildings.level}
                                    </td>
                                </tr>
                            )
                        ) ): (<tr>
                            <td style={{ textAlign: "center"}}>
                            
                            </td>
                            <td style={{ textAlign: "center"}}>
                            
                            </td>
                        </tr>)} 
                    </tbody>
                </Table>
            </div>
        )
    }
}
