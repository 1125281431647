import { Component } from "react";
import { Constants } from "../../Constants";
import Store from "src/store";
import { useDispatch } from "react-redux";
import { logoutUser } from "src/slices/thunks";
import React from "react";
import MetaTags from "react-meta-tags";
import { Col, Container, Row, Table } from "reactstrap";
import loading from "../../assets/images/loading.gif";
import { Link } from "react-router-dom";

interface EventUserScoresListProps {
  EventId?: string;
}

export default class EventUserScoresList extends Component<any, any> {
  constructor(props: EventUserScoresListProps) {
    super(props);
    this.state = {
      result: [],
      eventUserScoresList: Store.getData("getEventUserScoresList"),
      isLoading: true,
    };

    this.getEventUserScoresList = this.getEventUserScoresList.bind(this);
  }

  async getEventUserScoresList() {
    try {
      var body = {
        eventId: this.props.match.params.eventid,
      };

      var response = await fetch(
        Constants.url + "analytics/getEventUserScoresList",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
          body: JSON.stringify(body),
        }
      );
      if (response.status == 401 || response.status == 403) {
        const dispatch = useDispatch();
        dispatch(logoutUser(history));
        return;
      }
      var result = await response.json();
      this.setState({ result: result });

      this.setState({ isLoading: false });

      return result;
    } catch (error) {
      console.log("error: ", error);
    }
  }

  componentDidMount() {
    this.getEventUserScoresList().then(result => {
      this.setState({ result: result });
    });
  }

  componentWillUnmount() {
    this.getEventUserScoresList().then(result => {
      this.setState({ result: result });
    });
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ minHeight: "100vh" }}>
          <div className="page-content">
            <MetaTags>
              <title> Etkinliğe Katılan Kullanıcıların Puan Listesi </title>
            </MetaTags>
          </div>
          <br />
          <div>
            <Row
              style={{
                textAlign: "left",
                direction: "ltr",
                alignItems: "center",
              }}
            >
              <Col xs={1}></Col>
              <Col md={8}>
                <h5>
                  <b>
                    <u> Etkinliğe Katılan Kullanıcıların Puan Listesi</u>
                  </b>
                </h5>
              </Col>
            </Row>
            <Row
              style={{
                textAlign: "left",
                direction: "ltr",
                marginLeft: "75px",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            >
              <Col>
                <Link to="/analyticEvents" className="btn btn-primary">
                  Geri
                </Link>
              </Col>
            </Row>
          </div>
          <div>
            <Container
              style={{ direction: "ltr", flex: 1, alignContent: "center" }}
            >
              {this.state.isLoading && (
                <div>
                  <Col
                    style={{
                      textAlign: "center",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={loading}
                      alt="loading"
                      style={{ width: "150px" }}
                    />
                    <h4>Bilgiler Yükleniyor...</h4>
                  </Col>
                </div>
              )}
            </Container>
            <Container
              style={{
                textAlign: "right",
                direction: "ltr",
                width: "800px",
                alignItems: "center",
                flex: 1,
              }}
            >
              {!this.state.isLoading && (
                <Table bordered style={{ direction: "ltr" }}>
                  <thead>
                    <tr>
                      <th style={{ textAlign: "center" }}>Kullanıcı Adı</th>
                      <th style={{ textAlign: "center" }}>Puan</th>
                    </tr>
                  </thead>
                  <tbody>
                    {/*TODO*/}
                    {this.state.result != null ? (
                      this.state.result.map((users: any, index: number) => (
                        <tr key={index}>
                          <td style={{ textAlign: "center" }}>
                            {users.userName}
                          </td>
                          <td style={{ textAlign: "center" }}>{users.value}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td style={{ textAlign: "center" }}></td>
                        <td style={{ textAlign: "center" }}></td>
                        <td style={{ textAlign: "center" }}></td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
              <br />
            </Container>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
