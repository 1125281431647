import React, { Component, useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import PersonalInfo from "src/pages/UserInfo/personalInfo";
import BuildingsTable  from "src/pages/UserInfo/buildingsTable";
import EmployeesTable from "src/pages/UserInfo/employeesTable";
import UserCheck from "src/pages/UserInfo/userCheck";
import UserHourlyLogin from "src/pages/UserInfo/userHourlyLogin";
import Store from "src/store";
import i18n from '../../i18n';

import { Col, Container, Row } from 'reactstrap';
import {Link} from 'react-router-dom';
import exportFromJSON from 'export-from-json';
import FriendsTable from "./friendsTable";


export default class UserInfo extends Component<any,any> {      
    constructor(props:any) {
        super(props);
        this.state = {
        userInfoData : Store.getData("userInfoData")
        };

        this.callbackFunction = this.callbackFunction.bind(this);

    }

    ExportToExcelEmployees = () => {  
        const data = this.state.userInfoData.data.adminPanelUserEmployeeDTOs.map((el : any, index : number) => {
            return { "Çalışanın Adı" : el.name, "Çalışanın Deneyim Yılı" : el.experience, "Teknik Bilgi Yetkinlik Puanı" : el.knowledge, "Sürekli Öğrenme Yetkinlik Puanı" : el.flexibility, "Problem Çözme Yetkinlik Puanı" : el.problemSolving, "İnsan İlişkileri Yönetimi Yetkinlik Puanı" : el.communication, "İnovasyon Yetkinlik Puanı" : el.innovation, "Müşteri Odaklılık Yetkinlik Puanı" : el.customerFocus, "Değerlere Uygunluk Yetkinlik Puanı" : el.conformityToValues};
       });  
        const fileName = 'Kullanıcının Sahip Olduğu Çalışanlar' ;
        const exportType = 'xls' ;
        exportFromJSON({ data, fileName, exportType })  
    } 

    ExportToExcelBuildings = () => {          
        const data = this.state.userInfoData.data.adminPanelUserBuildingDTOs.map((el : any, index : number) => {
             return { "Bina Adı" : i18n.t(el.type), "Bina Seviyesi" : el.level };
        }); 
        const fileName = 'Kullanıcının Sahip Olduğu Binalar' ;
        const exportType = 'xls' ;
        exportFromJSON({ data, fileName, exportType })  
    }

    componentWillUnmount() {
        if (this.state.userInfoData !== null) {
            this.setState({ userInfoData : Store.getData("userInfoData")})
        }
    }

    async callbackFunction (childData : any) {
         if(childData != null) {
        await this.setState({userInfoData: childData});
        Store.setData("userInfoData", childData);  

         }
    }


    render() {

        return (
            <React.Fragment>
                <div className="page-content" >
                    <MetaTags>
                       <title>Kullanıcı Sorgulama</title>
                    </MetaTags>
                    <br />
                    <Row style={{ direction: "ltr", alignItems:'center', flex: 1}} >
                        <Col md={1}></Col>
                        <Col>
                            <h4 style={{ textAlign: "left" }}>Kullanıcı Sorgulama</h4>
                        </Col>
                    </Row>
                    <br />  
                    <Row style={{ direction: "ltr", alignItems:'center', flex: 1}}> 
                        <Col xs={1}></Col>
                        <Col>
                            <UserCheck parentCallback = {this.callbackFunction} history={this.props.history}/>
                        </Col>                       
                    </Row>                    
                <hr />                     
                </div>
                <div>
                    { this.state.userInfoData != null &&
                    <Container style={{ textAlign: "right", direction: "ltr", alignItems:'center', flex: 1}}>
                        <Row  style={{ textAlign: "left"}}>
                            <h5 style={{margin:"15px"}}><b><u> Kullanıcı Bilgileri </u></b></h5>
                        </Row>
                        <Row>
                            <Row> 
                                <Col  style={{ textAlign: "right", direction: "ltr"}} xs={5}>
                                    <PersonalInfo userInfoDataFromParent= {this.state.userInfoData}/>
                                </Col>
                                <Col></Col>
                                <Col  style={{ textAlign: "left", direction: "ltr"}} xs={5}>
                                    <Row>
                                        <Link to={{ pathname : '/userhourlyLogin'}}>
                                            <b>
                                                Kullanıcının Saatlik Giriş Bilgileri İçin Tıklayınız
                                            </b>
                                        </Link>
                                   </Row>
                                   <br />
                                   <Row>
                                       <Link to={{ pathname : '/userMostCorrectAnsweredQuestion'}}> 
                                           <b>
                                               Kullanıcının Doğru Yanıtladığı Sorular İçin Tıklayınız
                                           </b>
                                       </Link>
                                   </Row>
                                   <br />
                                   <Row>
                                       <Link to={{ pathname : '/userMostIncorrectAnsweredQuestion'}}>
                                           <b>
                                               Kullanıcının Yanlış Yanıtladığı Sorular İçin Tıklayınız
                                           </b>
                                       </Link>
                                   </Row>
                                   <br />
                                   <Row>
                                        <Col>
                                            <button type="button" className="btn btn-danger" onClick={this.ExportToExcelBuildings}>Sahip Olunan Binaları İndir</button>
                                        </Col>
                                        <Col>
                                            <button type="button" className="btn btn-danger" onClick={this.ExportToExcelEmployees}>Sahip Olunan Çalışanlar İndir</button>
                                        </Col>
                                   </Row>
                                </Col> 
                            </Row>
                            <br />   
                            <Row></Row>                   
                            <Row style={{ textAlign: "center", direction: "ltr"}}>
                                <Col md={4}>
                                   <BuildingsTable userBuildingsInfoDataFromParent= {this.state.userInfoData}/> 
                                </Col>
                                <Col style={{ textAlign: "left", marginLeft: "50px"}} md={4}>
                                    <EmployeesTable userEmployeesInfoDataFromParent= {this.state.userInfoData}/>
                                </Col>
                            </Row>
                            <Row style={{ textAlign: "center", direction: "ltr"}}>
                                <Col style={{ textAlign: "left", marginLeft: "50px"}}>
                                    <FriendsTable userFriendsInfoDataFromParent= {this.state.userInfoData}/>
                                </Col>
                            </Row>
                        </Row>
                    </Container>
                    }
                </div>
                <br />
                <br />
            </React.Fragment>
        );
    }
}
