import { Redirect } from "react-router-dom";

import UserInfo from "../pages/UserInfo/index";
import UserStatistic from "../pages/UserStatistics/index";
import AddQuestions from "../pages/AddQuestions/index";
import QuestionOperations from "../pages/QuestionOperations/index";
import UserHourlyLogin from "../pages/UserInfo/userHourlyLogin";
import MostCorrectAnsweredQuestion from "../pages/UserInfo/mostCorrectAnsweredQuestion";
import MostIncorrectAnsweredQuestion from "../pages/UserInfo/mostIncorrectAnsweredQuestion";
import AllHourlyLogin from "../pages/UserStatistics/allHourlyLogin";
import UniqueHourlyLogin from "../pages/UserStatistics/uniqueHourlyLogin";
import UsersXpList from "../pages/UserStatistics/usersXpList";
import UserPrestigePoint from "../pages/UserStatistics/usersPrestigeList";
import UsersLevelList from "../pages/UserStatistics/usersLevelList";
import UsersCampusValueList from "../pages/UserStatistics/usersCampusValueList";
import mostAnsweredQuestionUsers from "../pages/UserStatistics/mostAnsweredQuestionUsers";
import answersAccuracyRate from "../pages/UserStatistics/answersAccuracyRate";
import incorrectlyAnswersByCategory from "../pages/UserStatistics/incorrectlyAnswersByCategory";
import mostPlayedMiniGameUsers from "../pages/UserStatistics/mostPlayedMiniGameUsers";
import mostPlayedMultiplayerGameUsers from "../pages/UserStatistics/mostPlayedMultiplayerGameUsers";
import editQuestion from "../pages/QuestionOperations/editQuestion";
import userIncompleteTutorialList from "../pages/UserStatistics/usersIncompleteTutorialList";
import usersEmployeeData from "src/pages/UserStatistics/usersEmployeeData";
import usersPlatforms from "src/pages/UserStatistics/usersPlatforms";

//Authentication pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import allAnswersByCategory from "src/pages/UserStatistics/allAnswersByCategory";
import correctlyAnswersByCategory from "src/pages/UserStatistics/correctlyAnswersByCategory";
import todayNewUsersList from "src/pages/UserStatistics/todayNewUsersList";
import allLoginDaysCount from "src/pages/UserStatistics/allLoginDaysCount";
import UserControl from "src/pages/UserControl/index";
import ColelctableResources from "src/pages/CollectableResouces/CollectableResources";
import CollectableResourcesAdd from "src/pages/CollectableResouces/CollectableResourcesAdd";
import CollectableResourcesEdit from "src/pages/CollectableResouces/CollectableResourcesEdit";
import Events from "src/pages/Events";
import AddEvent from "src/pages/Events/addEvent";
import EditEvent from "src/pages/Events/editEvent";
import EventAnsweredQuestionCount from "src/pages/Events/eventAnsweredQuestionCount";
import UserAnsweredQuestionCount from "src/pages/Events/userAnsweredQuestionCount";
import EventUserScoresList from "src/pages/Events/eventUserScoresList";
import JoinedEventUserList from "src/pages/Events/joinedEventUserList";

interface RouteProps {
  path: string;
  component: any;
  exact?: boolean;
}

const userRoutes: Array<RouteProps> = [
  //dashboard
  { path: "/userInfo", component: UserInfo },

  { path: "/userHourlyLogin", component: UserHourlyLogin },
  {
    path: "/userMostAnsweredQuestionUsers",
    component: mostAnsweredQuestionUsers,
  },
  { path: "/userAnswersAccuracyRate", component: answersAccuracyRate },
  {
    path: "/userMostCorrectAnsweredQuestion",
    component: MostCorrectAnsweredQuestion,
  },
  {
    path: "/userMostIncorrectAnsweredQuestion",
    component: MostIncorrectAnsweredQuestion,
  },
  { path: "/analyticEvents", component: UserStatistic },
  { path: "/addQuestion", component: AddQuestions },
  { path: "/questionOperation", component: QuestionOperations },
  { path: "/userControl", component: UserControl },

  { path: "/allhourlyLogin", component: AllHourlyLogin },
  { path: "/uniqueHourlyLogin", component: UniqueHourlyLogin },
  { path: "/userXpList", component: UsersXpList },
  { path: "/userPrestigePointList", component: UserPrestigePoint },
  { path: "/usersLevelList", component: UsersLevelList },
  { path: "/userCampusValueList", component: UsersCampusValueList },
  {
    path: "/userCorrectlyAnswersByCategory",
    component: correctlyAnswersByCategory,
  },
  { path: "/todayNewUsersList", component: todayNewUsersList },
  { path: "/usersLoginDaysList", component: allLoginDaysCount },
  {
    path: "/userIncorrectlyAnswersByCategory",
    component: incorrectlyAnswersByCategory,
  },
  { path: "/userAllAnswersByCategory", component: allAnswersByCategory },
  { path: "/mostPlayedMiniGameUsers", component: mostPlayedMiniGameUsers },
  {
    path: "/mostPlayedMultiplayerGameUsers",
    component: mostPlayedMultiplayerGameUsers,
  },
  { path: "/editQuestion", component: editQuestion },
  { path: "/incompleteTutorial", component: userIncompleteTutorialList },
  { path: "/usersEmployeeList", component: usersEmployeeData },
  { path: "/usersPlatforms", component: usersPlatforms },
  { path: "/eventList", component: Events },
  { path: "/addEvent", component: AddEvent },
  { path: "/editEvent", component: EditEvent },

  {
    path: "/getJoinedEventUserList/:eventid",
    component: JoinedEventUserList,
  },
  { path: "/getEventUserScoresList/:eventid", component: EventUserScoresList },
  {
    path: "/getUserAnsweredQuestionCount/:eventid",
    component: UserAnsweredQuestionCount,
  },
  {
    path: "/getEventAnsweredQuestionCount/:eventid",
    component: EventAnsweredQuestionCount,
  },

  { path: "/collectable/resources", component: ColelctableResources },
  { path: "/collectable/resources/add", component: CollectableResourcesAdd },
  { path: "/collectable/resources/edit", component: CollectableResourcesEdit },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  { path: "/", exact: true, component: () => <Redirect to="/userInfo" /> },
];

const authRoutes: Array<RouteProps> = [
  //Authentication pages
  { path: "/login", component: Login },
  { path: "/logout", component: Logout },
  { path: "/register", component: Register },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/hc", component: Login },
];

export { userRoutes, authRoutes };
