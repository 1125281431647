import React from "react";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

//import components
import SidebarContent from "./SidebarContent";

//import images
import islegendfavicon from "../../assets/images/islegendfavicon.png";

const Sidebar = (props: any) => {

  function tToggle() {
    document.body.setAttribute("data-sidebar-size", "sm");
  }

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box"  style={{alignItems:'center'}}>
          <Row>
            <Col></Col>
            <Col md={2}>
              <Link to="/userInfo" className="logo logo-dark">
                <span>
                  <img src={islegendfavicon} alt="" height="50"  style={{alignItems:'center'}} />
                </span>
              </Link>
            </Col>
            <Col></Col>
            <Col>
              <Link to="/userInfo" className="logo logo-light">
                <span> 
                  <img src={islegendfavicon} alt="" height="50" style={{ alignItems:'center'}} />
                </span>
              </Link>
            </Col>
          </Row>
        </div>

        <button
          onClick={() => {
            tToggle();
          }}
          type="button" className="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn" id="vertical-menu-btn">
          <i className="fa fa-fw fa-bars" />
        </button>

        <div className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;