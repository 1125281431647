//import DateTimeRangePicker from "@wojtekmaj/react-datetimerange-picker"
import moment from "moment"
import { Dispatch, SetStateAction, useEffect, useState } from "react"
import { Col, Row, Input, FormGroup, Label, Table, Button, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Alert } from "reactstrap"
import AdminCollectableResourceDTO, { Currency } from "src/pages/CollectableResouces/Data/AdminCollectableResourceDTO"
import AdminCollectableResourceUserDTO from "src/pages/CollectableResouces/Data/AdminCollectableResourceUserDTO"

interface Props {
    collectableResource: AdminCollectableResourceDTO | undefined;
    setCollectableResource: Dispatch<SetStateAction<AdminCollectableResourceDTO | undefined>>;
}

const CollectableResourceEdit = (props: Props) => {
    const setCollectableResource = props.setCollectableResource;
    const collectableResource = props.collectableResource;

    const [currencyDropDownOpen, setCurrencyDropDownOpen] = useState(false);
    const toggleCurrencyDropDownOpen = () => setCurrencyDropDownOpen((prevState) => !prevState);

    const [newUserValue, setNewUserValue] = useState("");

    function onDescriptionChanged(newDescription: string) {
        setCollectableResource(prev => {
            if (prev != null) {
                return({
                    ...prev,
                    description: newDescription
                  });
            }

            return prev;
        });
    }

    function setActive(active: boolean) {
        setCollectableResource(prev => {
            if (prev != null) {
                return({
                    ...prev,
                    isActive: active
                  });
            }

            return prev;
        });
    }

    function setHasDate(checked: boolean) {
        setCollectableResource(prev => {
            if (prev != null) {
                var start = prev.startDate;
                var end = prev.endDate;
                if (checked) {
                    if (start == null) {
                        start = moment.utc().format('yyyy-MM-DD HH:mm:ss')
                    }

                    if (end == null) {
                        end = moment.utc().format('yyyy-MM-DD HH:mm:ss')
                    }
                }
                else {
                    start = undefined;
                    end = undefined;
                }

                return({
                    ...prev,
                    startDate: start,
                    endDate: end
                  });
            }

            return prev;
        });
    }

    function onDateChange(dates: (Date | undefined) | (Date | undefined)[] | null) {

        setCollectableResource(prev => {
            if (prev != null) {
                var start: string | undefined = undefined;
                var end: string | undefined = undefined;

                if (dates != null ) {
                    if (dates instanceof Date) {
                        start = moment(dates).utc().format('yyyy-MM-DD HH:mm:ss')
                    }
                    else {
                        if (dates.length > 0 && dates[0] != null) {
                            start = moment(dates[0]).utc().format('yyyy-MM-DD HH:mm:ss')
                        }

                        if (dates.length > 1 && dates[1] != null) {
                            end = moment(dates[1]).utc().format('yyyy-MM-DD HH:mm:ss')
                        }
                    }
                }

                return({
                    ...prev,
                    startDate: start,
                    endDate: end
                  });
            }

            return prev;
        });
    }

    function setCanCollectMultiple(checked: boolean) {
        setCollectableResource(prev => {
            if (prev != null) {
                var collectPeriod = prev.collectPeriod;

                if (checked) {
                    collectPeriod = 24*60*60;
                }
                else {
                    collectPeriod = undefined;
                }

                return({
                    ...prev,
                    collectPeriod: collectPeriod,
                    canCollectMultiple: checked
                  });
            }

            return prev;
        });
    }

    function setCollectPeriod(period: number) {
        setCollectableResource(prev => {
            if (prev != null) {
                return({
                    ...prev,
                    collectPeriod: period
                  });
            }

            return prev;
        });
    }

    function setAllUsers(checked: boolean) {
        setCollectableResource(prev => {
            if (prev != null) {
                return({
                    ...prev,
                    allUsers: checked
                  });
            }

            return prev;
        });
    }

    function toggleUser(id: string | undefined) {
        setCollectableResource(prev => {
            if (prev != null) {

                const users = Array.from(prev.users);
                let index = users.findIndex(u => u.id == id);

                if (index >= 0) {
                    users[index].isActive = !users[index].isActive;
                }

                return({
                    ...prev,
                    users: users
                  });
            }

            return prev;
        });
    }



    function setAmount(amount: number) {
        setCollectableResource(prev => {
            if (prev != null) {
                return({
                    ...prev,
                    amount: amount
                  });
            }

            return prev;
        });
    }

    function onCurrencyChanged(currency: Currency) {
        setCollectableResource(prev => {
            if (prev != null) {
                return({
                    ...prev,
                    amountType: currency
                  });
            }

            return prev;
        });
    }


    function addNewUser() {
      

        setCollectableResource(prev => {
            if (prev != null) {

                const users = Array.from(prev.users);
                var newUsers = newUserValue.split(/\r?\n/);

                for(var i = 0; i < newUsers.length; i++) {
                    var userName = newUsers[i];

                    let index = users.findIndex(u => u.userName == userName);

                    if (index < 0) {
                        users.push({
                            id: undefined,
                            createdDate: undefined,
                            modifiedDate: undefined,
                            isActive: true,
                            userName: userName
                        });
                    }
                }
                
                return({
                    ...prev,
                    users: users
                  });
            }

            return prev;
        });

        setNewUserValue("");

    }

    return (
        <Col style={{direction: 'ltr', paddingLeft: 30, paddingRight: 30}}>
            <Row>
                <Input type="textarea" maxLength={400} value={collectableResource?.description != null ? collectableResource.description : ""} onChange={evt => onDescriptionChanged(evt.target.value)}/>
            </Row>
            <Row style={{marginTop: 10}}>
                <Col sm={6}>
                    <Row>
                        <FormGroup check style={{width: 75}}>
                            <Label check>Aktif</Label>
                            <Input type="checkbox" checked={collectableResource?.isActive} onChange={evt => setActive(evt.target.checked)}/>
                        </FormGroup>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <Label check>Kaynak</Label>
                    </Row>
                    <Row>
                        <Col sm={6}>
                            <Input type="number" value={collectableResource?.amount} onChange={evt => setAmount(evt.target.valueAsNumber)}/>
                        </Col>
                        <Col sm={3}>
                            <Dropdown isOpen={currencyDropDownOpen} toggle={toggleCurrencyDropDownOpen} direction={'down'}>
                                <DropdownToggle caret>{collectableResource?.amountType}</DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => onCurrencyChanged(Currency.Money)}>Money</DropdownItem>
                                    <DropdownItem onClick={() => onCurrencyChanged(Currency.Diamond)}>Diamond</DropdownItem>
                                    <DropdownItem onClick={() => onCurrencyChanged(Currency.Energy)}>Energy</DropdownItem>
                                    <DropdownItem onClick={() => onCurrencyChanged(Currency.Ticket)}>Ticket</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </Col>
                    </Row>
                    <Row style={{marginTop: 20}}>
                        <FormGroup check style={{width: 80}}>
                            <Label check>Süreli</Label>
                            <Input type="checkbox" checked={collectableResource?.startDate != null || collectableResource?.endDate != null} onChange={evt => setHasDate(evt.target.checked)}/>
                        </FormGroup>
                    </Row>
                    { (collectableResource?.startDate != null || collectableResource?.endDate != null) &&
                    <Row>
                        <Col style={{width: 445}}>
                           
                        </Col>
                    </Row>
                    }
                    <Row style={{marginTop: 20}}>
                        <FormGroup check style={{width: 200}}>
                            <Label check>Birden Fazla Alınabilir</Label>
                            <Input type="checkbox" checked={collectableResource?.canCollectMultiple} onChange={evt => setCanCollectMultiple(evt.target.checked)}/>
                        </FormGroup>
                    </Row>
                    { collectableResource?.canCollectMultiple &&
                    <Row>
                        <FormGroup check style={{width: 400, flexDirection: 'row', display: 'flex'}}>
                            <Col sm={1} style={{ width: 130, display:'flex', textAlign: 'center', alignItems:'center'}}>
                                <Label check>Periyod (Saniye)</Label>
                            </Col>
                            <Col>
                                <Input type="number" value={collectableResource?.collectPeriod} onChange={evt => setCollectPeriod(evt.target.valueAsNumber)}/>
                            </Col>
                        </FormGroup>
                    </Row>
                    }
                    <Row>

                    </Row>
                    <Row style={{height: 200}}></Row>
                </Col>
                <Col sm={6}>
                    <Row style={{justifyContent: 'flex-end'}}>
                        <FormGroup check style={{width: 160}}>
                            <Label check>Tüm Kullanıcılar</Label>
                            <Input type="checkbox" checked={collectableResource?.allUsers} onChange={evt => setAllUsers(evt.target.checked)}/>
                        </FormGroup>
                    </Row>
                    { !collectableResource?.allUsers &&
                        <>
                            <Row style={{marginTop: 20}}>
                                <Row>
                                    <Label style={{padding: 0}}>Kullanıcı Ekle</Label>
                                </Row>
                                <Row>
                                    <FormGroup style={{width: '100%', flexDirection: 'row', display: 'flex', padding: 0}}>
                                        <Input style={{marginRight: 10}} type="textarea" value={newUserValue} placeholder={'UserName'} onChange={evt => setNewUserValue(evt.target.value)}/>
                                        <Button onClick={addNewUser}>Ekle</Button>
                                    </FormGroup>
                                </Row>
             
                            </Row>
                            <Row style={{marginTop: 20}}>
                                <Table bordered style={{ direction: "ltr", width: "100%" }}>
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: "center", width: '80%'}}>
                                                User Name
                                            </th>
                                            <th style={{ textAlign: "center", width: '20%'}}>
                                                Çıkar
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { collectableResource?.users != null && collectableResource.users.length > 0 && 
                                            collectableResource.users.map(
                                                (user: AdminCollectableResourceUserDTO, index: number) => (
                                                    <tr key={index}>
                                                        <td style={{ textAlign: "center" }}>{user.userName}</td>
                                                        <td style={{ textAlign: "center" }}>
                                                            <Button onClick={() => toggleUser(user.id)}>{user.isActive ? "Çıkar" : "Geri Ekle"}</Button>
                                                        </td>
                                                    </tr>
                                                )
                                            )
                                        }
                                    </tbody>
                                </Table>
                            </Row>
                        </>
                    }

                </Col>

            </Row>
        </Col>
    )
}

export default CollectableResourceEdit