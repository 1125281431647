import React, { Component, useState } from "react";
import MetaTags from "react-meta-tags";
import GeneralInfo from "src/pages/UserStatistics/generalInfo";
import MiniGameInfo from "src/pages/UserStatistics/miniGameInfo";
import IsActiveChart from "src/pages/UserStatistics/isActiveChart";
import OtherStatisticList from "src/pages/UserStatistics/otherStatisticList";
import { Constants } from "../../Constants";
import loading from "../../assets/images/loading.gif";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { Button, Col, Container, Row } from "reactstrap";
import ExportAllData from "../ExportAllData/exportAllData";
import { result } from "lodash";

import EventStatistic2 from "src/pages/UserStatistics/eventStatistic2";

export default class UserStatistic extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      allUserCount: "",
      activeUserCount: "",
      passiveUserCount: "",
      todaysUserCount: "",
      startDate: new Date(),
      endDate: new Date(),
      isDownload: false,
      disabled: false,
    };

    this.getUserCount = this.getUserCount.bind(this);
  }

  componentDidMount() {
    this.setState({ startDate: Date.now() - 6.048e8 }); // a week before from today
    this.setState({ endDate: Date.now() });

    this.getUserCount("allUserCount");
    this.getUserCount("activeUserCount");
    this.getUserCount("passiveUserCount");
    this.getUserCount("todaysUserCount");
  }

  async getUserCount(value: string) {
    await fetch(Constants.url + "admin/" + value, {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    })
      .then(response => response.json())
      .then(count => {
        var obj: any = {};
        obj[value] = count;
        this.setState(obj);
      })
      .catch(error => console.log("fetchToken error: ", error));
  }

  warningLoading(value: boolean) {
    if (value == true) {
      alert("İndirilmeye başlansın mı?");
      this.setState({ isDownload: true });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="page-content">
          <MetaTags>
            <title>Kullanıcı İstatistikleri</title>
          </MetaTags>
          <br />
          <Row style={{ direction: "ltr", alignItems: "center", flex: 1 }}>
            <Col md={1}></Col>
            <Col md={6}>
              <h4 style={{ textAlign: "left" }}>Kullanıcı İstatistikleri</h4>
            </Col>
            <Col xs={2}>
              <DatePicker
                selected={this.state.startDate}
                onChange={(date: any) => this.setState({ startDate: date })}
                dateFormat="dd/MM/yyyy"
              />
            </Col>
            <Col xs={2}>
              <DatePicker
                selected={this.state.endDate}
                onChange={(date: any) => this.setState({ endDate: date })}
                dateFormat="dd/MM/yyyy"
              />
            </Col>
          </Row>
        </div>
        <div>
          <Row style={{ direction: "ltr", alignItems: "center", flex: 1 }}>
            <Col md={1}></Col>
            <Col md={4}>
              <GeneralInfo
                allUserCount={this.state.allUserCount}
                activeUserCount={this.state.activeUserCount}
                todayUserCount={this.state.todaysUserCount}
              />
            </Col>
            <Col md={1}></Col>
            <Col md={6}>
              <IsActiveChart
                activeUserCount={this.state.activeUserCount}
                allUserCount={this.state.allUserCount}
              />
            </Col>
          </Row>
          <br />
          <Row style={{ direction: "ltr", alignItems: "center", flex: 1 }}>
            <Col md={9}></Col>
            <Col md={3}>
              <Button
                style={{ backgroundColor: "darkblue" }}
                onClick={() => {
                  this.warningLoading(true);
                  this.setState({ disabled: true });
                  ExportAllData(this.state.startDate, this.state.endDate);
                }}
                disabled={this.state.disabled}
              >
                Tüm İstatistik Verilerini İndir
                {this.state.isDownload && (
                  <img src={loading} alt="loading" style={{ width: "30px" }} />
                )}
              </Button>
            </Col>
          </Row>
          <br />
        </div>
        <div>
          <Container
            style={{
              textAlign: "right",
              direction: "ltr",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Row style={{ textAlign: "left" }}>
              <h5>
                <b>
                  <u> Diğer Analitik Veriler </u>
                </b>
              </h5>
            </Row>
            <Row>
              <Col style={{ textAlign: "left", direction: "ltr" }} md={6}>
                <OtherStatisticList
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                />
              </Col>
              <Col style={{ textAlign: "left", direction: "ltr" }} md={6}>
                <MiniGameInfo />
              </Col>
            </Row>
            <Row>
              <Col style={{ textAlign: "left", direction: "ltr" }} md={6}>
                <EventStatistic2 />
              </Col>
            </Row>
          </Container>
        </div>
        <br />
        <br />
      </React.Fragment>
    );
  }
}
