import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams, useLocation } from "react-router-dom";
import {
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { logoutUser } from "src/slices/thunks";
import { Constants } from "../../Constants";
import JoinedEventUserList from "../Events/joinedEventUserList";
import { generatePath } from "react-router";

export default function EventStatistic2() {
  const [eventList, setEventList] = useState<any>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);
  const [selectedEventId, setSelectedEventId] = useState<string>();
  const [eventName, setEventName] = useState<string>();

  const [eventT, setEvent] = useState(false);
  const toggleEvent = () => setEvent(prevState => !prevState);

  let { event_id } = useParams();

  const handleEventSelection = (value: any, event: any) => {
    setSelectedEventId(value);
    setEventName(event);
    setEvent(false);
  };

  const getEvents = async () => {
    var response = await fetch(Constants.url + "admin/event?page=1&size=10", {
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("token"),
      },
    });
    if (response.status == 401 || response.status == 403) {
      const dispatch = useDispatch();
      dispatch(logoutUser(history));
      return;
    } else {
      const res = await response.json();

      if (res.success) {
        setIsSuccess(res.success);
        setEventList(res.data);
      }
    }
  };

  useEffect(() => {
    getEvents();
  }, []);

  return (
    <div style={{ marginBottom: "100px" }}>
      <Table style={{ direction: "ltr" }} borderless>
        <thead>
          <tr>
            <th>
              <b>
                <u>Etkinlik İstatistik :</u>
              </b>
            </th>
          </tr>
          <tr>
            <u>Etkinlik seçiminiyle istatistik seçenekleri açılacaktır.</u>
          </tr>
        </thead>
        <tbody style={{ textAlign: "left" }}>
          <tr>
            <th>
              <Dropdown
                disabled={!isSuccess}
                isOpen={eventT}
                toggle={toggleEvent}
              >
                <DropdownToggle caret size="lg">
                  {selectedEventId ? eventName : "Etkinlik"}
                </DropdownToggle>
                <DropdownMenu
                  style={{ maxHeight: "150px", overflow: "scroll" }}
                >
                  {eventList?.map((event: any) => (
                    <DropdownItem
                      onClick={() => {
                        handleEventSelection(
                          event.eventId,
                          event.eventState.game.name
                        );
                        toggleEvent();
                      }}
                      key={event.eventId}
                    >
                      {event?.eventState?.game?.name}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </th>
          </tr>
          {selectedEventId !== undefined ? (
            <>
              <tr style={{ margin: "25px 15px 15px 25px" }}>
                <th>
                  <Link
                    to={{
                      pathname: `/getJoinedEventUserList/${selectedEventId}`,
                    }}
                  >
                    Oyundaki etkinlik katılım sayısı listesi
                  </Link>
                </th>
              </tr>
              <tr style={{ margin: "25px 15px 15px 25px" }}>
                <th>
                  <Link
                    to={{
                      pathname: `/getEventUserScoresList/${selectedEventId}`,
                    }}
                  >
                    Oyundaki her bir oyuncunun etkinlikte kaç puan kazandığı
                    listesi
                  </Link>
                </th>
              </tr>
              <tr style={{ margin: "25px 15px 15px 25px" }}>
                <th>
                  <Link
                    to={{
                      pathname: `/getUserAnsweredQuestionCount/${selectedEventId}`,
                    }}
                  >
                    Oyundaki her bir oyuncunun etkinlikte doğru ve yanlış
                    cevapladığı soru sayıları listesi
                  </Link>
                </th>
              </tr>
              <tr>
                <th>
                  <Link
                    to={{
                      pathname: `/getEventAnsweredQuestionCount/${selectedEventId}`,
                    }}
                  >
                    Oyundaki etkinlik sorularından doğru ve yanlış olarak
                    cevaplanma sayıları listesi
                  </Link>
                </th>
              </tr>
            </>
          ) : (
            <tr></tr>
          )}
        </tbody>
      </Table>
    </div>
  );
}
