import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Table, Col, Row, Container } from "reactstrap";
import { Link } from "react-router-dom";
import Config, { Constants } from "../../Constants";;
import { logoutUser } from "src/slices/thunks";
import { useDispatch } from "react-redux";
import exportFromJSON from 'export-from-json';
import moment from "moment";
import loading from '../../assets/images/loading.gif';


export default class mostGainedMiniGamePointUsersList extends Component<any, any>  {
    constructor(props: any) {
        super(props);
        this.state = {
            result: [],
            isLoading: true
        };

        this.getNewUser = this.getNewUser.bind(this);
    }

    ExportToExcel = () => {
        const data = this.state.result.data.map((el: any) => {
            return { "Kullanıcı Adı": el.username };
        });

        const fileName = 'Bugün Sisteme Katılan Yeni Kullanıcılar Listesi';
        const exportType = 'xls';
        exportFromJSON({ data, fileName, exportType })
    }

    componentDidMount() {
        this.getNewUser().then(result => {
            this.setState({ result: result });
        });
    }

    async getNewUser() {
        try {
            var body = {
                "dateTime": moment.utc().format('yyyy-MM-DD HH:mm:ss')
            };
            var response = await fetch(Constants.url + "analytics/getTodayNewUsersList", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(body)
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();
            this.setState({ result: result });
            this.setState({ isLoading: false });

            return result;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ minHeight: "100vh" }}>
                    <div className="page-content">
                        <MetaTags>
                            <title>  Bugün Sisteme Katılan Yeni Kullanıcılar Listesi </title>
                        </MetaTags>
                    </div>
                    <div>
                        <Container style={{ textAlign: "right", direction: "ltr", alignItems: 'center', flex: 1 }}>
                            <div>
                                <Row style={{ textAlign: "left", direction: "ltr", marginLeft: "30px" }}>
                                    <Col>
                                        <h5><b><u> Bugün Sisteme Katılan Yeni Kullanıcılar Listesi </u></b></h5>
                                    </Col>
                                </Row>
                                <br />
                                <Row style={{ textAlign: "left", direction: "ltr", marginLeft: "30px" }}>
                                    <Col>
                                        <Link to="/analyticEvents" className="btn btn-primary">Geri</Link>
                                    </Col>
                                    <Col md={5} >
                                        <button type="button" className="btn btn-danger" onClick={this.ExportToExcel}>Bugün Sisteme Katılan Yeni Kullanıcılar Listesi İndir</button>
                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <br />
                            <br />
                            <div>
                            </div>
                            <br />
                            <Container style={{ direction: "ltr", flex: 1, alignContent: "center" }}>
                                {
                                    this.state.isLoading &&
                                    <div>
                                        <Col style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                            <img src={loading} alt="loading" style={{ width: "150px" }} />
                                            <h4>Bilgiler Yükleniyor...</h4>
                                        </Col>
                                    </div>
                                }
                            </Container>
                            <Col md={10}>
                                {!this.state.isLoading &&
                                    <Table bordered style={{ direction: "ltr", width: "800px" }}>
                                        <thead>
                                            <tr>
                                                <th style={{ textAlign: "center" }}>
                                                    Kullanıcı Adı
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.result != null && this.state.result != undefined && this.state.result.data != null ? (this.state.result.data.map(
                                                (newUser: any, index: number) => (
                                                    <tr key={index}>
                                                        <td style={{ textAlign: "center" }}>
                                                            {newUser.username}
                                                        </td>
                                                    </tr>
                                                )
                                            )) : (<tr>
                                                <td style={{ textAlign: "center" }}>

                                                </td>
                                            </tr>)}
                                        </tbody>
                                    </Table>
                                }
                            </Col>
                        </Container>
                    </div>
                </div>
            </React.Fragment>

        );
    }
}

