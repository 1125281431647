import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import Config, { Constants } from "../../Constants";

import {
  Table,
  Form,
  FormGroup,
  Label,
  Col,
  Row,
  Input,
  FormText,
  Button,
} from "reactstrap";
import { logoutUser } from "src/slices/thunks";
import { useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";

export default class AddQuestions extends Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      result: [],
      send: [],
      requiredQuestion: "Lütfen bir soru giriniz",
      requiredAnswer: "Lütfen bir cevap giriniz",
      questionCharsLeft: 1000,
      questionMaxChars: 1000,
      answerMaxChars: 250,
      answerChars1Left: 250,
      answerChars2Left: 250,
      answerChars3Left: 250,
      answerChars4Left: 250,
      question: "",
      correctAnswer: "",
      categoryType: "rünlerimiz",
      level: "1",
      option: "",
      answer1: "",
      answer2: "",
      answer3: "",
      answer4: "",
    };
  }

  handleQuestionCount(event: any) {
    const questionCharCount = event.target.value.length;
    const questionMaxChar = this.state.questionMaxChars;
    const charLength = questionMaxChar - questionCharCount;
    this.setState({ questionCharsLeft: charLength });
  }

  handleAnswer1Count(event: any) {
    const answerCharCount = event.target.value.length;
    const answerMaxChar = this.state.answerMaxChars;
    const charLength = answerMaxChar - answerCharCount;
    this.setState({ answerChars1Left: charLength });
  }

  handleAnswer2Count(event: any) {
    const answerCharCount = event.target.value.length;
    const answerMaxChar = this.state.answerMaxChars;
    const charLength = answerMaxChar - answerCharCount;
    this.setState({ answerChars2Left: charLength });
  }

  handleAnswer3Count(event: any) {
    const answerCharCount = event.target.value.length;
    const answerMaxChar = this.state.answerMaxChars;
    const charLength = answerMaxChar - answerCharCount;
    this.setState({ answerChars3Left: charLength });
  }

  handleAnswer4Count(event: any) {
    const answerCharCount = event.target.value.length;
    const answerMaxChar = this.state.answerMaxChars;
    const charLength = answerMaxChar - answerCharCount;
    this.setState({ answerChars4Left: charLength });
  }

  async getCategory() {
    try {
      var response = await fetch(Constants.url + "admin/getQuestionCategory", {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      });

      if (response.status == 401 || response.status == 403) {
        const dispatch = useDispatch();
        dispatch(logoutUser(history));
        return;
      }
      var result = await response.json();
      this.setState({ userInfo: result });

      return result;
    } catch (error) {
      console.log("error: ", error);
    }
  }

  async postQuestion() {
    try {
      var body = {
        question: this.state.question,
        correctAnswer: this.state.correctAnswer,
        categoryType: this.state.categoryType,
        level: this.state.level,
        option: this.state.option,
        answer1: this.state.answer1,
        answer2: this.state.answer2,
        answer3: this.state.answer3,
        answer4: this.state.answer4,
      };

      var response = await fetch(Constants.url + "admin/addQuestionManuel", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
        body: JSON.stringify(body),
      });

      if (response.status == 401 || response.status == 403) {
        const dispatch = useDispatch();
        dispatch(logoutUser(history));
        return;
      }

      if (response.status == 200) {
        alert("Kayıt Başarılı");
      }

      var result = await response.json();
      this.setState({ send: result });

      return result;
    } catch (error) {
      console.log("error: ", error);
    }
  }

  componentDidMount() {
    this.getCategory().then(result => {
      this.setState({ result: result });
    });
  }

  componentWillUnmount() {
    this.postQuestion().then(result => {
      this.setState({ send: result });
    });
  }

  render() {
    return (
      <div
        className="page-content"
        style={{
          textAlign: "left",
          direction: "ltr",
          margin: "-30px 0px 0px 50px",
        }}
      >
        <h5>Soru Ekleme:</h5>
        <Form>
          <FormGroup>
            <Label for="category">Kategori Seçiniz</Label>
            <Input
              id="category"
              name="category"
              type="select"
              onChange={event =>
                this.setState({ categoryType: event.target.value })
              }
            >
              {this.state.result != null ? (
                this.state.result.map((category: any, index: number) => (
                  <option key={index} value={category.type}>
                    {category.type}
                  </option>
                ))
              ) : (
                <option> </option>
              )}
            </Input>
          </FormGroup>
          <br />
          <FormGroup>
            <Label for="category">Soru Seviyesi Seçiniz</Label>
            <Input
              id="level"
              name="level"
              type="select"
              onChange={event => this.setState({ level: event.target.value })}
            >
              <option value="1"> 1 </option>
              <option value="2"> 2 </option>
              <option value="3"> 3 </option>
              <option value="4"> 4 </option>
              <option value="5"> 5 </option>
            </Input>
            {this.props.isCallback === true ? (
              <>
                <Label for="category">Soru Cevaplama Süresi</Label>
                <Input
                  type="number"
                  onChange={event => {
                    this.setState({ time: event.target.value });
                  }}
                  id="time"
                ></Input>
              </>
            ) : null}
          </FormGroup>
          <br />
          <FormGroup>
            <div>
              <Label for="question">Soru Giriniz</Label>
              <Input
                id="question"
                name="question"
                type="textarea"
                required={this.state.requiredQuestion}
                maxLength={this.state.questionMaxChars}
                onChange={event => {
                  this.handleQuestionCount(event);
                  this.setState({ question: event.target.value });
                }}
              />
              <div>{this.state.questionCharsLeft}</div>
            </div>
          </FormGroup>
          <br />
          <FormGroup tag="fieldset">
            <legend>
              Cevaplar (Lütfen doğru olan seçeneği işaretleyiniz!)
            </legend>
            <FormGroup>
              <div>
                <Row>
                  <Col>
                    <Input
                      id="answer1"
                      name="answer1"
                      placeholder="Lütfen birinci cevabı giriniz"
                      type="text"
                      required={this.state.requiredAnswer}
                      maxLength={this.state.answerMaxChars}
                      onChange={event => {
                        this.handleAnswer1Count(event);
                        this.setState({ answer1: event.target.value });
                        this.setState({ option: 1 });
                      }}
                    />
                  </Col>
                  <Col>
                    <Input
                      name="radio1"
                      type="radio"
                      value="answer1"
                      onChange={() => {
                        this.state.answer1 != null
                          ? this.setState({ correctAnswer: 1 })
                          : this.setState({ correctAnswer: 0 });
                      }}
                    />
                  </Col>
                </Row>
                <div>{this.state.answerChars1Left}</div>
              </div>
            </FormGroup>
            <br />
            <FormGroup>
              <Row>
                <Col>
                  <Input
                    id="answer2"
                    name="answer2"
                    placeholder="Lütfen ikinci cevabı giriniz"
                    type="text"
                    required={this.state.requiredAnswer}
                    maxLength={this.state.answerMaxChars}
                    onChange={event => {
                      this.handleAnswer2Count(event);
                      this.setState({ answer2: event.target.value });
                      this.setState({ option: 2 });
                    }}
                  />
                </Col>
                <Col>
                  <Input
                    name="radio1"
                    type="radio"
                    value="answer2"
                    onChange={event => {
                      this.state.answer2 != null
                        ? this.setState({ correctAnswer: 2 })
                        : this.setState({ correctAnswer: 0 });
                    }}
                  />
                </Col>
                <div>{this.state.answerChars2Left}</div>
              </Row>
            </FormGroup>
            <br />
            <FormGroup>
              <Row>
                <Col>
                  <Input
                    id="answer3"
                    name="answer3"
                    placeholder="Lütfen üçüncü cevabı giriniz"
                    type="text"
                    maxLength={this.state.answerMaxChars}
                    onChange={event => {
                      this.handleAnswer3Count(event);
                      this.setState({ answer3: event.target.value });
                      this.setState({ option: 3 });
                    }}
                  />
                </Col>
                <Col>
                  <Input
                    name="radio1"
                    type="radio"
                    value="answer3"
                    onChange={event => {
                      this.state.answer3 != null
                        ? this.setState({ correctAnswer: 3 })
                        : this.setState({ correctAnswer: 0 });
                    }}
                  />
                </Col>
                <div>{this.state.answerChars3Left}</div>
              </Row>
            </FormGroup>
            <br />
            <FormGroup>
              <Row>
                <Col>
                  <Input
                    id="answer4"
                    name="answer4"
                    placeholder="Lütfen dördüncü cevabı giriniz"
                    type="text"
                    maxLength={this.state.answerMaxChars}
                    onChange={event => {
                      this.handleAnswer4Count(event);
                      this.setState({ answer4: event.target.value });
                      this.setState({ option: 4 });
                    }}
                  />
                </Col>
                <Col>
                  <Input
                    name="radio1"
                    type="radio"
                    value="answer4"
                    onChange={event => {
                      this.state.answer4 != null
                        ? this.setState({ correctAnswer: 4 })
                        : this.setState({ correctAnswer: 0 });
                    }}
                  />
                </Col>
                <div>{this.state.answerChars4Left}</div>
              </Row>
            </FormGroup>
          </FormGroup>
          <br />
          <br />

          <Button
            style={{ backgroundColor: "darkblue" }}
            onClick={async () => {
              if (this.props.isCallback === true) {
                this.props.handleOnSubmit(this.state);
              } else {
                await this.postQuestion();
              }
            }}
          >
            Ekle
          </Button>
          <Button
            style={{ backgroundColor: "darkblue", marginLeft: 7 }}
            onPress={() => {
              <Redirect to="/addQuestion" />;
            }}
          >
            İptal
          </Button>
        </Form>
      </div>
    );
  }
}
