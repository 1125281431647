import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Table, Col, Row, Container, Form, FormGroup, Label, Input } from "reactstrap";
import { Link } from "react-router-dom";
import { Constants } from "../../Constants";
import { logoutUser } from "src/slices/thunks";
import { useDispatch } from "react-redux";
import exportFromJSON from 'export-from-json';
import exclamation from '../../assets/images/exclamation.gif';
import loading from '../../assets/images/loading.gif';
import { PageRequest } from "src/common/data/pageRequest";


export default class allAnswersByCategory extends Component<any, any>  {
    constructor(props: any) {
        super(props);
        this.state = {
            result: undefined,
            category: undefined,
            choosenCategory: "All",
            isLoading: true,
            error: null,
            pageRequest: PageRequest
        };

        this.getQuestion = this.getQuestion.bind(this);
        this.getCategory = this.getCategory.bind(this);
        this.changeCategory = this.changeCategory.bind(this);
    }

    ExportToExcel = async () => {
        var mergedResult = this.state.result.data;
        mergedResult= [];
        for (let index = 0; index < Math.ceil(this.state.result.recordsTotal / this.state.pageRequest.size); index++) {

            if(this.state.choosenCategory==="All"){
                await this.getQuestion(new PageRequest(index+1,this.state.pageRequest.size)).then(result => {
                    mergedResult = mergedResult.concat(result.data);
                });  
            }
            else{
                 await this.changeCategory(this.state.choosenCategory,new PageRequest(index+1,this.state.pageRequest.size)).then(result => {
                    mergedResult = mergedResult.concat(result.data);
                });  
            }
                  
        }
        mergedResult = mergedResult.sort(function( a: any, b: any){
            return parseFloat(a.count) - parseFloat(b.count);
        });
        mergedResult = mergedResult.reverse();
        const data = mergedResult.map((el : any, index : number) => {
            return { "Soru Seviyesi" : el.question.level, "Sorunun Kategorisi" : el.question.category, "Yanıtlanan Sorular" : el.question.question,
                    "Toplam Yanıtlama Sayısı" : el.count, "Doğru Yanıtlanma Sayısı" : el.correctAnswerCount, "Yanlış Yanıtlanma Sayısı" : el.incorrectAnswerCount};  
        }); 
        
        const fileName = 'Tüm ve Kategori Bazında Cevaplanan Sorular' ;
        const exportType = 'xls' ;
        exportFromJSON({ data, fileName, exportType })  
    } 

    componentDidMount(){ 
        this.getQuestion(new PageRequest()).then(result => {
            this.setState({result: result});
        }); 
        this.getCategory().then(result => {
            this.setState({ result: result });
        });
        this.setState({pageRequest:new PageRequest()})
    }

    componentWillUnmount(){        
        this.changeCategory(this.state.choosenCategory,this.state.pageRequest).then(result => {
            this.setState({result: result})});        
    }
    async getQuestion (pageRequest: PageRequest ) {
        try {
            var response = await fetch( Constants.url + "analytics/getAllAnswers?page"+pageRequest.page+"&size="+pageRequest.size, {
                headers:{ 
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }

            var result = await response.json();
            this.setState({ result: result });
            this.setState({ isLoading: false });
            return result;

        } catch (error) {
            this.setState({ isLoading: false });
            console.log("error", error)
            this.setState({ error: error });
        }
    }
    async getCategory() {
        try {
            var response = await fetch(Constants.url + "admin/getQuestionCategory",
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    }
                });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();
            this.setState({ category: result });
            

            return result;


        } catch (error) {            
            console.log("error", error)
        }
    }

      async changeCategory(category : string , pageRequest: PageRequest | null=null) {
        console.log("choosen category = ", category)
        if( category == "All"){
            return this.getQuestion(new PageRequest());
        }
        else {
            try {
                var body = {
                    "category" : category,
                    "pageRequest": pageRequest
                    };
    
                    console.log("body Correct Question: ", body)
    
                var response = await fetch( Constants.url + "analytics/getAllAnswersByCategory", {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    },
                    body: JSON.stringify(body)
                });
                if (response.status == 401 || response.status == 403) {
                    const dispatch = useDispatch();
                    dispatch(logoutUser(history));
                    return
                }
                var result = await response.json();
                this.setState({ result: result });
                this.setState({ isLoading: false });

                return result;

            } catch (error) {
                this.setState({ isLoading: false });
                console.log("error", error)
                this.setState({ error: error });
            }
        }
    }


    render() {
        return (
            <React.Fragment>
                <div style={{ minHeight: "100vh" }}>
                    <div className="page-content">
                        <MetaTags>
                            <title>  Tüm ve Kategori Bazında Cevaplanan Sorular </title>
                        </MetaTags>
                    </div>
                    <div>
                        <Container style={{ textAlign: "right", direction: "ltr", alignItems: 'center', flex: 1 }}>
                            <div>
                                <Row style={{ textAlign: "left", direction: "ltr", marginLeft: "30px" }}>
                                    <Col>
                                        <h5><b><u> Tüm ve Kategori Bazında Cevaplanan Sorular </u></b></h5>
                                    </Col>
                                </Row>
                                <br />
                                <Row style={{ textAlign: "left", direction: "ltr", marginLeft: "30px" }}>
                                    <Col>
                                        <Link to="/analyticEvents" className="btn btn-primary">Geri</Link>
                                    </Col>
                                    <Col md={5} >
                                        <button type="button" className="btn btn-danger" onClick={this.ExportToExcel}>Tüm ve Kategori Bazında Cevaplanan Sorular Listesi İndir</button>
                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <br />
                            <br />
                            <div>
                                <Col md={4} style={{ textAlign: "left", direction: "ltr", margin: "-45px 0px 0px 45px" }}>
                                    <Form>
                                        <FormGroup>
                                            <Label for="category">
                                                Kategori Seçiniz
                                            </Label>
                                            <Input
                                                id="category"
                                                name="category"
                                                type="select"
                                                onChange={async event => { this.setState({ isLoading: true }); this.setState({ choosenCategory: event.target.value }); await this.changeCategory(event.target.value); }}
                                            >
                                                <option value="All">Tüm Kategoriler</option>
                                                {this.state.category != null ? (this.state.category.map(
                                                    (category: any, index: number) => (
                                                        <option key={index} value={category.type}>
                                                            {category.type}
                                                        </option>
                                                    ))) : (<option>  </option>)
                                                }
                                            </Input>
                                        </FormGroup>
                                    </Form>
                                </Col>
                            </div>
                            <br />
                            <div>

                                <Container style={{ direction: "ltr", flex: 1, alignContent: "center", marginTop: '10px' }}>
                                    {
                                        this.state.isLoading &&
                                        <div>
                                            <Col style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={loading} alt="loading" style={{ width: "150px" }} />
                                                <h4>Bilgiler Yükleniyor...</h4>
                                            </Col>
                                        </div>
                                    }

                                </Container>
                                <Container style={{ direction: "ltr", flex: 1, alignContent: "center" }}>
                                    {!this.state.isLoading && this.state.error != null &&
                                        <div>
                                            <Col style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={exclamation} alt="exclamation" style={{ width: "75px" }} />
                                                <h4>Verileri Çekme Esnasında Hata Oluştu</h4>
                                            </Col>
                                        </div>
                                    }

                                </Container>
                                <Container style={{ textAlign: "right", direction: "ltr", width: "800px", alignItems: 'center', flex: 1 }}>
                                    {!this.state.isLoading && this.state.error == null &&
                                        <Table bordered style={{ direction: "ltr" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "center" }}>
                                                        Soru Seviyesi
                                                    </th>

                                                    <th style={{ textAlign: "center" }}>
                                                        Sorunun Kategorisi
                                                    </th>
                                                    <th style={{ textAlign: "center" }}>
                                                        Yanıtlanan Sorular
                                                    </th>
                                                    <th style={{ textAlign: "center" }}>
                                                        Toplam Yanıtlama Sayısı
                                                    </th>
                                                    <th style={{ textAlign: "center" }}>
                                                        Doğru Yanıtlama Sayısı
                                                    </th>
                                                    <th style={{ textAlign: "center" }}>
                                                        Yanlış Yanıtlama Sayısı
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.result != null && this.state.result.data != null ? (this.state.result.data.map(
                                                    (questions: any, index: number) => (
                                                        <tr key={index}>
                                                            <td style={{ textAlign: "center" }}>
                                                                {questions.question.level}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {questions.question.category}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {questions.question.question}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {questions.count}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {questions.correctAnswerCount}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {questions.incorrectAnswerCount}
                                                            </td>
                                                        </tr>
                                                    )
                                                )) : (<tr>
                                                    <td style={{ textAlign: "center" }}>

                                                    </td>
                                                    <td style={{ textAlign: "center" }}>

                                                    </td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    }
                                </Container>
                            </div>
                        </Container>
                    </div>
                </div>
            </React.Fragment>

        );
    }
}

