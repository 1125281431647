import MetaTags from "react-meta-tags";
import React from "react";

import { Row, Col, Alert, Container } from "reactstrap";

//redux
import { useSelector, useDispatch } from "react-redux";

import { withRouter, Link } from "react-router-dom";

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation";

// actions
import { loginUser } from "../../slices/thunks";

//import images
import islegendfavicon from "../../assets/images/islegendfavicon.png";
interface LoginProps {
  history: any;
}

const Login = ({ history }: LoginProps) => {
  const dispatch = useDispatch();

  const { error } = useSelector((state: any) => ({
    error: state.login.error,
  }));


  // handleValidSubmit
  const handleValidSubmit = (values: [any,any]) => {
    dispatch(loginUser(values, history));
  };

  var username : any ;
  var password : any ;
  return (
    <React.Fragment>
      <MetaTags>
        <title>İş Legends Giriş Sayfası</title>
      </MetaTags>
      <div className="authentication-bg min-vh-100">
        <div className="bg-overlay bg-white"></div>
        <Container>
          <div className="d-flex flex-column min-vh-100 px-3 pt-4">
            <Row className="justify-content-center my-auto" style={{ direction: "ltr", alignItems:'center', flex: 1}}>
              <Col md={8} lg={6} xl={4}>

                <div className="py-5">
                  <div className="mb-4 mb-md-5 text-center">
                    <Link to="/userInfo" className="d-block auth-logo">
                      <img src={islegendfavicon} alt="" height="100" className="auth-logo-dark" />
                      <img src={islegendfavicon} alt="" height="100" className="auth-logo-light" />
                    </Link>
                  </div>
                  <div className="text-center mb-4">
                    <h2>Hoşgeldiniz</h2>
                  </div>
                  <AvForm
                  >
                    {error ? <Alert color="danger">{error}</Alert> : null}
                    <div className="form-floating form-floating-custom mb-3">
                      <AvField
                        name="userName"
                        label="Kullanıcı adı"
                        value=""
                        className="form-control"
                        placeholder="Kullanıcı adı giriniz.."
                        type="text"
                        required
                        onChange={(e : any) => username = e.target.value }
                      />
                    </div>
                    <div className="form-floating form-floating-custom mb-3">
                      <AvField
                        name="password"
                        label="Parola"
                        value=""
                        type="password"
                        className="form-control"
                        required
                        placeholder="Parola giriniz.."
                        onChange={(e : any) => password = e.target.value }
                      />
                    </div>
                    <div  style={{ direction: "ltr", alignItems:'center'}}>
                      {/* <Row >
                        <Col md={1}> 
                        <h5>
                        <input className="form-check-input" type="checkbox" id="remember-check"  />  
                        </h5>                                                            
                        </Col>                        
                           <Col>
                           <h5>
                           <label className="form-check-label font-size-14" >  Beni Hatırla </label>   
                           </h5>                          
                           </Col>                              
                        </Row>                 */}
                   
                    </div>

                    <div className="mt-3">
                      
                      <button  className="btn btn-info w-100" type="submit" onClick={() => handleValidSubmit([username,password])} >Giriş</button>
                      
                    </div>                  
                  </AvForm>                
                </div>
              </Col>
            </Row>

          </div>
        </Container>
      </div></React.Fragment>
  );
};

export default withRouter(Login);