import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import Search from "src/pages/QuestionOperations/search";
import SearchList from "src/pages/QuestionOperations/searchList";
import exportFromJSON from 'export-from-json';
import Config, { Constants } from "../../Constants";
import { useDispatch } from "react-redux";
import { logoutUser } from "src/slices/thunks";
import { Link } from "react-router-dom";
import { Table, Form, FormGroup, Label, Input, ListGroup, ListGroupItem, Col, Row, Container, Button } from "reactstrap";
import Store from "src/store";

export default class QuestionOperations extends Component<any, any> {
    constructor(props: any) {
        super(props);
        // var filteredQuestions = Store.getData("filteredQuestion");
        this.state = {
            allQuestions: [],
            filteredQuestions: [],
            category: [],
            choosenQuestion: null,
            filterWord: "",
            choosenValue: null,
            choosenCategory: null
        };
        this.changeCategory = this.changeCategory.bind(this);
        this.callbackFunction = this.callbackFunction.bind(this);
    }

    async areYouSure() {
        var answer = window.confirm("Silmek istediğinizden emin misiniz?");
        if (answer) {
            try {
                var body = {
                    "questionId": this.state.choosenQuestion.id
                }
                var response = await fetch(Constants.url + "admin/deleteQuestionsById",
                    {
                        method: 'PUT',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'Bearer ' + localStorage.getItem("token")
                        },
                        body: JSON.stringify(body)
                    });
                if (response.status == 401 || response.status == 403) {
                    const dispatch = useDispatch();
                    dispatch(logoutUser(history));
                    return
                }
                if (response.status == 200) {
                    alert("Silme işlemi Başarılı");
                    this.GetFiltredQuestions();
                }
                var result = await response.json();
                return result;

            } catch (error) {
                console.log("error: ", error);
            }
        }
        else {
            this.props.history;
        }
    }

    ExportToExcelFiltered = () => {
        const data = this.state.filteredQuestions.data.map((el: any, index: number) => {
            var row: any = {
                "Kategori": el.category,
                "Soru IDsi": el.id,
                "Soru": el.question,
                "Cevap 1": el.questionAnswers[0],
                "Cevap 2": el.questionAnswers[1],
                "Cevap 3": (el.questionAnswers[2] != null ? el.questionAnswers[2] : ""),
                "Cevap 4": (el.questionAnswers[3] != null ? el.questionAnswers[3] : ""),
                "Doğru Cevap": el.correctAnswer,
                "Soru Seviyesi": el.level,
            };

            return row;
        });

        const fileName = 'Filtrelenmiş Sorular';
        const exportType = 'xls';
        exportFromJSON({ data, fileName, exportType });
    }

    ExportToExcelAll = () => {
        this.GetAllQuestions().then(result => this.setState({ allQuestions: result }))
        const data = this.state.allQuestions.map((el: any, index: number) => {
            var row: any = {
                "Kategori": el.category,
                "Soru IDsi": el.id,
                "Soru": el.question,
                "Cevap 1": el.answers[0].answer,
                "Cevap 2": el.answers[1].answer,
                "Cevap 3": (el.answers[2] != null ? el.answers[2].answer : ""),
                "Cevap 4": (el.answers[3] != null ? el.answers[3].answer : ""),
                "Doğru Cevap": el.correctAnswer,
                "Soru Seviyesi": el.level,
            };

            return row;
        });

        const fileName = 'Tüm Sorular';
        const exportType = 'xls';
        exportFromJSON({ data, fileName, exportType });
    }

    async getCategory() {
        try {
            var response = await fetch(Constants.url + "admin/getQuestionCategory",
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    }
                });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();
            this.setState({ category: result });
            return result;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    async changeCategory(category: string) {
        try {
            var body = {
                "filterWord": this.state.filterWord,
                "category": category
            };


            var response = await fetch(Constants.url + "admin/getFilteredQuestionsWithCategory", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                },
                body: JSON.stringify(body)
            });
            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();
            this.setState({ filteredQuestions: result });
            Store.setData("filteredQuestion", result);

            return result;

        } catch (error) {
            console.log("error: ", error);
        }

    }

    async GetAllQuestions() {
        try {
            var response = await fetch(Constants.url + "admin/getAllQuestion",
                {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    }
                });

            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();
            this.setState({ allQuestions: result });

            return result;

        } catch (error) {
            console.log("error: ", error);
        }
    }


    async GetFiltredQuestions() {
        try {
            var body = {
                "filterWord": this.state.filterWord
            }
            var response = await fetch(Constants.url + "admin/getFilteredQuestions",
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + localStorage.getItem("token")
                    },
                    body: JSON.stringify(body)
                });

            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();
            this.setState({ filteredQuestions: result });
            Store.setData("filteredQuestion", result);

            return result;

        } catch (error) {
            console.log("error: ", error);
        }
    }

    sendEditPage(chosenValue: any) {
        this.setState({ choosenValue: chosenValue })
        Store.setData("choosenQuestion", this.state.choosenQuestion)
    }

    async callbackFunction(childData: any) {
        if (childData != null) {
            await this.setState({ choosenQuestion: childData });
            Store.setData("choosenQuestion", childData);
        }
    }

    componentDidMount() {
        this.GetAllQuestions().then(result => this.setState({ allQuestions: result }));
        this.getCategory().then(result => {
            this.setState({ category: result });
        });
    }

    render() {

        return (
            <React.Fragment>
                <div style={{ minHeight: "100vh", alignItems: 'center', flex: 1 }}>
                    <div className="page-content" >
                        <MetaTags>
                            <title>Soru İşlemleri </title>
                        </MetaTags>
                        <Row>
                            <Col>
                                <h4 style={{ textAlign: "left", margin: "15px 0px 0px 20px" }}>Soru İşlemleri</h4>
                            </Col>
                        </Row>
                        <Row style={{ textAlign: "left", direction: "ltr", margin: "100px 0px 0px -10px" }}>
                            <Col xs="8">
                                <form style={{ textAlign: "left", direction: "ltr", margin: "-75px 0px 0px 20px" }}>
                                    <h5>Arama</h5>
                                    <input type="text" name="questionSearch" onChange={event => this.setState({ filterWord: event.target.value })} />
                                    <Button style={{ backgroundColor: "darkblue", margin: "-10px 0px 0px 30px" }} onClick={async e => await this.GetFiltredQuestions()}> Ara</Button>
                                </form>
                            </Col>
                            <Col xs="3" style={{ textAlign: "right", margin: "-50px 0px 0px 0px" }}>
                                <Link to="/editQuestion" className="btn btn-primary" style={{ backgroundColor: "darkblue" }} onClick={() => { this.sendEditPage(this.state.choosenValue) }} >Düzenle</Link>
                            </Col>

                            <Col style={{ textAlign: "center", margin: "-50px 0px 0px 0px" }}>
                                <Button style={{ backgroundColor: "darkblue" }} onClick={() => { this.areYouSure() }} >Sil</Button>
                            </Col>
                        </Row>
                        <br />
                        <Row style={{ textAlign: "center", direction: "rtl", marginRight: "3px" }}>
                            <Col md="2">
                                <button type="button" className="btn btn-danger" onClick={this.ExportToExcelFiltered}>Filtrelenmiş Soruları İndir</button>
                            </Col>
                            <Col md="2">
                                <button type="button" className="btn btn-danger" onClick={this.ExportToExcelAll}>Tüm Soruları İndir</button>
                            </Col>
                            <Col md={5}></Col>
                            <Col md={3} style={{ textAlign: "left", direction: "ltr" }}>
                                <Form style={{ textAlign: "left", direction: "ltr", marginLeft: "20px", }}>
                                    <FormGroup>
                                        <Input
                                            id="category"
                                            name="category"
                                            type="select"
                                            onChange={async event => { this.setState({ choosenCategory: event.target.value }); await this.changeCategory(event.target.value); }}
                                        >
                                            <option value="All">Tüm Kategoriler</option>
                                            {this.state.category != null ? (this.state.category.map(
                                                (category: any, index: number) => (
                                                    <option key={index} value={category.type}>
                                                        {category.type}
                                                    </option>
                                                ))) : (<option>  </option>)
                                            }
                                        </Input>
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <br />
                        <hr />
                    </div>
                    <div>
                        {this.state.filteredQuestions.data != null &&
                            <Container style={{ textAlign: "left", direction: "ltr", alignItems: 'center', flex: 1 }}>
                                <Row style={{ textAlign: "center", marginTop: "-120px" }}>
                                    <SearchList filteredQuestionfromParent={this.state.filteredQuestions} parentCallback={this.callbackFunction} ></SearchList>
                                </Row>
                            </Container>
                        }
                    </div>
                </div>
                <br />
            </React.Fragment>

        );
    }

}



