import React, {Component} from "react";
import MetaTags from "react-meta-tags";
import { Table, ListGroup, ListGroupItem, Col, Row, Container,Form, FormGroup, Label, Input, FormText, Button } from "reactstrap";
import { Link } from "react-router-dom";
import Config, {Constants} from "../../Constants";
import { logoutUser } from "src/slices/thunks";
import { useDispatch } from "react-redux";
import exportFromJSON from 'export-from-json';


export default class correctlyAnswersByCategory extends Component<any,any>  {
    constructor(props:any) {
        super(props);
        this.state = {      
            result : undefined,   
            category : undefined,
            choosenCategory : "All"
        };
      
         this.getQuestion = this.getQuestion.bind(this);
         this.getCategory = this.getCategory.bind(this);
         this.changeCategory = this.changeCategory.bind(this);
    }

    ExportToExcel = () => {  
        const data = this.state.result.data.map((el : any, index : number) => {
             return { "Soru Seviyesi" : el.question.level, "Sorunun Kategorisi" : el.question.category, "Doğru Yanıtlanan Sorular" : el.question.question, "Doğru Yanıtlama Sayısı" : el.count };
        }); 
        
        const fileName = 'Doğru Cevaplanan Sorular' ;
        const exportType = 'xls' ;
        exportFromJSON({ data, fileName, exportType })  
    } 

    componentDidMount(){ 
        this.getQuestion().then(result => {
            this.setState({result: result});
        }); 
        this.getCategory().then(result => {
            this.setState({result: result});
        });
    }

    componentWillUnmount(){        
        this.changeCategory(this.state.choosenCategory).then(result => {
            this.setState({result: result})});        
    }
    async getQuestion ( ) {
        try {
            var response = await fetch( Constants.url + "analytics/getMostCorrectlyAnswers", {
                headers:{ 
                    'Content-Type': 'application/json',
                    'Authorization' : 'Bearer ' + localStorage.getItem("token") 
                }
            });
            if ( response.status == 401 || response.status == 403){
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }
            var result = await response.json();
            this.setState({ result : result});
              
            return result;

        } catch (error) {
            console.log("error: " , error);
        }  
    }
    async getCategory() {
        try {
          var response = await fetch(Constants.url + "admin/getQuestionCategory", 
          { 
              headers:{ 'Content-Type': 'application/json',
                        'Authorization' : 'Bearer ' + localStorage.getItem("token") 
              }
          });
          if ( response.status == 401 || response.status == 403){
              const dispatch = useDispatch();
              dispatch(logoutUser(history));
              return
          }
          var result = await response.json();
          this.setState({category : result});
    
          return result;

    
        } catch (error) {                
            console.log("error: " , error);
        }
      }

      async changeCategory(category : string) {
        if( category == "All"){
            return this.getQuestion();
        }
        else{
            try {
                var body = {
                    "category" : category
                    };
    
                var response = await fetch( Constants.url + "analytics/getMostCorrectlyAnswersByCategory", {
                    method: 'POST',
                    headers:{
                        'Content-Type': 'application/json',
                        'Authorization' : 'Bearer ' + localStorage.getItem("token") 
                    },
                    body: JSON.stringify(body)
                });
                if ( response.status == 401 || response.status == 403){
                    const dispatch = useDispatch();
                    dispatch(logoutUser(history));
                    return
                }
                var result = await response.json();
                this.setState({result : result});
                  
                return result;
    
            } catch (error) {
                console.log("error: " , error);
            }  
        }
      }
        
    
    render() { 
        return (
            <React.Fragment>
                  <div style={{ minHeight : "100vh"}}>
                    <div className="page-content">
                        <MetaTags>
                            <title>  Doğru Cevaplanan Sorular </title>
                        </MetaTags>                        
                    </div>     
                    <div>
                        <Container style={{ textAlign: "right", direction: "ltr", alignItems:'center', flex: 1}}>
                        <div>
                            <Row  style={{textAlign: "left", direction: "ltr", marginLeft:"30px"}}>
                                <Col>
                                    <h5><b><u> Doğru Cevaplanan Sorular </u></b></h5>
                                </Col>                                
                            </Row>
                            <br />
                            <Row  style={{textAlign: "left", direction: "ltr", marginLeft:"30px"}}>
                                <Col>
                                    <Link to="/analyticEvents" className="btn btn-primary">Geri</Link>
                                </Col>                            
                                <Col md={5} >
                                    <button type="button" className="btn btn-danger" onClick={this.ExportToExcel}>Doğru Yanıtlanan Sorular Listesi İndir</button>  
                                </Col>
                            </Row>
                            </div>
                            <br />
                            <br />
                            <br />
                            <div>
                            <Col md={4} style={{ textAlign: "left", direction: "ltr", margin:"-45px 0px 0px 45px"}}>
                            <Form>
                              <FormGroup>
                                  <Label for="category">
                                    Kategori Seçiniz
                                  </Label>
                                  <Input
                                    id="category"
                                    name="category"
                                    type="select"
                                    onChange={ async event => {this.setState({choosenCategory : event.target.value}); await this.changeCategory(event.target.value);}}
                                  >
                                    <option value="All">Tüm Kategoriler</option>
                                    { this.state.category != null ? (this.state.category.map(
                                                    (category : any, index : number) => (
                                                      <option key = {index} value = {category.type}>
                                                        {category.type}
                                                      </option>
                                                    ))) : (<option>  </option>)
                                                  }                           
                                  </Input>
                              </FormGroup>
                            </Form>
                        </Col>
                        </div>
                        <br />
                        <Col md={11}>
                            <Table bordered style={{ direction: "ltr"}}>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center"}}>
                                            Soru Seviyesi
                                        </th>
            
                                        <th style={{ textAlign: "center"}}>
                                            Sorunun Kategorisi
                                        </th>
                                        <th style={{ textAlign: "center"}}>
                                            Doğru Yanıtlanan Sorular
                                        </th>

                                        <th style={{ textAlign: "center"}}>
                                            Doğru Yanıtlama Sayısı
                                        </th>
                                    </tr>
                                </thead>
                                <tbody> 
                                    { this.state.result != null && this.state.result.data != null ? (this.state.result.data.map(
                                        (questions : any, index : number) => (
                                            <tr key={index}>
                                                <td style={{ textAlign: "center"}}>
                                                    {questions.question.level}                                   
                                                </td>
                                                <td style={{ textAlign: "center"}}>
                                                    {questions.question.category}
                                                </td>
                                                <td style={{ textAlign: "center"}}>
                                                    {questions.question.question}                                       
                                                </td>
                                                <td style={{ textAlign: "center"}}>
                                                    {questions.count}
                                                </td>
                                            </tr>
                                        )
                                    )) : (<tr>
                                        <td style={{ textAlign: "center"}}>

                                        </td>
                                        <td style={{ textAlign: "center"}}>

                                        </td>
                                    </tr>)} 
                                </tbody>
                            </Table>
                        </Col>
                            
                        </Container>
                    </div>
                </div> 
            </React.Fragment>
            
        );
    }
}

