import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Table, Col, Row, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { Constants } from "../../Constants";
import exportFromJSON from 'export-from-json';
import { logoutUser } from "src/slices/thunks";
import { useDispatch } from "react-redux";
import exclamation from '../../assets/images/exclamation.gif';
import loading from '../../assets/images/loading.gif';


export default class mostAnsweredQuestionUsers extends Component<any, any>  {
    constructor(props: any) {
        super(props);
        this.state = {
            mostAnsweredQuestions: [],
            isLoading: true,
            error: null
        };

    }

    ExportToExcel = () => {
        const data = this.state.mostAnsweredQuestions.map((el: any, index: number) => {
            return {
                "Kullanıcı Adı": el.username,
                "Cevaplanan Soru Sayısı": el.answerCount,
                "Doğru Cevaplanan Soru Sayısı": el.correctAnswerCount,
                "Yanlış Cevaplanan Soru Sayısı": (el.answerCount - el.correctAnswerCount),
                "Başarı Yüzdesi": "%" + ((el.correctAnswerCount * 100) / el.answerCount).toFixed(2)
            };
        });

        const fileName = 'En Çok Soru Yanıtlayan Kullanıcılar Listesi';
        const exportType = 'xls';
        exportFromJSON({ data, fileName, exportType })
    }

    componentDidMount() {
        this.getMostAnsweredQuestions();
    }


    async getMostAnsweredQuestions() {
        try {
            var response = await fetch(Constants.url + "analytics/mostAnsweredQuestions", {
                method: 'Get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            });

            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }

            var answerResults = await response.json();
            this.setState({ mostAnsweredQuestions: answerResults });
            this.setState({ isLoading: false });

            return answerResults;
        }
        catch (error) {
            this.setState({ isLoading: false });
            this.setState({ error: error });
            console.log("error", error)
        }
    }


    render() {
        return (
            <React.Fragment>
                <div style={{ minHeight: "100vh" }}>
                    <div className="page-content">
                        <MetaTags>
                            <title>  En Çok Soru Yanıtlayan Kullanıcılar</title>
                        </MetaTags>
                    </div>
                    <div>
                        <Container style={{ textAlign: "right", direction: "ltr", alignItems: 'center', flex: 1 }}>
                            <div>
                                <Row style={{ textAlign: "left", direction: "ltr", marginLeft: "50px" }}>
                                    <Col>
                                        <h5 ><b><u> En Çok Soru Yanıtlayan Kullanıcılar </u></b></h5>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col style={{ textAlign: "left", direction: "ltr", marginLeft: "60px" }}>
                                        <Link to="/analyticEvents" className="btn btn-primary" onClick={this.props.history.pop}>Geri</Link>
                                    </Col>
                                    <Col md={5} >
                                        <button type="button" className="btn btn-danger" onClick={this.ExportToExcel}>En Çok Soru Yanıtlayan Kullanıcılar Listesi İndir</button>
                                    </Col>
                                </Row>
                                <br />
                            </div>
                            <div>
                                <Container style={{ direction: "ltr", flex: 1, alignContent: "center" }}>
                                    {
                                        this.state.isLoading &&
                                        <div>
                                            <Col style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={loading} alt="loading" style={{ width: "150px" }} />
                                                <h4>Bilgiler Yükleniyor...</h4>
                                            </Col>
                                        </div>
                                    }

                                </Container>
                                <Container style={{ direction: "ltr", flex: 1, alignContent: "center" }}>
                                    {
                                        !this.state.isLoading && this.state.error != null &&
                                        <div>
                                            <Col style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={exclamation} alt="exclamation" style={{ width: "150px" }} />
                                                <h4>Verileri Çekme Esnasında Hata Oluştu</h4>
                                            </Col>
                                        </div>
                                    }

                                </Container>
                                <Container style={{ textAlign: "right", direction: "ltr", width: "800px", alignItems: 'center', flex: 1 }}>
                                    {!this.state.isLoading &&  this.state.error == null &&
                                        <Table bordered style={{ direction: "ltr", width: "800px" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "center" }}>
                                                        Kullanıcı Adı
                                                    </th>
                                                    <th style={{ textAlign: "center" }}>
                                                        Cevaplanan Soru Sayısı
                                                    </th>
                                                    <th style={{ textAlign: "center" }}>
                                                        Doğru Cevaplanan Soru Sayısı
                                                    </th>
                                                    <th style={{ textAlign: "center" }}>
                                                        Yanlış Cevaplanan Soru Sayısı
                                                    </th>
                                                    <th style={{ textAlign: "center" }}>
                                                        Başarı Yüzdesi
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.mostAnsweredQuestions != undefined && this.state.mostAnsweredQuestions != null ? (this.state.mostAnsweredQuestions.map(
                                                    (users: any, index: number) => (
                                                        <tr key={index}>

                                                            <td style={{ textAlign: "center" }}>
                                                                {users.username}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {users.answerCount}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {users.correctAnswerCount}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {users.answerCount - users.correctAnswerCount}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {"% " + ((users.correctAnswerCount * 100) / users.answerCount).toFixed(2)}
                                                            </td>
                                                        </tr>
                                                    )
                                                )) : (<tr>

                                                    <td style={{ textAlign: "center" }}>

                                                    </td>
                                                    <td style={{ textAlign: "center" }}>

                                                    </td>
                                                </tr>)}
                                            </tbody>

                                        </Table>
                                    }
                                    <br />
                                </Container>
                            </div>
                        </Container>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

