import React, { useEffect, useRef, useState } from 'react'
import { Row, Button } from 'reactstrap'
import { useHistory } from "react-router-dom";
import Store from 'src/store';
import CollectableResourceEdit from 'src/components/CollectableResouces/CollectableResouceEdit';
import MoonLoader from 'react-spinners/MoonLoader';
import { logoutUser } from 'src/slices/thunks';
import { useDispatch } from 'react-redux';
import AdminCollectableResourceDTO, { Currency } from '../Data/AdminCollectableResourceDTO';
import { Constants } from 'src/Constants';

const CollectableResourcesAdd = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isChanged, setIsChanged] = useState(true);
    const isChangedRef = useRef(isChanged);
    const [collectableResource, setCollectableResource] = useState<AdminCollectableResourceDTO | undefined>(undefined);

    const navigation = useHistory()

    useEffect(() => {
        setIsLoading(true);
        const resourceId = Store.getData("collectableResourceId");  
        if (resourceId != null) {
            loadResource(resourceId);
        }
        else {
            let data : AdminCollectableResourceDTO = {
                isActive: true,
                allUsers: true,
                amountType: Currency.Money,
                amount: 1000,
                users: []
            };
            setCollectableResource(data);
            setIsLoading(false);
        }
    }, [])

    useEffect(() => {
        isChangedRef.current = isChanged;
    }, [isChanged])

    useEffect(() => {
        const unloadCallback = (event: any) => {
            if (isChangedRef.current) {
                event.preventDefault();
                event.returnValue = "";
                return "";
            }

            return event;
        };
      
        window.addEventListener("beforeunload", unloadCallback);
        return () => window.removeEventListener("beforeunload", unloadCallback);
      }, []);

    useEffect(() => {
        if (!isChanged) {
            setIsChanged(true);
        }
    }, [collectableResource])

    async function loadResource(resourceId: string) {
        var response = await fetch(Constants.url + "admin/collectable/resource/details?id=" + resourceId, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token") 
            }
        });

        if ( response.status == 401 || response.status == 403){
            const dispatch = useDispatch();
            dispatch(logoutUser(history));
            return;
        }
        
        const res = await response.json();

        if (res.success) {
            let data : AdminCollectableResourceDTO = res.data;
            data.id = undefined;
            data.modifiedDate = undefined;
            data.createdDate = undefined;
            data.isActive = true;

            if (data.users != null && data.users.length > 0) {
                let users = data.users;
                users.forEach(u => {
                    u.id = undefined;
                    u.modifiedDate = undefined;
                    u.createdDate = undefined;
                    u.isActive = true;
                });
                data.users = users;
            }

            setCollectableResource(data);
        }
        else {
            alert("Server Error: " + res.error);
        }

        setIsLoading(false);
    }

    async function save() {
        setIsLoading(true);
        var body = {
            resource: collectableResource
        };

        var response = await fetch(Constants.url + "admin/collectable/resource/put", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem("token") 
            },
            body: JSON.stringify(body)
        });

        if ( response.status == 401 || response.status == 403){
            const dispatch = useDispatch();
            dispatch(logoutUser(history));
            return;
        }
        
        const res = await response.json();

        if (res.success) {
            setCollectableResource(res.data);
            setIsChanged(false);
        }
        else {
            alert("Server Error: " + res.error);
        }
        setIsLoading(false);
    }

    function cancel() {
        if (isChanged) {
            if (confirm("Yapılan değişiklikler kaybolacak. Çıkmak istediğinize emin misiniz?")) {
                navigation.push("/collectable/resources");
            }
        }
        else {
            navigation.push("/collectable/resources");
        }
    }

    return (
        <React.Fragment>
            <div className="page-content" style={{direction: 'ltr'}}>
                { isLoading &&
                   <Row style={{direction: "ltr", height: 500, justifyContent: 'center', alignItems: 'center' }}>
                        <div style={{display:'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            <MoonLoader color="#1a1a1a" size={80} speedMultiplier={0.5} />
                        </div>
                    </Row>
                }
                { !isLoading && 
                    <>
                        <Row style={{justifyContent: 'center', marginBottom: 20}}>
                            <Row style={{width: 400, justifyContent: 'space-between'}}>
                                <Button style={{width: 150}} onClick={save}>Kaydet</Button>
                                <Button style={{width: 150}} onClick={cancel}>{isChanged ? "İptal" : "Geri"}</Button>
                            </Row>
                        </Row>
                        <CollectableResourceEdit collectableResource={collectableResource} setCollectableResource={setCollectableResource} />
                    </>
                }
            </div>
        </React.Fragment>
    )
}

export default CollectableResourcesAdd