import React, { Component } from "react";
import MetaTags from "react-meta-tags";
import { Table, Col, Row, Container } from "reactstrap";
import { Link } from "react-router-dom";
import { Constants } from "../../Constants";
import exportFromJSON from 'export-from-json';
import exclamation from '../../assets/images/exclamation.gif';
import loading from '../../assets/images/loading.gif';
import { logoutUser } from "src/slices/thunks";
import { useDispatch } from "react-redux";


export default class answersAccuracyRate extends Component<any, any>  {
    constructor(props: any) {
        super(props);
        this.state = {
            mostAccuracyRateQuestions: [],
            isLoading: true,
            error: null
        };

    }

    ExportToExcel = () => {
        const data = this.state.mostAccuracyRateQuestions.map((el: any, index: number) => {
            return { "Kullanıcı Adı": el.username, "Doğruluk Oranı": '% ' + el.accuracyRate };
        });

        const fileName = 'En Yüksek Doğruluk Oranı ile Soru Cevaplayan Kullanıcılar';
        const exportType = 'xls';
        exportFromJSON({ data, fileName, exportType })
    }

    componentDidMount() {
        this.getAnswersAccuracyRate();
    }


    async getAnswersAccuracyRate() {

        try {

            var response = await fetch(Constants.url + "analytics/GetMostAccuracyRateAnsweredQuestion", {
                method: 'Get',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': 'Bearer ' + localStorage.getItem("token")
                }
            });

            if (response.status == 401 || response.status == 403) {
                const dispatch = useDispatch();
                dispatch(logoutUser(history));
                return
            }

            var answerResults = await response.json();
            this.setState({ mostAccuracyRateQuestions: answerResults });

            this.setState({ isLoading: false });
            return answerResults;
        }
        catch (error) {
            this.setState({ isLoading: false });
            console.log("error", error)
            this.setState({ error: error });
        }

    }


    render() {
        return (
            <React.Fragment>
                <div style={{ minHeight: "100vh" }}>
                    <div className="page-content">
                        <MetaTags>
                            <title> En Yüksek Doğruluk Oranı ile Soru Cevaplayan Kullanıcılar </title>
                        </MetaTags>
                    </div>
                    <br />
                    <div>
                        <Container style={{ textAlign: "right", direction: "ltr", alignItems: 'center', flex: 1 }}>
                            <div>
                                <Row style={{ textAlign: "left", direction: "ltr", alignItems: "center", marginLeft: "50px" }}>
                                    <Col>
                                        <h5 ><b><u>  En Yüksek Doğruluk Oranı ile Soru Cevaplayan Kullanıcılar </u></b></h5>
                                    </Col>
                                </Row>
                                <br />
                                <Row>
                                    <Col style={{ textAlign: "left", direction: "ltr", marginLeft: "60px" }}>
                                        <Link to="/analyticEvents" className="btn btn-primary" onClick={this.props.history.pop}>Geri</Link>
                                    </Col>
                                    <Col md={5} >
                                        <button type="button" className="btn btn-danger" onClick={this.ExportToExcel}>En Yüksek Doğruluk Oranı ile Soru Cevaplayan Kullanıcılar Listesi İndir</button>
                                    </Col>
                                </Row>
                                <br />
                            </div>
                            <div>
                                <Container style={{ direction: "ltr", flex: 1, alignContent: "center", marginTop:'10px' }}>
                                    {
                                        this.state.isLoading &&
                                        <div>
                                            <Col style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={loading} alt="loading" style={{ width: "150px" }} />
                                                <h4>Bilgiler Yükleniyor...</h4>
                                            </Col>
                                        </div>
                                    }

                                </Container>
                                <Container style={{ direction: "ltr", flex: 1, alignContent: "center" }}>
                                    {!this.state.isLoading && this.state.error != null &&
                                        <div>
                                            <Col style={{ textAlign: 'center', alignItems: 'center', justifyContent: 'center' }}>
                                                <img src={exclamation} alt="exclamation" style={{ width: "75px" }} />
                                                <h4>Verileri Çekme Esnasında Hata Oluştu</h4>
                                            </Col>
                                        </div>
                                    }

                                </Container>
                                <Container style={{ textAlign: "right", direction: "ltr", width: "800px", alignItems: 'center', flex: 1 }}>
                                    {!this.state.isLoading &&  this.state.error == null &&
                                        <Table bordered style={{ direction: "ltr", width: "800px" }}>
                                            <thead>
                                                <tr>
                                                    <th style={{ textAlign: "center" }}>
                                                        Kullanıcı Adı
                                                    </th>

                                                    <th style={{ textAlign: "center" }}>
                                                        Doğruluk Oranı
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {this.state.mostAccuracyRateQuestions != null ? (this.state.mostAccuracyRateQuestions.map(
                                                    (users: any, index: number) => (
                                                        <tr key={index}>

                                                            <td style={{ textAlign: "center" }}>
                                                                {users.username}
                                                            </td>
                                                            <td style={{ textAlign: "center" }}>
                                                                {'%' + users.accuracyRate}
                                                            </td>
                                                        </tr>
                                                    )
                                                )) : (<tr>

                                                    <td style={{ textAlign: "center" }}>

                                                    </td>
                                                    <td style={{ textAlign: "center" }}>

                                                    </td>
                                                </tr>)}
                                            </tbody>

                                        </Table>
                                    }
                                    <br />
                                </Container>
                            </div>
                        </Container>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

