import React from "react";
import MetaTags from "react-meta-tags";
import UploadExcelFile from "src/pages/AddQuestions/uploadExcelFile";
import AddQuestion from "src/pages/AddQuestions/addQuestions";
//import IsActiveChart  from "src/pages/UserStatistics/isActiveChart";
//import OtherStatisticList from "src/pages/UserStatistics/otherStatisticList";

import { Col, Container, Row, Table } from 'reactstrap';

const AddQuestions = () => {
    
    return (
        <React.Fragment>
            <div className="page-content"> 
                <MetaTags>
                   <title>Soru </title>
                </MetaTags>
                <Col>
                    <h4 style={{ textAlign: "left", margin:"15px 0px 0px 35px" }}>Soru Ekleme</h4>
                </Col> 
            </div>
            <div>
                <UploadExcelFile/>
                <br />
                <AddQuestion/>
            </div>
            <div>
                <Container style={{ textAlign: "right", direction: "ltr"}}>
                    <Row>

                    </Row>
                </Container>
                
            </div>
            <br />
            <br />
        </React.Fragment>
    );
}

export default AddQuestions;