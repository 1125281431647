import { apiError } from "./reducer";
import { Constants } from "src/Constants";

export const loginUser = (user: [any, any], history: any) => async (dispatch: any) => {
    const body = {
        userName: user[0],
        password: user[1]
    }

    var response = await fetch(Constants.url + "admin/login",
        {
            method: 'POST',
            headers: {
                "accept": "*/*",
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(body)
        });
    if (response.status == 200) {
        var result = await response.json();

        if (result.success) {
            localStorage.setItem("token", result.data.accessToken);
            history.push("/userInfo");
        }
        else {
            throw "Failed"

        }
    }
    else {
        alert("Kullanıcı adı ya şifre hatalı. Lütfen kontrol ederek yeniden deneyiniz");
    }
};

export const logoutUser = (history: any) => async (dispatch: any) => {
    try {
        localStorage.removeItem("token");

        history.push("/login");
    } catch (error) {
        dispatch(apiError(error));
    }
};